import React, { useState } from "react";
import { Button, EmptyState, Typography, useTranslation } from "@lumar/shared";
import { makeStyles, Paper, PopoverProps, useTheme } from "@material-ui/core";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import errorLottie from "../../../_animations/error-animation.json";
import emptyLottie from "../../../_animations/noresult-animation.json";
import { ConditionallyShow } from "../../../_common/components/ConditionallyShow";
import { useGetProjectListLazyQuery } from "../utils/useGetProjectListLazyQuery";
import { Backdrop, Popover } from "@material-ui/core";
import { useDebounce } from "../../../_common/hooks/useDebounce";
import { ProjectsList } from "./ProjectsList";
import { ProjectOption } from "../../../_common/utils/constants";
import { ProjectSearchBar } from "./ProjectSearchBar";

const DIALOG_WIDTH = 450;

const useStyles = makeStyles((theme) => ({
  emptyState: {
    height: 276,
  },
  header: {
    backgroundColor: theme.palette.grey[50],
  },
  popover: {
    width: DIALOG_WIDTH,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    backdropFilter: "blur(8px)",
    background: "rgba(209, 213, 219,0.3)",
    height: 60,
    width: "100%",
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    display: "block",
  },
}));

interface Props {
  onCancel: () => void;
  onOk: (projects: ProjectOption[]) => void;
  anchorEl?: PopoverProps["anchorEl"];
  open: boolean;
  excludeProject?: string;
}

export function CopyAlertDialog({
  onCancel,
  onOk,
  anchorEl,
  open,
  excludeProject,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["common", "alerts"]);
  const theme = useTheme();
  const [selectedProjects, setSelectedProjects] = useState<
    (ProjectOption & { testsTotalCount: number })[]
  >([]);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm);

  const {
    data: projects,
    error: errorLoadingProjects,
    hasFetchedAllData,
    handleLoadMore,
  } = useGetProjectListLazyQuery(!open, debouncedSearchTerm || null);

  const projectList = projects?.getAccount?.monitorProjects?.edges.filter(
    ({ node }) => node.id !== excludeProject,
  );

  const handleSearchBarChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSearchTerm(e.target.value);
  };

  const handleSearchBarClear = (): void => {
    setSearchTerm("");
  };

  return (
    <Backdrop open={true} className={classes.backdrop}>
      <Popover
        id="alert-copy-to-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={onCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.popover,
        }}
      >
        <div
          style={{ padding: theme.spacing(2), paddingBottom: theme.spacing(1) }}
          className={classes.header}
        >
          <Typography
            variant="subtitle2Bold"
            data-pendo="copy-alert-dialog-title"
            className={classes.title}
          >
            {t("alerts:chooseProject")}
          </Typography>
          <ProjectSearchBar
            value={searchTerm}
            onChange={handleSearchBarChange}
            onClear={handleSearchBarClear}
          />
        </div>
        <ConditionallyShow show={Boolean(errorLoadingProjects)}>
          <Paper>
            <EmptyState
              className={classes.emptyState}
              width={DIALOG_WIDTH}
              height="100%"
              title={t("errorLoadingProjects")}
              description={t("errorLoadingProjectsDesc")}
              icon={
                <Lottie
                  loop
                  animationData={errorLottie}
                  play
                  style={{ width: "80px", margin: "auto" }}
                />
              }
              actions={[
                {
                  type: "button",
                  title: t("reloadPage"),
                  onClick: () => {
                    window.location.reload();
                  },
                },
              ]}
            />
          </Paper>
        </ConditionallyShow>
        <ConditionallyShow
          show={!Boolean(errorLoadingProjects) && projectList?.length === 0}
        >
          <Paper>
            <EmptyState
              className={classes.emptyState}
              width={DIALOG_WIDTH}
              height="100%"
              title=""
              description={t("emptyProjectList")}
              icon={
                <Lottie
                  loop
                  animationData={emptyLottie}
                  play
                  style={{ width: "80px", margin: "auto" }}
                />
              }
            />
          </Paper>
        </ConditionallyShow>
        <ConditionallyShow
          show={
            !Boolean(errorLoadingProjects) &&
            !Boolean(projectList?.length === 0)
          }
        >
          <div style={{ position: "relative" }}>
            <ProjectsList
              projectList={projectList}
              selectedProjects={selectedProjects ?? []}
              hasFetchedAllData={hasFetchedAllData}
              onListItemClick={(project, selected) => {
                if (selected)
                  setSelectedProjects([project, ...(selectedProjects ?? [])]);
                else {
                  setSelectedProjects(
                    selectedProjects?.filter((e) => e.id !== project.id),
                  );
                }
              }}
              onScroll={handleLoadMore}
              searchTerm={debouncedSearchTerm}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
              className={classes.footer}
            >
              <Button variant="outlined" size="large" onClick={onCancel}>
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                data-testid="copy-alert-dialog-continue-button"
                style={{ marginLeft: 8 }}
                onClick={() => {
                  onOk(selectedProjects);
                  setSelectedProjects([]);
                }}
                disabled={Boolean(!selectedProjects?.length)}
              >
                {t("continue")}
              </Button>
            </div>
          </div>
        </ConditionallyShow>
      </Popover>
    </Backdrop>
  );
}

import { differenceBy, intersectionWith, isEqual } from "lodash";
import { RuleAndThreshold } from "../../_common/utils/types";

const getReportCode = (o: RuleAndThreshold): string =>
  `${o.report.code}--${o.segment?.id ?? "?urls?"}`;

const isRuleAndThresholdChanged = (
  arrVal: RuleAndThreshold,
  othVal: RuleAndThreshold,
): boolean =>
  arrVal.report.code === othVal.report.code &&
  arrVal.segment?.id === othVal.segment?.id &&
  !isEqual(arrVal, othVal);

interface MapRulesAndThresholdsToApiActionsPayload {
  rulesToCreate: RuleAndThreshold[];
  rulesToDelete: RuleAndThreshold[];
  rulesToUpdate: RuleAndThreshold[];
}

export function mapRulesAndThresholdsToApiActions(
  initialValues: RuleAndThreshold[],
  newValues: RuleAndThreshold[],
): MapRulesAndThresholdsToApiActionsPayload {
  const rulesToDelete = differenceBy(initialValues, newValues, getReportCode);
  const rulesToCreate = differenceBy(newValues, initialValues, getReportCode);

  const rulesToUpdate = intersectionWith(
    newValues,
    initialValues,
    isRuleAndThresholdChanged,
  );

  return {
    rulesToCreate,
    rulesToUpdate,
    rulesToDelete,
  };
}

import {
  PencilSolid,
  ToggleIconButton,
  Typography,
  XSolid,
  useSession,
  useTranslation,
} from "@lumar/shared";
import {
  Tooltip,
  Button,
  ButtonGroup,
  ButtonProps,
  CircularProgress,
  ClickAwayListener,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useState, KeyboardEvent } from "react";
import {
  ReportTemplateUnit,
  RoleCode,
  ThresholdPredicate,
} from "../../graphql";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { MAX_ABSOLUTE_THRESHOLD_URLS } from "../../alerts/_common/utils/constants";
import clsx from "clsx";

interface Props {
  onAccept: (value: number) => Promise<void>;
  onDecline: ButtonProps["onClick"];
  initialValue: number;
  thresholdPredicate: ThresholdPredicate;
  loading: boolean;
  isResponsive?: boolean;
  unit: ReportTemplateUnit;
}

export function SuggestedThreshold({
  onAccept,
  onDecline,
  initialValue,
  thresholdPredicate,
  loading,
  isResponsive = false,
  unit,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["notifications", "common", "units"]);
  const session = useSession();
  const [isEditMode, setIsEditMode] = useState(false);

  const [savedValue, setSavedValue] = useState<string>(initialValue.toString());
  const [inputValue, setInputValue] = useState<string>(savedValue);
  const { isInputValid, errorMessage } = useInputValidation(inputValue);

  const hasSufficientRole = session.hasSufficientRole(RoleCode.Editor);

  const predicateSymbol =
    thresholdPredicate === ThresholdPredicate.GreaterThanOrEqual ? "≥" : "<";

  const cancelSuggestedThresholdChange = (): void => {
    setIsEditMode(false);
    setInputValue(savedValue);
  };

  const handleAcceptButtonClick = (): void => {
    if (!loading) {
      const newThreshold = isInputValid ? inputValue : savedValue;
      setSavedValue(newThreshold);
      onAccept(Number(newThreshold));
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === "Escape") {
      cancelSuggestedThresholdChange();
    }
  };

  return (
    <div
      onClick={(e) => e.preventDefault()}
      className={clsx(
        classes.container,
        isResponsive && classes.responsiveContainer,
      )}
    >
      <Typography className={classes.title}>
        {t("acceptSuggestedThreshold")}
      </Typography>

      {isEditMode ? (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Typography>{predicateSymbol} </Typography>
            <ClickAwayListener onClickAway={cancelSuggestedThresholdChange}>
              <Tooltip
                title={errorMessage ?? ""}
                open={true}
                placement="top"
                arrow={false}
                classes={{ tooltip: classes.errorTooltip }}
              >
                <TextField
                  variant="outlined"
                  type="number"
                  value={inputValue}
                  autoFocus
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  error={Boolean(errorMessage)}
                  data-pendo="edit-suggested-threshold-input"
                />
              </Tooltip>
            </ClickAwayListener>
            <Tooltip title={t("common:cancel") as string} arrow={false}>
              <ToggleIconButton
                variant="outlined"
                onClick={cancelSuggestedThresholdChange}
                data-pendo="cancel-suggested-threshold-changes"
              >
                <XSolid />
              </ToggleIconButton>
            </Tooltip>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ paddingTop: 7.5, paddingBottom: 7.5 }}
            onClick={handleAcceptButtonClick}
          >
            {t("common:saveAndAccept")}
          </Button>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 8,
            }}
          >
            <Typography
              className={classes.threshold}
              data-testid="suggested-threshold-text"
            >
              {t("suggestedThreshold", {
                symbol: predicateSymbol,
                count: Number(savedValue),
                unit: t(`units:${unit ?? ReportTemplateUnit.UrLs}`, {
                  count: Number(savedValue),
                }),
              })}
            </Typography>
            <HideFromInsufficientRole>
              <Tooltip title={t("common:edit") as string} arrow={false}>
                <ToggleIconButton
                  variant="outlined"
                  onClick={() => setIsEditMode(true)}
                  data-pendo="edit-suggested-threshold-button"
                >
                  <PencilSolid />
                </ToggleIconButton>
              </Tooltip>
            </HideFromInsufficientRole>
          </div>
          <Tooltip
            title={
              !hasSufficientRole ? (t("editorsOnlyMessage") as string) : ""
            }
          >
            <ButtonGroup className={classes.buttonGroup}>
              <Button
                onClick={handleAcceptButtonClick}
                className={classes.acceptButton}
                disabled={!hasSufficientRole}
                data-pendo="accept-suggested-threshold-button"
                data-testid="accept-suggested-threshold-button"
              >
                {loading ? (
                  <CircularProgress
                    style={{ width: 18, height: 18, color: "white" }}
                  />
                ) : (
                  t("common:accept")
                )}
              </Button>
              <Button
                onClick={onDecline}
                className={classes.cancelButton}
                disabled={!hasSufficientRole || loading}
                data-pendo="decline-suggested-threshold"
                data-testid="decline-suggested-threshold-button"
              >
                {t("common:decline")}
              </Button>
            </ButtonGroup>
          </Tooltip>
        </>
      )}
    </div>
  );
}

function useInputValidation(inputValue: string): {
  isInputValid: boolean;
  errorMessage: string | null;
} {
  const { t } = useTranslation("alerts");

  const isValueTooSmall = Number(inputValue) < 0;
  const isValueTooBig = Number(inputValue) > MAX_ABSOLUTE_THRESHOLD_URLS;

  const errorMessage = isValueTooBig
    ? t("maxUrlsValidationMessage", { count: MAX_ABSOLUTE_THRESHOLD_URLS })
    : isValueTooSmall
      ? t("minUrlsValidationMessage")
      : null;

  const isInputValid = Boolean(!errorMessage && inputValue);

  return { isInputValid, errorMessage };
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
  },
  threshold: {
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
  },
  buttonGroup: {
    filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05))",
  },
  acceptButton: {
    backgroundColor: theme.palette.green[500],
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    width: 80,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.green[600],
    },
  },
  cancelButton: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    color: theme.palette.grey[500],
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  errorTooltip: {
    backgroundColor: theme.palette.red[600],
  },
  container: {
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(3, 0, 2, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: 168,
    marginLeft: theme.spacing(2),
  },
  responsiveContainer: {
    ["@media (max-width: 1050px)"]: {
      margin: 0,
      flexDirection: "row",
      maxWidth: "100%",
      alignItems: "center",
      padding: 8,
      borderLeft: "inherit",
      borderTop: "1px solid #E5E7EB",
    },
  },
}));

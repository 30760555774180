import { useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { NotificationChannelType } from "./components/CreateNewNotificationMenu";
import { RulesAndThresholdsFormState } from "../utils/types";

interface NotificationChannels {
  activeChannels: NotificationChannelType[];
  selectedChannel: NotificationChannelType;
}

export function useNotificationChannels(): {
  notificationChannels: NotificationChannels;
  addChannel: (channel: NotificationChannelType) => void;
  removeChannel: (channel: NotificationChannelType) => void;
  selectChannel: (channel: NotificationChannelType) => void;
} {
  const { initialValues, touched } =
    useFormikContext<RulesAndThresholdsFormState>();

  const hasEmailsSaved = Boolean(initialValues.emailAlerts?.length);
  const hasSlackWebhooksSaved = Boolean(initialValues.slackWebhooks?.length);
  const hasMsTeamsWebhooksSaved = Boolean(
    initialValues.microsoftTeamsWebhooks?.length,
  );

  const [notificationChannels, setNotificationChannels] = useState(() => {
    const activeChannels: NotificationChannelType[] = [
      ...(hasEmailsSaved ? [NotificationChannelType.Email] : []),
      ...(hasSlackWebhooksSaved ? [NotificationChannelType.Slack] : []),
      ...(hasMsTeamsWebhooksSaved ? [NotificationChannelType.MsTeams] : []),
    ];
    const initiallySelectedChannel =
      activeChannels[0] ?? NotificationChannelType.Email;

    return {
      activeChannels,
      selectedChannel: initiallySelectedChannel,
    };
  });

  const isEditMode =
    Boolean(touched["emailAlerts"]) ||
    Boolean(touched["rulesAndThresholds"]) ||
    Boolean(touched["slackWebhooks"]) ||
    Boolean(touched["microsoftTeamsWebhooks"]);

  function addChannel(channel: NotificationChannelType): void {
    setNotificationChannels((state) => ({
      activeChannels: [...state.activeChannels, channel],
      selectedChannel: channel,
    }));
  }

  function removeChannel(channel: NotificationChannelType): void {
    setNotificationChannels((state) => {
      const filteredChannels = state.activeChannels.filter(
        (c) => c !== channel,
      );
      const selectedChannel =
        filteredChannels[0] ?? NotificationChannelType.Email;

      return {
        activeChannels: filteredChannels,
        selectedChannel,
      };
    });
  }

  function selectChannel(channel: NotificationChannelType): void {
    setNotificationChannels((state) => ({
      ...state,
      selectedChannel: channel,
    }));
  }

  useEffect(() => {
    if (!isEditMode) {
      // If we are no longer in edit mode, we need to reset the visibility of the channels
      const activeChannels: NotificationChannelType[] = [
        ...(hasEmailsSaved ? [NotificationChannelType.Email] : []),
        ...(hasSlackWebhooksSaved ? [NotificationChannelType.Slack] : []),
        ...(hasMsTeamsWebhooksSaved ? [NotificationChannelType.MsTeams] : []),
      ];
      const selectedChannel =
        activeChannels[0] ?? NotificationChannelType.Email;

      setNotificationChannels({
        activeChannels,
        selectedChannel,
      });
    }
  }, [
    hasEmailsSaved,
    hasMsTeamsWebhooksSaved,
    hasSlackWebhooksSaved,
    isEditMode,
  ]);

  return {
    notificationChannels,
    addChannel,
    removeChannel,
    selectChannel,
  };
}

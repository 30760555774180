import React from "react";
import {
  Typography,
  ExclamationSolid,
  ExclamationCircleSolid,
  ArrowCircleRightSolid,
  Snackbar,
  CheckCircleSolid,
  ChartPieSolid,
  useNumberFormatter,
  useTranslation,
  getRawProjectId,
  useApolloClient,
  useSession,
} from "@lumar/shared";
import {
  makeStyles,
  Tooltip,
  Chip as MuiChip,
  useTheme,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SparklinesMiniChart } from "../../_common/components/SparklinesMiniChart/SparklinesMiniChart";
import { AbsoluteURLs } from "../../_common/routing/absoluteURLs";
import { ConditionalLink } from "../_common/ConditionalLink";
import { EditRuleAndThresholdDialog } from "../_common/EditRuleAndThresholdDialog";
import { EditRuleChip } from "../_common/EditRuleChip";
import { ManageAlertButton } from "../_common/ManageAlertButton";
import { StatusChips } from "../_common/StatusChips";
import { SuggestedThreshold } from "../_common/SuggestedThreshold";
import { extractChartDataFromTrends } from "../utils/extractChartDataFromTrends";
import { assert } from "../../_common/assert";
import {
  GetNotificationsQueryVariables,
  ReportTemplateUnit,
  Severity,
  TestAutoThresholdAcceptance,
  ThresholdPredicate,
  useAcceptSuggestedThresholdMutation,
  useGetRulesAndThresholdsQuery,
  useRejectSuggestedThresholdMutation,
  useUpdateRulesAndThresholdsMutation,
} from "../../graphql";
import { getErrorMessage } from "../../_common/utils/getErrorMessage";
import { ExtendedThresholdSettings, MonitorNotification } from "../types";
import { NotificationTitle } from "./NotificationTitle";
import { useSuggestedThreshold } from "../utils/useSuggestedThreshold";
import { ProjectDetails } from "./ProjectDetails";
import { extractChartDataFromTestResults } from "../utils/extractChartDataFromTestResults";
import { minBy, maxBy } from "lodash";
import { updateNotificationsInCache } from "../utils/updateNotificationsInCache";
import { useClearNotificationCache } from "../utils/useClearNotificationCache";

interface Props {
  notification: MonitorNotification;
  isAdjustment: boolean;
  queryVariables?: GetNotificationsQueryVariables;
  unit: ReportTemplateUnit;
}

export function NotificationItem({
  notification,
  isAdjustment,
  queryVariables,
  unit,
}: Props): JSX.Element {
  const {
    severity,
    absoluteThreshold,
    thresholdPredicate,
    reportStat: report,
    reportTemplate,
    id: notificationID,
  } = notification;

  assert(reportTemplate);
  assert(thresholdPredicate);

  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();

  const urlThreshold = absoluteThreshold ?? 0;
  const isFailed = severity === Severity.Fail;

  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [showArrowTooltip, setShowArrowTooltip] = React.useState(false);

  const suggestedThreshold = useSuggestedThreshold(notification);

  const apollo = useApolloClient();

  const clearCache = useClearNotificationCache();

  const [acceptSuggestedThreshold, { loading }] =
    useAcceptSuggestedThresholdMutation();
  const [rejectSuggestedThreshold] = useRejectSuggestedThresholdMutation();

  const classes = useStyles({ isFailed, isAdjustment });
  const { t } = useTranslation([
    "common",
    "alerts",
    "common",
    "notifications",
    "units",
  ]);
  const { accountId } = useParams<{ accountId: string }>();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const formatNumber = useNumberFormatter();

  const [updateRuleAndThreshold] = useUpdateRulesAndThresholdsMutation({
    refetchQueries: ["GetNotifications"],
    awaitRefetchQueries: true,
  });

  const { data } = useGetRulesAndThresholdsQuery({
    variables: {
      projectId: report?.project.id,
    },
  });

  const doesAlertStillExist = Boolean(
    data?.alert?.rulesAndThresholds.totalCount,
  );

  const {
    lastCrawledAt,
    currentUrlCount,
    previousUrlCount,
    seriesData,
    crawlId,
  } = extractChartDataFromTrends(report?.reportTrend);

  const thresholdSeriesData = extractChartDataFromTestResults(
    report?.testResults?.nodes,
  );

  const differenceFromRule = currentUrlCount - urlThreshold;
  const doesRuleStillExist = Boolean(notification.test);

  const reportPageUrl = report
    ? AbsoluteURLs.EXTERNAL__AnalyzeReport.getUrl({
        accountId,
        crawlId: String(crawlId),
        segmentId: notification?.segment?.id,
        projectId: getRawProjectId(report?.project.id),
        reportTemplateCode: reportTemplate.code,
        reportTypeCode: "basic",
      })
    : "/";

  const hasSuggestedThreshold =
    (suggestedThreshold.thresholdAcceptanceWorse ===
      TestAutoThresholdAcceptance.Suggest &&
      typeof suggestedThreshold.value === "number" &&
      !isAdjustment) ||
    (suggestedThreshold.thresholdAcceptanceBetter ===
      TestAutoThresholdAcceptance.Suggest &&
      typeof suggestedThreshold.value === "number" &&
      isAdjustment);

  const hasThresholdBeenUpdated =
    urlThreshold !== notification.test?.absoluteThreshold ||
    thresholdPredicate !== notification.test.thresholdPredicate;

  const shouldShowSuggestedThreshold =
    hasSuggestedThreshold &&
    suggestedThreshold.isVisible &&
    !(suggestedThreshold.isAccepted || suggestedThreshold.isRejected);

  async function handleAcceptSuggestedThreshold(
    threshold: number,
  ): Promise<void> {
    const hasSuggestedThresholdBeenEdited =
      threshold !== notification?.suggestedAbsoluteThreshold;
    try {
      await acceptSuggestedThreshold({
        variables: {
          testResultIds: [notification.id],
        },
        refetchQueries: ["GetNotifications", "NotificationTotalCounts"],
        awaitRefetchQueries: true,
        update: (cache, { data, errors }) => {
          if (data && !errors && queryVariables)
            updateNotificationsInCache(
              cache,
              queryVariables,
              (notification) => {
                if (notification.id !== notificationID) return {};
                return {
                  suggestedAbsoluteThresholdAcceptedAt:
                    new Date().toISOString(),
                };
              },
            );
        },
      });

      if (hasSuggestedThresholdBeenEdited) {
        await updateRuleAndThreshold({
          variables: {
            rulesAndThresholds: {
              testId: notification.test?.id,
              absoluteThreshold: threshold,
            },
          },
        });
      }

      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("notifications:ruleUpdatedSuccessfully")}
        />,
      );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={`${t("errors:genericError")} ${getErrorMessage(e)}`}
        />,
      );
    }
  }

  async function handleDeclineSuggestedThreshold(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<void> {
    e.preventDefault();

    try {
      await rejectSuggestedThreshold({
        variables: {
          testResultIds: [notification.id],
        },
        refetchQueries: ["GetNotifications", "NotificationTotalCounts"],
        awaitRefetchQueries: true,
        update: (cache, { data, errors }) => {
          if (data && !errors && queryVariables)
            updateNotificationsInCache(
              cache,
              queryVariables,
              (notification) => {
                if (notification.id !== notificationID) return {};
                return {
                  suggestedAbsoluteThresholdRejectedAt:
                    new Date().toISOString(),
                };
              },
            );
        },
      });
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("notifications:ruleDeclinedSuccessfully")}
        />,
      );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={`${t("errors:genericError")} ${getErrorMessage(e)}`}
        />,
      );
    }
  }

  function handleRuleAndThresholdUpdate(test: ExtendedThresholdSettings): void {
    if (
      test.absoluteThreshold !== notification.absoluteThreshold ||
      test.automaticThresholdAcceptanceWhenTestResultIsBetter !==
        notification.automaticThresholdAcceptanceWhenTestResultIsBetter ||
      test.automaticThresholdAcceptanceWhenTestResultIsWorse !==
        notification.automaticThresholdAcceptanceWhenTestResultIsWorse ||
      test.thresholdPredicate !== test.thresholdPredicate
    ) {
      if (isAdjustment) clearCache();
      if (queryVariables)
        updateNotificationsInCache(apollo.cache, queryVariables, (nt) => {
          if (notificationID !== nt.id) return {};
          return {
            test: {
              ...nt.test,
              absoluteThreshold: test.absoluteThreshold,
              automaticThresholdAcceptanceWhenTestResultIsBetter:
                test.automaticThresholdAcceptanceWhenTestResultIsBetter,
              automaticThresholdAcceptanceWhenTestResultIsWorse:
                test.automaticThresholdAcceptanceWhenTestResultIsWorse,
              thresholdPredicate: test.thresholdPredicate,
            },
          };
        });
    }
  }
  const isCustomExtraction =
    reportTemplate.code.startsWith("custom_extraction_");
  const reportTemplateName =
    (isCustomExtraction
      ? report?.customExtractions?.find(
          (e) => e.reportTemplateCode === reportTemplate.code,
        )?.label
      : undefined) ?? reportTemplate.name;

  return (
    <>
      <ConditionalLink
        href={reportPageUrl}
        shouldRenderAsLink={Boolean(report)}
        style={{ textDecoration: "none", color: "inherit" }}
        data-pendo="view-notifications-notification-list-item"
        data-testid="notification-list-item"
      >
        <div
          className={classes.container}
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
        >
          <div className={classes.statusIconContainer}>
            {isAdjustment ? (
              <CheckCircleSolid />
            ) : isFailed ? (
              <ExclamationCircleSolid />
            ) : (
              <ExclamationSolid data-testid="warning-icon" />
            )}
          </div>
          <div className={classes.mainContentContainer}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  minWidth: 0,
                  flex: 1,
                }}
              >
                <NotificationTitle
                  isFailed={isFailed}
                  reportName={reportTemplateName}
                  previousUrlCount={previousUrlCount}
                  currentUrlCount={currentUrlCount}
                  isHovering={isMouseOver}
                  isAdjustment={isAdjustment}
                  unit={unit}
                />

                <Typography
                  variant="caption"
                  className={classes.secondaryText}
                  style={{ marginBottom: 5 }}
                >
                  <strong style={{ fontWeight: 600 }}>
                    {formatNumber(Math.abs(differenceFromRule)) + " "}
                  </strong>
                  {t("notifications:urlsThan", {
                    rule: differenceFromRule >= 0 ? "more" : "less",
                    unit: t(`units:${unit ?? ReportTemplateUnit.UrLs}`, {
                      count: Math.abs(differenceFromRule),
                    }),
                  })}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: theme.spacing(1),
                    gap: 12,
                  }}
                >
                  <EditRuleChip
                    absoluteThreshold={urlThreshold}
                    thresholdPredicate={thresholdPredicate}
                    onClick={() => {
                      setIsDialogOpen(true);
                    }}
                    doesRuleStillExist={doesRuleStillExist}
                    unit={unit}
                  />
                  {!doesRuleStillExist || hasThresholdBeenUpdated ? (
                    <Typography className={classes.updatedRuleText}>
                      {doesRuleStillExist
                        ? t("notifications:ruleUpdatedTo", {
                            count: notification.test?.absoluteThreshold ?? 0,
                            symbol:
                              notification.test?.thresholdPredicate ===
                              ThresholdPredicate.GreaterThanOrEqual
                                ? "≥"
                                : "<",
                            unit: t(
                              `units:${unit ?? ReportTemplateUnit.UrLs}`,
                              {
                                count:
                                  notification.test?.absoluteThreshold ?? 0,
                              },
                            ),
                          })
                        : t("notifications:ruleDeleted")}
                    </Typography>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: theme.spacing(1),
                  }}
                >
                  {notification.test
                    ?.automaticThresholdAcceptanceWhenTestResultIsWorse &&
                  notification.test
                    .automaticThresholdAcceptanceWhenTestResultIsWorse !==
                    TestAutoThresholdAcceptance.None &&
                  !shouldShowSuggestedThreshold ? (
                    <MuiChip
                      label={
                        suggestedThreshold.thresholdAcceptanceWorse ===
                        TestAutoThresholdAcceptance.Suggest
                          ? t("notifications:suggestedThresholdEnabled")
                          : t("notifications:autoThresholdEnabled")
                      }
                      className={classes.suggestedThresholdChip}
                    />
                  ) : null}
                  <Typography
                    variant="captionMedium"
                    className={classes.secondaryText}
                    data-testid="notification-timestamp"
                  >
                    {lastCrawledAt
                      ? t("common:dateAndTime", { date: lastCrawledAt })
                      : "Unknown"}
                  </Typography>
                </div>
                <div style={{ marginLeft: 8 }}>
                  <SparklinesMiniChart
                    height={42}
                    width={150}
                    minX={minBy(seriesData, (e) => e[0])?.[0] ?? 0}
                    maxX={maxBy(seriesData, (e) => e[0])?.[0] ?? 1}
                    data={[
                      seriesData,
                      {
                        values: thresholdSeriesData,
                        color: "#EF4444",
                        gradient: null,
                        disableMarker: true,
                        dashStyle: "ShortDash",
                        lineWidth: 1,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              {report ? (
                <div
                  style={{ height: "100%", display: "flex", flexWrap: "wrap" }}
                >
                  <ProjectDetails
                    name={report.project.name}
                    primaryDomain={report.project.primaryDomain}
                  />
                  {segmentationAvailable && (
                    <>
                      <div
                        style={{
                          marginLeft: theme.spacing(1.375),
                          marginRight: theme.spacing(1.375),
                          width: 1,
                          background: theme.palette.grey[300],
                        }}
                      />
                      <Tooltip
                        title={
                          <div>
                            <Typography
                              variant="h1Bold"
                              style={{ fontSize: theme.typography.pxToRem(11) }}
                            >
                              {t("notifications:segment")}
                            </Typography>
                            <div>
                              {notification.segment?.name ??
                                t("common:noSegments")}
                            </div>
                          </div>
                        }
                        arrow={false}
                      >
                        <div className={classes.segment}>
                          <ChartPieSolid
                            style={{
                              width: 20,
                              height: 20,
                              marginRight: theme.spacing(1),
                            }}
                          />
                          <Typography
                            variant="captionSemiBold"
                            className={classes.segmentText}
                          >
                            {notification.segment?.name ??
                              t("common:noSegments")}
                          </Typography>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </div>
              ) : null}
              <div
                // Note: stops the user from being redirected to analyze app if they try to click the button/chips and miss
                onClick={(e) => e.preventDefault()}
                id="manage-alert-zone"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 5,
                  marginRight: -5,
                  marginBottom: -5,
                  width: 350,
                }}
              >
                {isMouseOver ? (
                  <>
                    {report ? (
                      <ManageAlertButton
                        alertId={report.project.id}
                        disabled={!doesAlertStillExist}
                      />
                    ) : null}
                    {!isAdjustment ? (
                      <StatusChips
                        notificationId={notification.id}
                        initialStatus={notification.status}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {shouldShowSuggestedThreshold ? (
            <SuggestedThreshold
              onAccept={handleAcceptSuggestedThreshold}
              onDecline={handleDeclineSuggestedThreshold}
              initialValue={suggestedThreshold.value ?? 0}
              thresholdPredicate={thresholdPredicate}
              loading={loading}
              unit={unit}
            />
          ) : null}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: 38,
              justifyContent: "center",
            }}
            onMouseEnter={() => setShowArrowTooltip(true)}
            onMouseLeave={() => setShowArrowTooltip(false)}
          >
            {isMouseOver ? (
              <Tooltip
                title={t("common:goToReport") as string}
                arrow={false}
                open={showArrowTooltip}
                placement="bottom"
              >
                <ArrowCircleRightSolid className={classes.arrowIcon} />
              </Tooltip>
            ) : null}
          </div>
        </div>
      </ConditionalLink>
      {isDialogOpen ? (
        <EditRuleAndThresholdDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onRuleAndThresholdUpdate={handleRuleAndThresholdUpdate}
          notification={notification}
          previousUrlCount={previousUrlCount}
          currentUrlCount={currentUrlCount}
          reportTrendData={seriesData}
          currentRuleSettings={notification.test}
          ruleSettingsAtTimeOfCrawl={{
            absoluteThreshold: urlThreshold,
            thresholdPredicate,
            severity,
          }}
          thresholdTrendData={thresholdSeriesData}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    backgroundColor: "white",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    "&:hover": {
      background: theme.palette.grey[100],
    },
  },
  statusIconContainer: ({
    isFailed,
    isAdjustment,
  }: {
    isFailed: boolean;
    isAdjustment: boolean;
  }) => ({
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    flexBasis: 25,
    "& svg": {
      fontSize: 25,
      color: isAdjustment
        ? theme.palette.green[400]
        : isFailed
          ? theme.palette.red[400]
          : theme.palette.yellow[400],
    },
  }),
  mainContentContainer: {
    flex: 1,
    minWidth: 0,
    padding: theme.spacing(2, 0, 2, 3),
  },
  secondaryText: {
    color: theme.palette.grey[500],
    display: "block",
  },
  arrowIcon: {
    color: theme.palette.ultraviolet[500],
    fontSize: 19,
  },
  updatedRuleText: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14.5),
  },
  suggestedThresholdChip: {
    height: 22,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: "white",
    marginRight: theme.spacing(1),
    color: theme.palette.grey[600],
    borderRadius: 8,
    "& .MuiChip-label": {
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(14.3),
    },
  },
  segment: {
    borderRadius: 4,
    background: theme.palette.grey[200],
    display: "flex",
    height: 32,
    alignSelf: "center",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  segmentText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 150,
    whiteSpace: "nowrap",
  },
}));

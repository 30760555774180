import {
  ModuleCode,
  ReportTemplateUnit,
  TestAutoThresholdAcceptance,
} from "../../graphql";
import { Severity, ThresholdPredicate } from "../../graphql";
import { RuleAndThreshold } from "../_common/utils/types";

const DEFAULT_SEO_RULE_AND_THRESHOLD: RuleAndThreshold = {
  id: "1",
  severity: Severity.Warning,
  report: {
    id: "TjAxNFJlcG9ydFRlbXBsYXRlMjYxOQ",
    name: "200 Pages",
    code: "200_pages",
    totalSign: 0,
    useableWithSegments: true,
    unit: ReportTemplateUnit.UrLs,
  },
  urls: 100,
  threshold: ThresholdPredicate.GreaterThanOrEqual,
  thresholdAcceptanceWhenWorse: TestAutoThresholdAcceptance.None,
  thresholdAcceptanceWhenBetter: TestAutoThresholdAcceptance.None,
  supportedModules: [ModuleCode.Seo],
};

const DEFAULT_A11Y_RULE_AND_THRESHOLD: RuleAndThreshold = {
  id: "1",
  severity: Severity.Warning,
  report: {
    id: "TjAxNFJlcG9ydFRlbXBsYXRlMjkxMw",
    name: "Level A Issues",
    code: "wcag_a_issues",
    totalSign: 0,
    useableWithSegments: true,
    unit: ReportTemplateUnit.Issues,
  },
  urls: 100,
  threshold: ThresholdPredicate.GreaterThanOrEqual,
  thresholdAcceptanceWhenWorse: TestAutoThresholdAcceptance.None,
  thresholdAcceptanceWhenBetter: TestAutoThresholdAcceptance.None,
  supportedModules: [ModuleCode.Accessibility],
};

const DEFAULT_SITESPEED_RULE_AND_THRESHOLD: RuleAndThreshold = {
  id: "1",
  severity: Severity.Warning,
  report: {
    id: "TjAxNFJlcG9ydFRlbXBsYXRlMjQzNQ",
    name: "All Pages",
    code: "all_pages",
    totalSign: 0,
    useableWithSegments: true,
    unit: ReportTemplateUnit.UrLs,
  },
  urls: 100,
  threshold: ThresholdPredicate.GreaterThanOrEqual,
  thresholdAcceptanceWhenWorse: TestAutoThresholdAcceptance.None,
  thresholdAcceptanceWhenBetter: TestAutoThresholdAcceptance.None,
  supportedModules: [ModuleCode.SiteSpeed],
};

export const DEFAULT_RULE_AND_THRESHOLDS = [
  DEFAULT_SEO_RULE_AND_THRESHOLD,
  DEFAULT_A11Y_RULE_AND_THRESHOLD,
  DEFAULT_SITESPEED_RULE_AND_THRESHOLD,
];

export const THRESHOLD_OPTIONS = [
  {
    value: ThresholdPredicate.GreaterThanOrEqual,
    label: "≥",
    tooltipTitle: "greater than or equal to",
    "data-testid": "greater-than-button",
    "data-pendo": "rule-greater-than-button",
  },
  {
    value: ThresholdPredicate.LessThan,
    label: "<",
    tooltipTitle: "less than",
    "data-testid": "less-than-button",
    "data-pendo": "rule-less-than-button",
  },
];

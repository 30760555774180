import React from "react";
import {
  Typography,
  ExclamationSolid,
  ExclamationCircleSolid,
  ArrowCircleRightSolid,
  CheckCircleSolid,
  Snackbar,
  ChartPieSolid,
  useNumberFormatter,
  useTranslation,
  getRawProjectId,
  useApolloClient,
  useSession,
} from "@lumar/shared";
import {
  makeStyles,
  Tooltip,
  Chip as MuiChip,
  useTheme,
} from "@material-ui/core";
import {
  GetNotificationsQueryVariables,
  ReportTemplateUnit,
  Severity,
  TestAutoThresholdAcceptance,
  ThresholdPredicate,
  useAcceptSuggestedThresholdMutation,
  useGetRulesAndThresholdsQuery,
  useRejectSuggestedThresholdMutation,
  useUpdateRulesAndThresholdsMutation,
} from "../../graphql";
import { SparklinesMiniChart } from "../../_common/components/SparklinesMiniChart/SparklinesMiniChart";
import { useParams } from "react-router-dom";
import { EditRuleAndThresholdDialog } from "../_common/EditRuleAndThresholdDialog";
import { assert } from "../../_common/assert";
import { extractChartDataFromTrends } from "../utils/extractChartDataFromTrends";
import { AbsoluteURLs } from "../../_common/routing/absoluteURLs";
import { EditRuleChip } from "../_common/EditRuleChip";
import { ManageAlertButton } from "../_common/ManageAlertButton";
import { ConditionalLink } from "../_common/ConditionalLink";
import { StatusChips } from "../_common/StatusChips";
import { SuggestedThreshold } from "../_common/SuggestedThreshold";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../_common/utils/getErrorMessage";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";
import { ExtendedThresholdSettings, MonitorNotification } from "../types";
import { ListItemTitle } from "./ListItemTitle";
import { useSuggestedThreshold } from "../utils/useSuggestedThreshold";
import { ProjectInformation } from "./ProjectInformation";
import { extractChartDataFromTestResults } from "../utils/extractChartDataFromTestResults";
import { minBy, maxBy } from "lodash";
import { updateNotificationsInCache } from "../utils/updateNotificationsInCache";
import { useClearNotificationCache } from "../utils/useClearNotificationCache";

const BORDER_RADIUS = 8;

interface Props {
  notification: MonitorNotification;
  selected?: boolean;
  isAdjustment: boolean;
  queryVariables?: GetNotificationsQueryVariables;
  unit: ReportTemplateUnit;
}

export function NotificationListItem({
  notification,
  selected,
  isAdjustment,
  queryVariables,
  unit,
}: Props): JSX.Element {
  const {
    severity,
    absoluteThreshold,
    thresholdPredicate,
    reportStat: report,
    reportTemplate,
    id: notificationID,
  } = notification;

  assert(reportTemplate);
  assert(thresholdPredicate);

  const urlThreshold = absoluteThreshold ?? 0;
  const isFailed = severity === Severity.Fail;

  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [showArrowTooltip, setShowArrowTooltip] = React.useState(false);

  const suggestedThreshold = useSuggestedThreshold(notification);

  const { enqueueSnackbar } = useSnackbar();
  const apollo = useApolloClient();

  const classes = useStyles({ isFailed, isAdjustment });

  const clearCache = useClearNotificationCache();

  const { t } = useTranslation([
    "common",
    "alerts",
    "notifications",
    "errors",
    "units",
  ]);
  const { accountId } = useParams<{ accountId: string }>();
  const theme = useTheme();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const formatNumber = useNumberFormatter();
  const [acceptSuggestedThreshold, { loading }] =
    useAcceptSuggestedThresholdMutation();

  const [rejectSuggestedThreshold] = useRejectSuggestedThresholdMutation();

  const [updateRuleAndThreshold] = useUpdateRulesAndThresholdsMutation({
    refetchQueries: [
      "GetNotifications",
      "NotificationTotalCounts",
      "RequiresManualApprovalCount",
      "GetNotificationsCount",
    ],
    awaitRefetchQueries: true,
  });

  const {
    lastCrawledAt,
    currentUrlCount,
    previousUrlCount,
    seriesData,
    crawlId,
  } = extractChartDataFromTrends(report?.reportTrend);
  const thresholdSeriesData = extractChartDataFromTestResults(
    report?.testResults?.nodes,
  );

  const { data } = useGetRulesAndThresholdsQuery({
    variables: {
      projectId: report?.project.id,
    },
  });

  const doesAlertStillExist = Boolean(
    data?.alert?.rulesAndThresholds.totalCount,
  );

  const differenceFromRule = currentUrlCount - urlThreshold;
  const doesRuleStillExist = Boolean(notification.test);

  const reportPageUrl = report
    ? AbsoluteURLs.EXTERNAL__AnalyzeReport.getUrl({
        accountId,
        crawlId: String(crawlId),
        segmentId: notification?.segment?.id,
        projectId: getRawProjectId(report?.project.id),
        reportTemplateCode: reportTemplate.code,
        reportTypeCode: "basic",
      })
    : "/";

  const hasSuggestedThreshold =
    (suggestedThreshold.thresholdAcceptanceWorse ===
      TestAutoThresholdAcceptance.Suggest &&
      typeof suggestedThreshold.value === "number" &&
      !isAdjustment) ||
    (suggestedThreshold.thresholdAcceptanceBetter ===
      TestAutoThresholdAcceptance.Suggest &&
      typeof suggestedThreshold.value === "number" &&
      isAdjustment);

  const hasThresholdBeenUpdated =
    urlThreshold !== notification.test?.absoluteThreshold ||
    thresholdPredicate !== notification.test.thresholdPredicate;

  const shouldShowSuggestedThreshold =
    hasSuggestedThreshold &&
    suggestedThreshold.isVisible &&
    !(suggestedThreshold.isAccepted || suggestedThreshold.isRejected);

  const showSuggestionChip =
    notification.automaticThresholdAcceptanceWhenTestResultIsWorse &&
    notification.automaticThresholdAcceptanceWhenTestResultIsWorse !==
      TestAutoThresholdAcceptance.None &&
    !shouldShowSuggestedThreshold;

  async function handleAcceptSuggestedThreshold(
    threshold: number,
  ): Promise<void> {
    const hasSuggestedThresholdBeenEdited =
      threshold !== notification?.suggestedAbsoluteThreshold;
    try {
      await acceptSuggestedThreshold({
        variables: {
          testResultIds: [notification.id],
        },
        refetchQueries: [
          "GetNotifications",
          "NotificationTotalCounts",
          "RequiresManualApprovalCount",
        ],
        awaitRefetchQueries: true,
        update: (cache, { data, errors }) => {
          if (data && !errors && queryVariables)
            updateNotificationsInCache(
              cache,
              queryVariables,
              (notification) => {
                if (notification.id !== notificationID) return {};
                return {
                  suggestedAbsoluteThresholdAcceptedAt:
                    new Date().toISOString(),
                };
              },
            );
        },
      });

      if (hasSuggestedThresholdBeenEdited) {
        await updateRuleAndThreshold({
          variables: {
            rulesAndThresholds: {
              testId: notification.test?.id,
              absoluteThreshold: threshold,
            },
          },
        });
      }

      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("notifications:ruleUpdatedSuccessfully")}
        />,
      );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={`${t("errors:genericError")} ${getErrorMessage(e)}`}
        />,
      );
    }
  }

  async function handleDeclineSuggestedThreshold(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): Promise<void> {
    e.preventDefault();

    try {
      await rejectSuggestedThreshold({
        variables: {
          testResultIds: [notification.id],
        },
        refetchQueries: [
          "GetNotifications",
          "NotificationTotalCounts",
          "RequiresManualApprovalCount",
        ],
        awaitRefetchQueries: true,
        update: (cache, { data, errors }) => {
          if (data && !errors && queryVariables)
            updateNotificationsInCache(
              cache,
              queryVariables,
              (notification) => {
                if (notification.id !== notificationID) return {};
                return {
                  suggestedAbsoluteThresholdRejectedAt:
                    new Date().toISOString(),
                };
              },
            );
        },
      });
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("notifications:ruleDeclinedSuccessfully")}
        />,
      );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={`${t("errors:genericError")} ${getErrorMessage(e)}`}
        />,
      );
    }
  }

  function handleRuleAndThresholdUpdate(test: ExtendedThresholdSettings): void {
    if (
      test.absoluteThreshold !== notification.absoluteThreshold ||
      test.automaticThresholdAcceptanceWhenTestResultIsBetter !==
        notification.automaticThresholdAcceptanceWhenTestResultIsBetter ||
      test.automaticThresholdAcceptanceWhenTestResultIsWorse !==
        notification.automaticThresholdAcceptanceWhenTestResultIsWorse ||
      test.thresholdPredicate !== test.thresholdPredicate
    ) {
      if (isAdjustment) clearCache();
      if (queryVariables)
        updateNotificationsInCache(apollo.cache, queryVariables, (nt) => {
          if (notificationID !== nt.id) return {};
          return {
            test: {
              ...nt.test,
              absoluteThreshold: test.absoluteThreshold,
              automaticThresholdAcceptanceWhenTestResultIsBetter:
                test.automaticThresholdAcceptanceWhenTestResultIsBetter,
              automaticThresholdAcceptanceWhenTestResultIsWorse:
                test.automaticThresholdAcceptanceWhenTestResultIsWorse,
              thresholdPredicate: test.thresholdPredicate,
            },
          };
        });
    }
  }

  const { ref, inView } = useInView({
    delay: 0,
    rootMargin: "1000px 0px 1000px 0px",
    threshold: 0,
    fallbackInView: true,
  });

  const isCustomExtraction =
    reportTemplate.code.startsWith("custom_extraction_");
  const reportTemplateName =
    (isCustomExtraction
      ? report?.customExtractions?.find(
          (e) => e.reportTemplateCode === reportTemplate.code,
        )?.label
      : undefined) ?? reportTemplate.name;

  return (
    <>
      <ConditionalLink
        href={reportPageUrl}
        shouldRenderAsLink={Boolean(report)}
        className={classes.link}
        data-pendo="notifications-page-notification-list-item"
        data-testid="notification-list-item"
      >
        <div
          ref={ref}
          data-testid="notification-list-item-hoverable-area"
          className={clsx(
            classes.container,
            selected && classes.bluebackground,
          )}
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
        >
          <div className={classes.statusIconContainer}>
            {isAdjustment ? (
              <CheckCircleSolid />
            ) : isFailed ? (
              <ExclamationCircleSolid data-testid="failed-icon" />
            ) : (
              <ExclamationSolid data-testid="warning-icon" />
            )}
          </div>
          <div className={classes.centralContainer}>
            <div className={classes.mainContentContainer}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={classes.titleContainer}>
                  <ListItemTitle
                    isFailed={isFailed}
                    reportName={reportTemplateName}
                    previousUrlCount={previousUrlCount}
                    currentUrlCount={currentUrlCount}
                    isHovering={isMouseOver}
                    isAdjustment={isAdjustment}
                    unit={unit}
                  />

                  <Typography
                    variant="caption"
                    className={classes.secondaryText}
                    style={{ marginBottom: 5 }}
                  >
                    <strong style={{ fontWeight: 600 }}>
                      {formatNumber(Math.abs(differenceFromRule)) + " "}
                    </strong>
                    {t("notifications:urlsThan", {
                      rule: differenceFromRule >= 0 ? "more" : "less",
                      unit: t(`units:${unit ?? ReportTemplateUnit.UrLs}`, {
                        count: Math.abs(differenceFromRule),
                      }),
                    })}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: theme.spacing(1),
                      gap: 12,
                    }}
                  >
                    <EditRuleChip
                      absoluteThreshold={urlThreshold}
                      thresholdPredicate={thresholdPredicate}
                      onClick={() => {
                        setIsDialogOpen(true);
                      }}
                      doesRuleStillExist={doesRuleStillExist}
                      unit={unit}
                    />
                    {!doesRuleStillExist || hasThresholdBeenUpdated ? (
                      <Typography className={classes.updatedRuleText}>
                        {doesRuleStillExist
                          ? t("notifications:ruleUpdatedTo", {
                              count: notification.test?.absoluteThreshold ?? 0,
                              symbol:
                                notification.test?.thresholdPredicate ===
                                ThresholdPredicate.GreaterThanOrEqual
                                  ? "≥"
                                  : "<",
                              unit: t(
                                `units:${unit ?? ReportTemplateUnit.UrLs}`,
                                {
                                  count:
                                    notification.test?.absoluteThreshold ?? 0,
                                },
                              ),
                            })
                          : t("notifications:ruleDeleted")}
                      </Typography>
                    ) : null}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: theme.spacing(1),
                    }}
                  >
                    {showSuggestionChip ? (
                      <MuiChip
                        label={
                          suggestedThreshold.thresholdAcceptanceWorse ===
                          TestAutoThresholdAcceptance.Suggest
                            ? t("notifications:suggestedThresholdEnabled")
                            : t("notifications:autoThresholdEnabled")
                        }
                        className={classes.suggestedThresholdChip}
                      />
                    ) : null}
                    <Typography
                      variant="captionMedium"
                      className={classes.secondaryText}
                      data-testid="notification-timestamp"
                    >
                      {lastCrawledAt
                        ? t("common:dateAndTime", {
                            date: lastCrawledAt,
                          })
                        : "Unknown"}
                    </Typography>
                  </div>
                  {inView ? (
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <div style={{ marginLeft: 16 }}>
                        <SparklinesMiniChart
                          height={55}
                          width={185}
                          minX={minBy(seriesData, (e) => e[0])?.[0] ?? 0}
                          maxX={maxBy(seriesData, (e) => e[0])?.[0] ?? 1}
                          data={[
                            {
                              values: seriesData,
                              type: "area",
                              color: theme.palette.grey[600],
                              gradient: [
                                [0, theme.palette.grey[100]],
                                [1, theme.palette.grey[100]],
                              ],
                            },
                            {
                              values: thresholdSeriesData,
                              color: "#EF4444",
                              gradient: null,
                              disableMarker: true,
                              dashStyle: "ShortDash",
                              lineWidth: 1,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  minHeight: 40,
                }}
              >
                {report ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <ProjectInformation
                      name={report.project.name}
                      primaryDomain={report.project.primaryDomain}
                    />
                    {segmentationAvailable && (
                      <>
                        <div
                          style={{
                            marginLeft: theme.spacing(1.375),
                            marginRight: theme.spacing(1.375),
                            width: 1,
                            background: theme.palette.grey[300],
                          }}
                        />
                        <Tooltip
                          title={
                            <div>
                              <Typography
                                variant="h1Bold"
                                style={{
                                  fontSize: theme.typography.pxToRem(11),
                                }}
                              >
                                {t("notifications:segment")}
                              </Typography>
                              <div>
                                {notification.segment?.name ??
                                  t("common:noSegments")}
                              </div>
                            </div>
                          }
                          arrow={false}
                        >
                          <div className={classes.segment}>
                            <ChartPieSolid
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: theme.spacing(1),
                              }}
                            />
                            <Typography
                              variant="captionSemiBold"
                              className={classes.segmentText}
                            >
                              {notification.segment?.name ??
                                t("common:noSegments")}
                            </Typography>
                          </div>
                        </Tooltip>
                      </>
                    )}
                  </div>
                ) : null}
                <div
                  // Note: stops the user from being redirected to analyze app if they try to click the button/chips and miss
                  onClick={(e) => e.preventDefault()}
                  className={classes.statusChipsContainer}
                >
                  <div style={{ marginLeft: "auto", marginBottom: -7 }} />

                  {isMouseOver ? (
                    <>
                      {report ? (
                        <ManageAlertButton
                          alertId={report.project.id}
                          disabled={!doesAlertStillExist}
                        />
                      ) : null}
                      {!isAdjustment ? (
                        <StatusChips
                          notificationId={notification.id}
                          initialStatus={notification.status}
                        />
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {shouldShowSuggestedThreshold ? (
              <SuggestedThreshold
                onAccept={handleAcceptSuggestedThreshold}
                onDecline={handleDeclineSuggestedThreshold}
                initialValue={suggestedThreshold.value ?? 0}
                thresholdPredicate={thresholdPredicate}
                loading={loading}
                isResponsive={true}
                unit={unit}
              />
            ) : null}
          </div>
          <div
            style={{
              width: 50,
              display: "flex",
              alignItems: "center",
              borderTopRightRadius: BORDER_RADIUS,
              borderBottomRightRadius: BORDER_RADIUS,
              justifyContent: "center",
            }}
            onMouseEnter={() => setShowArrowTooltip(true)}
            onMouseLeave={() => setShowArrowTooltip(false)}
          >
            {isMouseOver ? (
              <Tooltip
                title={t("common:goToReport") as string}
                arrow={false}
                open={showArrowTooltip}
                placement="bottom"
              >
                <ArrowCircleRightSolid className={classes.arrowIcon} />
              </Tooltip>
            ) : null}
          </div>
        </div>
      </ConditionalLink>
      {isDialogOpen ? (
        <EditRuleAndThresholdDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onRuleAndThresholdUpdate={handleRuleAndThresholdUpdate}
          notification={notification}
          previousUrlCount={previousUrlCount}
          currentUrlCount={currentUrlCount}
          reportTrendData={seriesData}
          currentRuleSettings={notification.test}
          ruleSettingsAtTimeOfCrawl={{
            absoluteThreshold: urlThreshold,
            thresholdPredicate,
            severity,
          }}
          thresholdTrendData={thresholdSeriesData}
        />
      ) : null}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    borderRadius: BORDER_RADIUS,
    marginTop: 1,
    marginBottom: 14,
    backgroundColor: "white",
    minHeight: 154.37,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    border: "1px solid transparent",
    "&:hover": {
      border: `1px solid ${theme.palette.grey[400]}`,
      marginTop: 1,
      marginBottom: 14,
      boxShadow:
        "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
    },
  },
  statusIconContainer: ({
    isFailed,
    isAdjustment,
  }: {
    isFailed: boolean;
    isAdjustment: boolean;
  }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    backgroundColor: theme.palette.grey[50],
    borderTopLeftRadius: BORDER_RADIUS,
    borderBottomLeftRadius: BORDER_RADIUS,
    flexBasis: 76,
    "& svg": {
      fontSize: 36,
      color: isAdjustment
        ? theme.palette.green[400]
        : isFailed
          ? theme.palette.red[400]
          : theme.palette.yellow[400],
    },
  }),
  mainContentContainer: {
    flex: 1,
    minWidth: 0,
    padding: theme.spacing(2, 0, 2, 3),
  },
  secondaryText: {
    color: theme.palette.grey[500],
    display: "block",
  },
  arrowIcon: {
    color: theme.palette.ultraviolet[500],
    fontSize: 24,
  },
  suggestedThresholdChip: {
    height: 22,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: "white",
    marginRight: theme.spacing(1),
    color: theme.palette.grey[600],
    borderRadius: 8,
    "& .MuiChip-label": {
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(14.3),
    },
  },
  updatedRuleText: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14.5),
  },
  statusChipsContainer: {
    display: "flex",
    alignItems: "center",
    padding: 5,
    marginRight: -5,
    marginBottom: -5,
    width: 350,
  },
  bluebackground: {
    border: `2px solid ${theme.palette.ultraviolet[500]}`,
    marginTop: 0,
    marginBottom: 13,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    flex: 1,
    width: "calc(100% - 46px)",
  },
  centralContainer: {
    display: "flex",
    flex: 1,
    ["@media (max-width: 1050px)"]: {
      display: "block",
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    minWidth: 0,
  },
  segment: {
    borderRadius: 4,
    background: theme.palette.grey[200],
    display: "flex",
    height: 32,
    alignSelf: "center",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  segmentText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 300,
    whiteSpace: "nowrap",
  },
}));

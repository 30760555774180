import React from "react";
import { Tooltip, TooltipProps } from "@material-ui/core";

interface Props extends TooltipProps {
  show: boolean;
}

/**
 * Use this component to conditionally hide/show content.
 */
export function ConditionallTooltip({
  show,
  children,
  title,
  ...props
}: Props): JSX.Element {
  return (
    <Tooltip title={show ? title : ""} {...props}>
      {children}
    </Tooltip>
  );
}

import { useParams } from "react-router-dom";
import {
  useDeleteRulesAndThresholdsMutation,
  GetNotificationsDocument,
  useDeleteSlackWebhookMutation,
  useDeleteEmailAlertMutation,
  GetAlertWithTestsQuery,
  GetProjectListForDialogDocument,
  useDeleteMsTeamsWebhookMutation,
} from "../../../graphql";

export function useDeleteAlertMutation(): [
  (alert: GetAlertWithTestsQuery["alert"]) => Promise<void>,
] {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const [deleteRulesAndThresholds] = useDeleteRulesAndThresholdsMutation({
    refetchQueries: [
      {
        query: GetNotificationsDocument,
        variables: {
          accountId,
        },
      },
      {
        query: GetProjectListForDialogDocument,
        variables: {
          accountId,
        },
      },
      "GetTestsTotalCount",
    ],
  });

  const [deleteSlackWebhook] = useDeleteSlackWebhookMutation();
  const [deleteEmailAlert] = useDeleteEmailAlertMutation();
  const [deleteMSAlert] = useDeleteMsTeamsWebhookMutation();

  async function deleteAlert(
    alert: GetAlertWithTestsQuery["alert"],
  ): Promise<void> {
    const testIds = alert?.tests.nodes.map((n) => n.id) ?? [];
    const emailIds = alert?.emailAlerts?.nodes.map((n) => n.id) ?? [];
    const slackIds = alert?.slackWebhooks?.nodes.map((n) => n.id) ?? [];
    const msIds = alert?.webhooks?.nodes.map((n) => n.id) ?? [];

    const promiseEmailArray = emailIds.map((id) =>
      deleteEmailAlert({
        variables: {
          id,
        },
        context: {
          includeInBatch: true,
        },
      }),
    );
    const promiseSlackArray = slackIds.map((id) =>
      deleteSlackWebhook({
        variables: {
          id,
        },
        context: {
          includeInBatch: true,
        },
      }),
    );
    const promiseMSArray = msIds.map((id) =>
      deleteMSAlert({
        variables: {
          id,
        },
        context: {
          includeInBatch: true,
        },
      }),
    );
    await Promise.all([
      deleteRulesAndThresholds({
        variables: { testIds },
        context: {
          includeInBatch: true,
        },
      }),
      promiseEmailArray,
      promiseSlackArray,
      promiseMSArray,
    ]);
  }

  return [deleteAlert];
}

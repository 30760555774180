import { TestAutoThresholdAcceptance } from "../../graphql";
import { MonitorNotification } from "../types";

interface SuggestedThresholdSettings {
  thresholdAcceptanceWorse: TestAutoThresholdAcceptance;
  thresholdAcceptanceBetter: TestAutoThresholdAcceptance;
  isVisible: boolean;
  isAccepted: boolean;
  isRejected: boolean;
  value: number | null | undefined;
}

export const useSuggestedThreshold = (
  notification: MonitorNotification,
): SuggestedThresholdSettings => {
  const isFromMostRecentCrawl =
    notification.reportStat?.crawlId ===
    notification.reportStat?.project.lastFinishedCrawl?.id;
  const isNotAltered =
    notification.test?.reportTemplateCode ===
      notification.reportTemplate.code &&
    notification.test?.thresholdType === notification.thresholdType &&
    notification.test?.thresholdPredicate === notification.thresholdPredicate &&
    notification.test?.absoluteThreshold === notification.absoluteThreshold;

  return {
    thresholdAcceptanceWorse:
      notification.automaticThresholdAcceptanceWhenTestResultIsWorse ??
      TestAutoThresholdAcceptance.None,
    thresholdAcceptanceBetter:
      notification.automaticThresholdAcceptanceWhenTestResultIsBetter ??
      TestAutoThresholdAcceptance.None,
    isVisible: isFromMostRecentCrawl && isNotAltered,
    isAccepted: Boolean(notification.suggestedAbsoluteThresholdAcceptedAt),
    isRejected: Boolean(notification.suggestedAbsoluteThresholdRejectedAt),
    value: notification?.suggestedAbsoluteThreshold,
  };
};

import { ReactNode, useState } from "react";
import {
  Alert,
  Dialog,
  ExclamationSolid,
  ExclamationCircleSolid,
  SelectableButtonGroup,
  Snackbar,
  Typography,
  useTranslation,
  useSession,
} from "@lumar/shared";
import {
  Table,
  TableCell,
  TableRow,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  Maybe,
  ReportTemplateUnit,
  Severity,
  TestAutoThresholdAcceptance,
  ThresholdPredicate,
  ThresholdType,
  useUpdateRulesAndThresholdsMutation,
} from "../../graphql";
import { MAX_ABSOLUTE_THRESHOLD_URLS } from "../../alerts/_common/utils/constants";
import { useSnackbar } from "notistack";
import { ReportTrendInfo } from "./ReportTrendInfo";
import {
  ExtendedThresholdSettings,
  MonitorNotification,
  ThresholdSettings,
} from "../types";
import { getErrorMessage } from "../../_common/utils/getErrorMessage";
import { ThresholdAcceptanceMenu } from "../../alerts/_common/ThresholdAcceptanceMenu";
import { TextFieldWithUnit } from "../../alerts/_common/TextFieldWithUnit";
import clsx from "clsx";
export interface EditRuleAndThresholdDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onRuleAndThresholdUpdate: (test: ExtendedThresholdSettings) => void;
  notification: MonitorNotification;
  previousUrlCount: number;
  currentUrlCount: number;
  reportTrendData: [number, number][];
  thresholdTrendData?: [number, number][];
  currentRuleSettings?: Maybe<ExtendedThresholdSettings>;
  ruleSettingsAtTimeOfCrawl: ThresholdSettings;
}

const haveRuleSettingsChanged = (
  settingsAtTimeOfCrawl: ThresholdSettings,
  currentSettings?: Maybe<ExtendedThresholdSettings>,
): boolean => {
  return (
    settingsAtTimeOfCrawl.absoluteThreshold !==
      currentSettings?.absoluteThreshold ||
    settingsAtTimeOfCrawl.severity !== currentSettings.severity ||
    settingsAtTimeOfCrawl.thresholdPredicate !==
      currentSettings.thresholdPredicate
  );
};

export function EditRuleAndThresholdDialog({
  isOpen,
  onClose,
  notification,
  previousUrlCount,
  currentUrlCount,
  reportTrendData,
  thresholdTrendData,
  currentRuleSettings,
  ruleSettingsAtTimeOfCrawl,
  onRuleAndThresholdUpdate,
}: EditRuleAndThresholdDialogProps): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);
  const [severity, setSeverity] = useState<Severity>(
    currentRuleSettings?.severity ?? Severity.Fail,
  );
  const [thresholdPredicate, setThresholdPredicate] =
    useState<ThresholdPredicate>(
      currentRuleSettings?.thresholdPredicate ??
        ThresholdPredicate.GreaterThanOrEqual,
    );
  const [urlThreshold, setUrlThreshold] = useState<number>(
    currentRuleSettings?.absoluteThreshold ?? 100,
  );

  const [thresholdAcceptanceWorse, setThresholdAcceptanceWorse] = useState(
    currentRuleSettings?.automaticThresholdAcceptanceWhenTestResultIsWorse ??
      TestAutoThresholdAcceptance.None,
  );
  const [thresholdAcceptanceBetter, setThresholdAcceptanceBetter] = useState(
    currentRuleSettings?.automaticThresholdAcceptanceWhenTestResultIsBetter ??
      TestAutoThresholdAcceptance.None,
  );

  const report = notification.reportTemplate;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation([
    "alerts",
    "common",
    "filterPredicates",
    "notifications",
  ]);

  const [updateRuleAndThreshold, { loading }] =
    useUpdateRulesAndThresholdsMutation({
      refetchQueries: [
        "GetNotifications",
        "RequiresManualApprovalCount",
        "NotificationTotalCounts",
        "GetNotificationsCount",
      ],
      awaitRefetchQueries: true,
    });

  const isUrlThresholdTooBigOrSmall =
    Number(urlThreshold) < 0 ||
    Number(urlThreshold) > MAX_ABSOLUTE_THRESHOLD_URLS;

  const canSaveChanges = haveValuesChanged && !isUrlThresholdTooBigOrSmall;

  async function handleSave(): Promise<void> {
    try {
      await updateRuleAndThreshold({
        variables: {
          rulesAndThresholds: {
            severity,
            thresholdPredicate,
            absoluteThreshold: Number(urlThreshold),
            testId: currentRuleSettings?.id,
            thresholdType: ThresholdType.Absolute,
            automaticThresholdAcceptanceWhenTestResultIsWorse:
              thresholdAcceptanceWorse,
            automaticThresholdAcceptanceWhenTestResultIsBetter:
              thresholdAcceptanceBetter,
          },
        },
      });
      onRuleAndThresholdUpdate({
        thresholdPredicate,
        absoluteThreshold: Number(urlThreshold),
        id: currentRuleSettings?.id,
        severity,
        automaticThresholdAcceptanceWhenTestResultIsWorse:
          thresholdAcceptanceWorse,
        automaticThresholdAcceptanceWhenTestResultIsBetter:
          thresholdAcceptanceBetter,
      });
      enqueueSnackbar(
        <Snackbar
          title={t("notificationsPage.thresholdUpdatedSuccessfully")}
          variant="success"
        />,
      );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          title={t("notificationsPage.thresholdFailedToUpdate", {
            message: getErrorMessage(e),
          })}
          variant="error"
        />,
      );
    }

    onClose();
  }

  const SEVERITY_OPTIONS = [
    {
      value: Severity.Warning,
      label: t(Severity.Warning),
      hideLabel: true,
      tooltipTitle: t(`notifications:${Severity.Warning}`),
      "data-pendo": "edit-rule-and-threshold-dialog-warning-button",
      // eslint-disable-next-line react/display-name
      startIcon: (isSelected: boolean): ReactNode => (
        <ExclamationSolid
          style={{ fontSize: theme.typography.pxToRem(16) }}
          htmlColor={
            isSelected ? theme.palette.yellow[400] : theme.palette.grey[200]
          }
        />
      ),
    },
    {
      value: Severity.Fail,
      label: t(Severity.Fail),
      hideLabel: true,
      tooltipTitle: t(`notifications:${Severity.Fail}`),
      "data-pendo": "edit-rule-and-threshold-dialog-fail-button",
      // eslint-disable-next-line react/display-name
      startIcon: (isSelected: boolean): ReactNode => (
        <ExclamationCircleSolid
          style={{ fontSize: theme.typography.pxToRem(16) }}
          htmlColor={
            isSelected ? theme.palette.red[400] : theme.palette.grey[200]
          }
        />
      ),
    },
  ];

  const isCustomExtraction = report.code.startsWith("custom_extraction_");
  const reportTemplateName =
    (isCustomExtraction
      ? notification.reportStat?.customExtractions?.find(
          (e) => e.reportTemplateCode === report.code,
        )?.label
      : undefined) ?? report.name;

  return (
    <Dialog
      title={t("notificationsPage.editRule")}
      open={isOpen}
      data-testid="edit-rule-and-threshold-dialog"
      onCloseViaIcon={onClose}
      onClose={onClose}
      anchorEl={undefined}
      buttons={[
        {
          title: t("common:cancel"),
          variant: "outlined",
          onClick: onClose,
          "data-pendo": "edit-rule-and-threshold-dialog-cancel-button",
        },
        {
          title: loading ? t("common:saving") : t("common:saveChanges"),
          variant: "contained",
          color: "primary",
          onClick: handleSave,
          disabled: !canSaveChanges || loading,
          "data-pendo": "edit-rule-and-threshold-dialog-save-changes-button",
          "data-testid": "edit-rule-and-threshold-dialog-save-changes-button",
        },
      ]}
    >
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          padding: "14px 8px 22px 8px",
        }}
        data-testid="edit-rule-dialog-content"
      >
        {haveRuleSettingsChanged(
          ruleSettingsAtTimeOfCrawl,
          currentRuleSettings,
        ) ? (
          <Alert
            severity="info"
            size="small"
            data-testid="changed-rule-and-threshold-info-message"
            style={{ marginBottom: 8 }}
          >
            <Typography
              variant="subtitle1SemiBold"
              style={{ fontSize: theme.typography.pxToRem(12) }}
            >
              {t("edited_fp")}
            </Typography>
            &nbsp;
            <Typography
              variant="subtitle1"
              style={{ fontSize: theme.typography.pxToRem(12) }}
            >
              {t("edited_sp")}
            </Typography>
          </Alert>
        ) : undefined}
        <Table className={classes.ruleAndThresholdContainer}>
          <TableRow
            style={{
              boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset,0px 1px 0px 0px #E5E7EB inset, 1px 0px 0px 0px #E5E7EB inset`,
              height: 56,
            }}
          >
            <TableCell className={classes.tableCell}>
              <SelectableButtonGroup
                value={severity}
                colorVariant="muted"
                onValueChange={(newSeverity) => {
                  setHaveValuesChanged(true);
                  setSeverity(newSeverity);
                }}
                options={SEVERITY_OPTIONS}
                dataTestId="severity-button-group"
                size="small"
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography
                className={classes.textCell}
                variant="body2"
                data-testid="edit-rule-and-threshold-report-name"
              >
                {reportTemplateName}
              </Typography>
            </TableCell>
            {segmentationAvailable && (
              <TableCell className={classes.tableCell}>
                <Typography
                  className={classes.textCell}
                  variant="body2"
                  data-testid="edit-rule-and-threshold-report-name"
                >
                  {notification.segment?.name ?? t("common:noSegments")}
                </Typography>
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              <SelectableButtonGroup
                value={thresholdPredicate}
                colorVariant="muted"
                size="small"
                dataTestId="threshold-predicate-button-group"
                onValueChange={(newPred) => {
                  setHaveValuesChanged(true);
                  setThresholdPredicate(newPred);
                }}
                options={[
                  {
                    value: ThresholdPredicate.GreaterThanOrEqual,
                    label: "≥",
                    tooltipTitle: t(
                      "filterPredicates:greaterThanOrEqualTo",
                    ) as string,
                    "data-pendo": "edit-rule-and-threshold-dialog-gte-button",
                  },
                  {
                    value: ThresholdPredicate.LessThan,
                    label: "<",
                    tooltipTitle: t("filterPredicates:lessThan") as string,
                    "data-pendo": "edit-rule-and-threshold-dialog-lt-button",
                  },
                ]}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <TextFieldWithUnit
                variant="outlined"
                type="number"
                unit={t(
                  `units:${
                    notification.reportTemplate?.metadata?.unit ??
                    ReportTemplateUnit.UrLs
                  }`,
                  { count: urlThreshold },
                )}
                value={urlThreshold}
                error={isUrlThresholdTooBigOrSmall}
                className={classes.textField}
                helperText={
                  isUrlThresholdTooBigOrSmall
                    ? Number(urlThreshold) < 0
                      ? t("alerts:minUrlsValidationMessage")
                      : t("alerts:maxUrlsValidationMessage", {
                          count: MAX_ABSOLUTE_THRESHOLD_URLS,
                        })
                    : undefined
                }
                data-pendo="edit-rule-and-threshold-dialog-url-threshold-input"
                data-testid="edit-rule-and-threshold-absolute-urls"
                onChange={(e) => {
                  setHaveValuesChanged(true);
                  setUrlThreshold(Number(e.target.value));
                }}
                style={{ maxWidth: 100 }}
              />
            </TableCell>
            <TableCell className={clsx(classes.tableCell, classes.issuesCell)}>
              <ThresholdAcceptanceMenu
                value={thresholdAcceptanceWorse}
                onChange={(e) => {
                  setHaveValuesChanged(true);
                  setThresholdAcceptanceWorse(
                    e.target.value as TestAutoThresholdAcceptance,
                  );
                }}
                label={t("rulesAndThresholds.thresholdSettingWhenWorse")}
                subheader={t("rulesAndThresholds.ifIssueWorsens")}
                data-pendo={"edit-threshold-acceptance-when-worse"}
                data-testid={"edit-threshold-acceptance-when-worse"}
              />
            </TableCell>
            <TableCell className={clsx(classes.tableCell, classes.issuesCell)}>
              <ThresholdAcceptanceMenu
                value={thresholdAcceptanceBetter}
                onChange={(e) => {
                  setHaveValuesChanged(true);
                  setThresholdAcceptanceBetter(
                    e.target.value as TestAutoThresholdAcceptance,
                  );
                }}
                label={t("rulesAndThresholds.thresholdSettingWhenBetter")}
                subheader={t("rulesAndThresholds.ifIssueImproves")}
                data-pendo={"edit-threshold-acceptance-when-better"}
                data-testid={"edit-threshold-acceptance-when-better"}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <ReportTrendInfo
                unit={notification.reportTemplate.metadata?.unit}
                currentUrlCount={currentUrlCount}
                previousUrlCount={previousUrlCount}
                totalSign={Math.sign(
                  notification.reportStat?.totalWeight ??
                    notification.reportTemplate.totalSign ??
                    0,
                )}
                reportTrendData={reportTrendData}
                thresholdTrendData={thresholdTrendData}
                absoluteThreshold={Number(urlThreshold)}
                edited={haveValuesChanged}
              />
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    marginRight: 0,
    "& [class*=MuiFormControlLabel-label]": {
      marginRight: 12,
      fontWeight: "inherit",
    },
  },
  ruleAndThresholdContainer: {
    padding: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[700],
  },
  suggestedThresholdContainer: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 5,
    margin: "-6px 0px",
    marginRight: theme.spacing(5),
  },
  textField: {
    "& input": {
      height: 17,
      paddingTop: "6px!important",
      paddingBottom: "6px!important",
    },
    "& .Mui-disabled": {
      color: theme.palette.grey[400],
    },
    minWidth: 150,
  },
  tableCell: {
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    padding: theme.spacing(1),
  },
  issuesCell: {
    background: "#EBEFF3",
  },
  textCell: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 200,
    overflowX: "hidden",
    minWidth: 100,
    textAlign: "center",
  },
}));

import { FaviconIcon, Typography } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    color: theme.palette.grey[500],
    display: "block",
  },
  faviconWrapper: {
    height: 26,
    width: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    minWidth: 0,
  },
}));

interface Props {
  name: string;
  primaryDomain: string;
}

export const ProjectInformation = ({
  name,
  primaryDomain,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.faviconWrapper}>
        <FaviconIcon height={19} width={19} url={primaryDomain} />
      </div>
      <span style={{ marginLeft: 15, minWidth: 0 }}>
        <Typography
          variant="captionMedium"
          component="p"
          className={classes.secondaryText}
          data-testid="notification-project-name"
          noWrap
        >
          {name}
        </Typography>
        <Typography
          variant="captionMedium"
          className={classes.secondaryText}
          noWrap
          style={{ fontWeight: 400 }}
        >
          {primaryDomain}
        </Typography>
      </span>
    </div>
  );
};

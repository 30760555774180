import {
  Alert,
  PlusSolid,
  ApolloError,
  EmptyState,
  useSession,
} from "@lumar/shared";
import { Box, CircularProgress, makeStyles, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import sleepingCatAnimation from "../../_animations/sleeping-cat-animation.json";
import { NotificationItem } from "./Notification";
import {
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  ReportTemplateUnit,
} from "../../graphql";
import { LoadMoreOnScroll } from "../../_common/components/LoadMoreOnScroll";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import { NotificationStatusFilter } from "../filters/constants";
import { MonitorNotification } from "../types";
import { getNotificationList } from "../utils/utils";
import { useMemo } from "react";
import { RoleCode } from "@lumar/shared/dist/graphql";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 312,
  },
  listItemContainer: {
    maxHeight: 570,
    overflowY: "scroll",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

interface Props {
  data: GetNotificationsQuery | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  hasFetchedAllData: boolean;
  onLoadMore: () => void;
  notificationStatus: NotificationStatusFilter;
  queryVariables?: GetNotificationsQueryVariables;
}

export function NotificationsMenuList({
  data,
  error,
  loading,
  hasFetchedAllData,
  onLoadMore,
  notificationStatus,
  queryVariables,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts", "common"]);
  const session = useSession();
  const theme = useTheme();

  const { createAlertPage } = useMonitorRoutes();

  const notifications: MonitorNotification[] = useMemo<MonitorNotification[]>(
    () => getNotificationList(data) ?? [],
    [data],
  );

  if (error) {
    return (
      <Box className={classes.container}>
        <Alert severity="error">
          <strong>
            {t("alerts:notificationsPage.failedToLoadNotifications")}
          </strong>{" "}
          {error.message}
        </Alert>
      </Box>
    );
  }

  if (loading) {
    return (
      <div className={classes.container}>
        <CircularProgress style={{ height: 18, width: 18 }} />
      </div>
    );
  }

  const isAdjustmentTabSelected =
    notificationStatus === NotificationStatusFilter.Adjustments;

  if (!notifications.length) {
    return (
      <div className={classes.container}>
        <EmptyState
          icon={
            <Lottie
              loop
              animationData={sleepingCatAnimation}
              play
              style={{ width: "80px", marginBottom: -theme.spacing(1) }}
            />
          }
          description={t("setUpAlertsMessage")}
          actions={
            session.hasSufficientRole(RoleCode.Editor)
              ? [
                  {
                    type: "internalLink",
                    icon: <PlusSolid />,
                    to: createAlertPage.getUrl(),
                    title: t("createNewAlert"),
                    "data-pendo": "view-notifications-create-alert-button",
                    "data-testid": "create-new-alert-button",
                  },
                ]
              : []
          }
        />
      </div>
    );
  }

  return (
    <div className={classes.listItemContainer}>
      {notifications.map((notification) => (
        <NotificationItem
          queryVariables={queryVariables}
          key={notification.id}
          notification={notification}
          isAdjustment={isAdjustmentTabSelected}
          unit={
            notification.reportTemplate.metadata?.unit ??
            ReportTemplateUnit.UrLs
          }
        />
      ))}

      {!hasFetchedAllData ? <LoadMoreOnScroll onLoadMore={onLoadMore} /> : null}
    </div>
  );
}

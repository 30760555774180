import React from "react";
import {
  GetAlertWithTestsQuery,
  ReportTemplateUnit,
  TestAutoThresholdAcceptance,
} from "../../../graphql";
import { RulesAndThresholdsFormState } from "../../_common/utils/types";
import { canReportTemplateBeUsedWithSegments } from "../../../_common/hooks/useReportTemplateAccumulator";

export function useCreateNewRulesFromData(
  data: GetAlertWithTestsQuery | undefined,
  preserveIds?: boolean,
): RulesAndThresholdsFormState {
  const newRules = React.useMemo(() => {
    const rulesAndThresholds = (
      data?.alert?.tests?.nodes.map((e) => {
        return {
          severity: e.severity,
          report: {
            ...e.reportTemplate,
            unit: e.reportTemplate.metadata?.unit ?? ReportTemplateUnit.UrLs,
            useableWithSegments: canReportTemplateBeUsedWithSegments(
              e.reportTemplate.datasourceCodeEnum,
            ),
          },
          segment: e.segment,
          threshold: e.thresholdPredicate,
          urls: e.absoluteThreshold,
          thresholdAcceptanceWhenWorse:
            e.automaticThresholdAcceptanceWhenTestResultIsWorse ??
            TestAutoThresholdAcceptance.None,
          thresholdAcceptanceWhenBetter:
            e.automaticThresholdAcceptanceWhenTestResultIsBetter ??
            TestAutoThresholdAcceptance.None,
          id: preserveIds ? e.id : undefined,
        };
      }) ?? []
    ).filter(
      (e) =>
        (!Boolean(e.segment) &&
          !e.report.code.startsWith("custom_extraction_")) ||
        preserveIds,
    );

    return {
      rulesAndThresholds,
      emailAlerts:
        data?.alert?.emailAlerts?.nodes.map(({ email }) => email) ?? [],
      slackWebhooks:
        data?.alert?.slackWebhooks?.nodes.map(({ url }) => url) ?? [],
      microsoftTeamsWebhooks:
        data?.alert?.webhooks?.nodes.map(({ url }) => url) ?? [],
    };
  }, [
    data?.alert?.emailAlerts?.nodes,
    data?.alert?.slackWebhooks?.nodes,
    data?.alert?.tests?.nodes,
    data?.alert?.webhooks?.nodes,
    preserveIds,
  ]);

  return newRules;
}

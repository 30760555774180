import {
  ShieldCheckSolid,
  ShieldExclamationSolid,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { RulesAndThresholdsTableTitle } from "./RulesAndThresholdsTableTitle";

export const URL_THRESHOLD_WIDTH = 100;
export const AUTO_THRESHOLD_WIDTH = 130;
export const URL_THRESHOLD_RIGHT_PADDING = 15;
export const REPORT_COMBOBOX_MIN_WIDTH = 100;
export const REPORT_COMBOBOX_MAX_WIDTH = 500;
export const SEVERITY_WIDTH = 183; // 153 +9 + 12 + 9
export const RULE_WIDTH = 80;

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(21.5),
    marginBottom: theme.spacing(1),
    display: "block",
    color: "white",
  },
  subtitle: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(15.5),
    marginBottom: theme.spacing(1),
    color: "inherit",
  },
  asterix: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(15.5),
    marginBottom: theme.spacing(1),
    color: "inherit",
  },
  image: {
    display: "block",
  },
  ul: {
    paddingInlineStart: 15,
  },
  report: {
    flex: 1,
  },
  urlThreshold: {
    maxWidth: 100,
    flexShrink: 100,
    minWidth: 80,
    marginRight: 32, // Note: this is to factor in the width of the `and` text next to the threshold input
  },
  suggestedThreshold: {
    minWidth: 100,
    flex: 1,
  },
  lineheight: { lineHeight: theme.typography.pxToRem(14.3) },
  lineheight2: { lineHeight: theme.typography.pxToRem(13.3) },
}));

export function SeverityTitle(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts", "common", "filterPredicates"]);

  return (
    <RulesAndThresholdsTableTitle
      title={t("alertSeverity")}
      tooltipTitle={
        <>
          <Typography component="h3" className={classes.title}>
            {t("alertSeverity")}
          </Typography>
          <Typography component="p">
            {t("rulesAndThresholds.alertSeverityExplanation")}
          </Typography>
          <Typography component="p" className={classes.subtitle}>
            {t("common:warning")}
          </Typography>
          <ul className={classes.ul}>
            <li>{t("rulesAndThresholds.warningBulletPoint1")}</li>
            <li>{t("rulesAndThresholds.warningBulletPoint2")}</li>
          </ul>
          <Typography component="p" className={classes.subtitle}>
            {t("alerts:critical")}
          </Typography>
          <ul className={classes.ul}>
            <li>{t("rulesAndThresholds.criticalBulletPoint1")}</li>
            <li>{t("rulesAndThresholds.criticalBulletPoint2")}</li>
          </ul>
        </>
      }
    />
  );
}

export function ReportTitle(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts", "common", "filterPredicates"]);

  return (
    <RulesAndThresholdsTableTitle
      title={t("common:report")}
      className={classes.report}
      tooltipTitle={
        <>
          <Typography className={classes.title} component="h3">
            {t("common:report")}
          </Typography>
          <Typography component="p">
            {t("rulesAndThresholds.reportDescription")}
          </Typography>
        </>
      }
    />
  );
}

export function RuleTitle(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts", "common", "filterPredicates"]);

  return (
    <RulesAndThresholdsTableTitle
      title={t("common:rule")}
      tooltipTitle={
        <>
          <Typography className={classes.title} component="h3">
            {t("common:rule")}
          </Typography>
          <Typography component="p">
            {t("rulesAndThresholds.ruleDescription")}
          </Typography>
          <ul className={classes.ul}>
            <li>{t("filterPredicates:greaterThanOrEqualTo")}</li>
            <li>{t("filterPredicates:lessThan")}</li>
          </ul>
        </>
      }
    />
  );
}

export function ThresholdTitle(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts", "common", "filterPredicates"]);

  return (
    <RulesAndThresholdsTableTitle
      title={t("rulesAndThresholds.urlThreshold")}
      className={classes.urlThreshold}
      tooltipTitle={
        <>
          <Typography className={classes.title} variant="h6SemiBold">
            {t("rulesAndThresholds.urlThreshold")}
          </Typography>
          <Typography variant="caption" className={classes.lineheight}>
            {t("rulesAndThresholds.manualThresholdDescriptionP1")}
          </Typography>
          <div style={{ height: 13 }} />
          <Typography variant="caption" className={classes.lineheight}>
            {t("rulesAndThresholds.manualThresholdDescriptionP2")}
          </Typography>
          <div style={{ height: 21 }} />
        </>
      }
    />
  );
}

export function IfWorsensTitle(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts", "common", "filterPredicates"]);

  return (
    <RulesAndThresholdsTableTitle
      title={t("rulesAndThresholds.ifWorsens.title")}
      className={classes.suggestedThreshold}
      startIcon={
        <ShieldExclamationSolid
          style={{ width: 17, height: 17, marginRight: 10 }}
        />
      }
      tooltipTitle={
        <>
          <Typography component="h3" className={classes.title}>
            {t("rulesAndThresholds.ifWorsens.title")}
          </Typography>
          <Typography component="p" style={{ marginBottom: 8 }}>
            {t("rulesAndThresholds.ifWorsens.part1")}
          </Typography>
          <Typography component="p" style={{ marginBottom: 8 }}>
            {t("rulesAndThresholds.ifWorsens.part2")}
          </Typography>
          <Typography component="p" className={classes.subtitle}>
            {t("rulesAndThresholds.ifWorsens.part3")}
          </Typography>
          <ul className={classes.ul}>
            <li>{t("rulesAndThresholds.ifWorsens.list1")}</li>
            <li>{t("rulesAndThresholds.ifWorsens.list2")}</li>
            <li>{t("rulesAndThresholds.ifWorsens.list3")}</li>
          </ul>
          <Typography component="p" className={classes.asterix}>
            {t("rulesAndThresholds.ifWorsens.part4")}
          </Typography>
        </>
      }
    />
  );
}

export function IfImprovesTitle(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts", "common", "filterPredicates"]);

  return (
    <RulesAndThresholdsTableTitle
      title={t("rulesAndThresholds.ifImproves.title")}
      startIcon={
        <ShieldCheckSolid style={{ width: 17, height: 17, marginRight: 10 }} />
      }
      className={classes.suggestedThreshold}
      tooltipTitle={
        <>
          <Typography component="h3" className={classes.title}>
            {t("rulesAndThresholds.ifImproves.title")}
          </Typography>
          <Typography component="p" style={{ marginBottom: 8 }}>
            {t("rulesAndThresholds.ifImproves.part1")}
          </Typography>
          <Typography component="p" style={{ marginBottom: 8 }}>
            {t("rulesAndThresholds.ifImproves.part2")}
          </Typography>
          <Typography component="p" className={classes.subtitle}>
            {t("rulesAndThresholds.ifImproves.part3")}
          </Typography>
          <ul className={classes.ul}>
            <li>{t("rulesAndThresholds.ifImproves.list1")}</li>
            <li>{t("rulesAndThresholds.ifImproves.list2")}</li>
            <li>{t("rulesAndThresholds.ifImproves.list3")}</li>
          </ul>
          <Typography component="p" className={classes.asterix}>
            {t("rulesAndThresholds.ifImproves.part4")}
          </Typography>
        </>
      }
    />
  );
}

export function ChangeTitle(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts", "common", "filterPredicates"]);

  return (
    <RulesAndThresholdsTableTitle
      title="Change"
      tooltipTitle={
        <>
          <Typography component="h3" className={classes.title}>
            Change compared to last crawl
          </Typography>
          <Typography component="p">
            {t("rulesAndThresholds.changeComparedToLastCrawlDescription")}
          </Typography>
          <ul className={classes.ul}>
            <li>{t("rulesAndThresholds.changeComparedBulletPoint1")}</li>
            <li>{t("rulesAndThresholds.changeComparedBulletPoint2")}</li>
            <li>{t("rulesAndThresholds.changeComparedBulletPoint3")}</li>
          </ul>
        </>
      }
    />
  );
}

export function SegmentTitle(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["alerts", "common", "filterPredicates"]);

  return (
    <RulesAndThresholdsTableTitle
      title={t("common:segment")}
      className={classes.report}
      tooltipTitle={
        <>
          <Typography className={classes.title} component="h3">
            {t("common:segment")}
          </Typography>
          <Typography component="p">
            {t("rulesAndThresholds.segmentDescription")}
          </Typography>
        </>
      }
    />
  );
}

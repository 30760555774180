import { Alert, EmptyState, Snackbar, useTranslation } from "@lumar/shared";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import errorLottie from "../../../_animations/error-animation.json";
import {
  useGetDashboardQuery,
  useGetDashboardChartsQuery,
  CustomChartType,
  CustomDashboardType,
  useCreateDashboardChartMutation,
} from "../../../graphql";
import { useGenericParams } from "../../../_common/routing/useGenericParams";
import { AccountOverviewGrid } from "./account-overview-grid/AccountOverviewGrid";
import { Collapse, Paper, makeStyles } from "@material-ui/core";
import { assert } from "../../../_common/assert";
import { TrendComparisonChart } from "./chart/TrendComparisonChart";
import { pick } from "lodash";
import { useMemo } from "react";
import { DEFAULT_REPORT_TEMPLATE_CODE } from "../utils/useCreateDefaultOrCloneDashboard";
import { useSnackbar } from "notistack";
import { useDashboardViews } from "../../components/DashboardViewsProvider";
import { useGetAccountModuleCodes } from "../../../_common/hooks/useGetAccountModuleCodes";

const useStyles = makeStyles({
  emptystate: {
    height: "calc(100vh - 130px)",
  },
});

const defaultChartArray = [
  {
    id: undefined,
    metric: undefined,
    metadata: undefined,
    customViews: {
      nodes: [],
    },
  },
];

export function AccountOverview({
  showChart,
}: {
  showChart: boolean;
}): JSX.Element {
  const classes = useStyles();

  const { accountId, dashboardId } = useGenericParams();
  assert(accountId);
  const { t } = useTranslation(["dashboards", "errors"]);

  const { enqueueSnackbar } = useSnackbar();
  const dashboardViews = useDashboardViews();
  const defaultModuleCodes = useGetAccountModuleCodes();

  const { data, loading, error } = useGetDashboardQuery({
    fetchPolicy: "no-cache",
    variables: { customDashboardId: dashboardId },
    skip: !Boolean(dashboardId),
  });

  const [createChart, { loading: chartCreationRunning }] =
    useCreateDashboardChartMutation({
      refetchQueries: ["GetDashboardCharts"],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("errors:chartUpdateError", { message: error.message })}
          />,
        );
      },
    });

  function createDefaultChart(values: {
    metadata?: Record<string, string>;
    metric?: string;
    customViews: string[];
  }): void {
    createChart({
      variables: {
        customDashboardId: dashboardId,
        metadata: values.metadata ?? { trendRange: "last_30_days" },
        metric: values.metric ?? DEFAULT_REPORT_TEMPLATE_CODE,
        customViews: values.customViews,
        type: CustomChartType.MonitorCrawlCompare,
      },
    });
  }

  const {
    data: chartsData,
    loading: chartsLoading,
    error: chartsError,
  } = useGetDashboardChartsQuery({
    fetchPolicy: "no-cache",
    variables: {
      customDashboardId: dashboardId,
      type: CustomChartType.MonitorCrawlCompare,
    },
    skip:
      !Boolean(dashboardId) ||
      loading ||
      data?.getCustomDashboard?.type !== CustomDashboardType.Monitor ||
      Boolean(dashboardViews.loading),
    onCompleted: async (data) => {
      if (
        !Boolean(data.getCustomDashboard?.customCharts?.nodes.length) &&
        dashboardViews.data?.length
      )
        await createDefaultChart({
          customViews: dashboardViews.data?.slice(0, 6).map((e) => e.id) ?? [],
        });
    },
  });
  const customCharts = useMemo(
    () =>
      chartsData?.getCustomDashboard?.customCharts?.nodes.map((chart) => ({
        ...pick(chart, ["id", "metric", "metadata"]),
        items: chart.customViews.nodes.map((e) => ({
          id: e.id,
          projectId: e.project.id,
          segmentId: e.segment?.id,
          projectName: e.project.name,
          segmentName: e.segment?.name,
          primaryDomain: e.project.primaryDomain,
          industryCode: e.project.industryCode,
        })),
      })),
    [chartsData?.getCustomDashboard?.customCharts?.nodes],
  );

  const somethingWrong =
    Boolean(error) || Boolean(chartsError) || Boolean(dashboardViews.error);

  const isNoDashboardFound =
    !loading && !somethingWrong && !data?.getCustomDashboard;

  if (data && data.getCustomDashboard?.type !== CustomDashboardType.Monitor)
    return <></>;
  if (!Boolean(defaultModuleCodes.length))
    return <Alert severity="error">{t("noAddonFound")}</Alert>;
  return (
    <>
      {Boolean(dashboardId) && isNoDashboardFound ? (
        <Alert severity="error">{t("noDashboardFound")}</Alert>
      ) : Boolean(somethingWrong) ? (
        <Paper>
          <EmptyState
            className={classes.emptystate}
            width="100%"
            height={500}
            title={t("dashboardLoadingError")}
            description={t("dashboardLoadingErrorDescription")}
            icon={
              <Lottie
                loop
                animationData={errorLottie}
                play
                style={{ width: "80px", margin: "auto" }}
              />
            }
            actions={[
              {
                type: "button",
                title: t("errorButton"),
                onClick: () => {
                  window.location.reload();
                },
              },
            ]}
          />
        </Paper>
      ) : (
        <>
          <Collapse in={showChart}>
            {(customCharts?.length ? customCharts : defaultChartArray).map(
              (chart, index) => (
                <TrendComparisonChart
                  key={index}
                  chart={chart}
                  isLoading={
                    chartsLoading ||
                    loading ||
                    !Boolean(data) ||
                    !showChart ||
                    chartCreationRunning
                  }
                  error={chartsError ?? error}
                />
              ),
            )}
          </Collapse>
          {data?.getCustomDashboard?.customTables?.nodes.map((table) => (
            <AccountOverviewGrid
              key={table.id}
              tableId={table.id}
              orderBy={table.orderBy}
              filters={table.filter ?? {}}
              columns={table.columns}
              loading={loading}
            />
          ))}
        </>
      )}
    </>
  );
}

import {
  Typography,
  Chip,
  useNumberFormatter,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ReportTemplateUnit } from "../../graphql";

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
  },
}));

interface ListItemTitleProps {
  reportName: string;
  previousUrlCount: number;
  currentUrlCount: number;
  isFailed: boolean;
  isHovering: boolean;
  isAdjustment: boolean;
  unit: ReportTemplateUnit;
}

export const ListItemTitle = ({
  reportName,
  previousUrlCount,
  currentUrlCount,
  isFailed,
  isHovering,
  isAdjustment,
  unit,
}: ListItemTitleProps): JSX.Element => {
  const formatNumber = useNumberFormatter();
  const classes = useStyles();
  const { t } = useTranslation(["notifications", "units"]);

  if (isAdjustment) {
    return (
      <Typography variant="subtitle1SemiBold">
        <span
          data-testid="notification-list-item-report-name"
          style={{
            textDecoration: isHovering ? "underline" : "none",
          }}
        >
          {`${reportName} `}
        </span>
        <Chip
          label={t("notifications:nottriggered")}
          style={{ fontSize: 16 }}
          color="lightgrey"
          className={classes.chip}
        />{" "}
        {t("notifications:adjustmentLabel1")}{" "}
        <Chip
          label={formatNumber(currentUrlCount)}
          color="primary"
          style={{ fontSize: 16 }}
        />{" "}
        {t(`units:${unit ?? ReportTemplateUnit.UrLs}`, {
          count: currentUrlCount,
        })}
      </Typography>
    );
  }

  const absoluteUrlChange = currentUrlCount - previousUrlCount;
  const percentageUrlChange = absoluteUrlChange / previousUrlCount;

  const changePhrase =
    absoluteUrlChange > 0
      ? t("notifications:up")
      : absoluteUrlChange === 0
        ? t("notifications:unchanged")
        : t("notifications:down");

  const chipLabel = `${formatNumber(absoluteUrlChange)} ${t(`units:${unit}`, {
    count: absoluteUrlChange,
  })} (${formatNumber(percentageUrlChange, { style: "percent" })})`;

  const comparisonPhrase = absoluteUrlChange
    ? `${t("notifications:from")} ${formatNumber(previousUrlCount)} ${t(
        "notifications:to",
      )}`
    : t("notifications:with");

  return (
    <Typography variant="subtitle1SemiBold">
      <span
        data-testid="notification-list-item-report-name"
        style={{
          textDecoration: isHovering ? "underline" : "none",
        }}
      >
        {reportName}
      </span>
      {` ${t("notifications:is")} ${changePhrase} `}
      {absoluteUrlChange === 0 ? undefined : (
        <Chip
          label={chipLabel}
          style={{ fontSize: 16 }}
          color={
            absoluteUrlChange === 0 ? "lightgrey" : isFailed ? "red" : "yellow"
          }
        />
      )}
      {` ${comparisonPhrase} `}
      <Chip
        label={formatNumber(currentUrlCount)}
        color="primary"
        style={{ fontSize: 16 }}
      />{" "}
      {t(`units:${unit ?? ReportTemplateUnit.UrLs}`, {
        count: currentUrlCount,
      })}
    </Typography>
  );
};

import {
  useApolloClient,
  getApiAccountId,
  useTranslation,
} from "@lumar/shared";
import {
  Box,
  CircularProgress,
  makeStyles,
  Tab,
  Tabs,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useNotificationTotalCountsQuery } from "../../graphql";
import { NotificationStatusFilter } from "../filters/constants";

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.grey[700],
  },
}));

interface Props {
  loading?: boolean;
  value: NotificationStatusFilter;
  onChange: (newStatus: NotificationStatusFilter) => void;
  mostRecentCrawlIds: number[];
}

export const NotificationsMenuTabs = ({
  loading: externalLoading = false,
  value,
  onChange,
  mostRecentCrawlIds,
}: Props): JSX.Element => {
  const { t } = useTranslation(["notifications", "common"]);
  const classes = useStyles();

  const { accountId } = useParams<{ accountId: string }>();
  const { cache } = useApolloClient();

  const { data, loading: queryLoading } = useNotificationTotalCountsQuery({
    variables: { accountId, crawlIds: mostRecentCrawlIds },
    skip: externalLoading,
  });

  const loading = externalLoading || queryLoading;
  // Note: Because apollo is managing the monitorNotifications cache for pagination, on tab change we have to force clear the cache
  // This is neccessary only when status change happened on notifications (so maybe optimization is possible)
  const clearCache = (): void => {
    cache.modify({
      id: cache.identify({
        __typename: "Account",
        id: getApiAccountId(accountId),
      }),
      fields: {
        monitorNotifications: (_, details) => details.DELETE,
      },
    });
  };

  return (
    <Tabs
      value={value}
      onChange={(_, newStatus) => {
        onChange(newStatus);
        clearCache();
      }}
      aria-label="Notification tabs"
      style={{ borderBottom: 0 }}
      data-pendo={`notifications-page-tabs`}
    >
      <Tab
        label={
          <TabLabel
            loading={loading}
            loadingText={t("common:notifications")}
            text={t("notificationsWithCount", {
              count: data?.getAccount?.unreadNotifications.totalCount,
            })}
          />
        }
        value={NotificationStatusFilter.Unread}
        classes={{ textColorPrimary: classes.primary }}
        data-pendo="all-notifications-tab"
      />
      <Tab
        label={
          <TabLabel
            loading={loading}
            loadingText={t("adjustments")}
            text={t("adjustmentsWithCount", {
              count: data?.getAccount?.adjustmentNotifications.totalCount,
            })}
          />
        }
        value={NotificationStatusFilter.Adjustments}
        data-testid={`${NotificationStatusFilter.Adjustments}-tab`}
        classes={{ textColorPrimary: classes.primary }}
        data-pendo="adjustments-tab"
      />
      <Tab
        label={
          <TabLabel
            loading={loading}
            loadingText={t("workingonit")}
            text={t("workingOnItWithCount", {
              count: data?.getAccount?.workingOnItNotifications.totalCount,
            })}
          />
        }
        value={NotificationStatusFilter.WorkingOnIt}
        classes={{ textColorPrimary: classes.primary }}
        data-pendo="working-on-it-tab"
      />
      <Tab
        label={t("read")}
        value={NotificationStatusFilter.Archived}
        classes={{ textColorPrimary: classes.primary }}
        data-pendo="archived-tab"
      />
    </Tabs>
  );
};

interface TabLabelProps {
  loading: boolean;
  loadingText: string;
  text: string;
}

const TabLabel = ({
  loading,
  loadingText,
  text,
}: TabLabelProps): JSX.Element =>
  loading ? (
    <Box display="flex" alignItems="center">
      <span>{loadingText}</span>
      <CircularProgress style={{ height: 16, width: 16, marginLeft: 8 }} />
    </Box>
  ) : (
    <>{text}</>
  );

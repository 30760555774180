import { ModuleCode, TableReportStatsDataRowsQuery } from "../../../../graphql";
import {
  TrendSDKReportTrendItem,
  ReportTrendElement,
} from "../../../../_common/types";
import { PROJECT_NAME_COLUMN_KEY } from "../account-overview-grid/AccountOverviewGrid";
import { DataGridReportValue } from "../types";
import { DataGridTrendValue } from "../types";
import { ReportOption } from "../../../../_common/utils/constants";
import { get } from "lodash";
import { isViewReportTrendsTableRow } from "./isReportTrendsTableRow";

export interface RowData {
  id: number;
  projectId: string;
  segmentId?: string;
  [PROJECT_NAME_COLUMN_KEY]: string;
  segmentName?: string;
  lastFinishedCrawlId: string | null;
  primaryDomain: string;
  reports: RowDataReports | null;
  segmentLastGeneratedAt?: string | null;
  moduleCode: ModuleCode;
}
type RowDataReports = Record<string, DataGridReportValue>;

function transformTrendReportToDataGridTrendValue(
  trendReport: ReportTrendElement,
): DataGridTrendValue {
  return {
    date: new Date(trendReport.createdAt),
    count: trendReport.totalRows || 0,
  };
}

function findSignForReportTemplate(
  reportTemplates: ReportOption[],
  reportTemplateCode: string,
): number | undefined | null {
  return reportTemplates?.find(
    (reportTemplate) => reportTemplate.code === reportTemplateCode,
  )?.totalSign;
}

export function isValidTrendSDKReportTrendItem(
  value: unknown,
): value is TrendSDKReportTrendItem {
  return (
    typeof value === "object" &&
    value !== null &&
    value.hasOwnProperty("crawlId") &&
    value.hasOwnProperty("createdAt") &&
    Boolean(get(value, "createdAt")) &&
    value.hasOwnProperty("basic")
  );
}

export function mapAccountOverviewDataToDataGridRows(
  data: TableReportStatsDataRowsQuery,
  reportTemplates: ReportOption[],
): RowData[] {
  const views = data.getCustomTable?.table.nodes || [];
  return views.map((view, viewIndex) => {
    const viewDetails = {
      id: viewIndex,
      projectId: view.customDashboardView.project?.id,
      segmentId: view.customDashboardView.segment?.id,
      [PROJECT_NAME_COLUMN_KEY]: view.customDashboardView.projectName ?? "",
      segmentLastGeneratedAt: view.customDashboardView.segment?.lastGeneratedAt,
      segmentName: view.customDashboardView.segmentName ?? undefined,
      lastFinishedCrawlId:
        view.customDashboardView.project?.lastFinishedCrawl?.id ?? null,
      primaryDomain: view.customDashboardView.projectPrimaryDomain ?? "",
      moduleCode:
        view.customDashboardView.project?.moduleCode ?? ModuleCode.Seo,
    };
    const reports = isViewReportTrendsTableRow(view.row)
      ? view.row.columns
      : [];

    const flattenedReports = reports?.reduce((acc, report) => {
      const reportsTrend = report.points
        .filter(isValidTrendSDKReportTrendItem)
        .map((e) => ({
          crawlId: e.crawlId.toString(),
          createdAt: e.createdAt ?? "",
          totalRows: e.basic ?? 0,
        }));
      return {
        ...acc,
        [report.reportTemplateCode]: {
          trends: reportsTrend
            ? reportsTrend.map(transformTrendReportToDataGridTrendValue)
            : [],
          totalSign: Math.sign(
            report.computed.last?.totalWeight ??
              findSignForReportTemplate(
                reportTemplates,
                report.reportTemplateCode,
              ) ??
              0,
          ),
          total: report.computed.last?.basic,
        },
      };
    }, {});
    const ret: RowData = {
      ...viewDetails,
      reports: flattenedReports ?? null,
    };
    return ret;
  });
}

/* eslint-disable fp/no-loops */
import {
  ApolloError,
  Button,
  EmptyState,
  Trans,
  useTranslation,
} from "@lumar/shared";
import {
  GetAlertWithTestsQuery,
  useCopyAlertTestsToProjectMutation,
  useCreateEmailAlertMutation,
  useCreateMsTeamsWebhookMutation,
  useCreateSlackWebhookMutation,
} from "../../graphql";
import { ProjectOption } from "../../_common/utils/constants";
import { useEffect, useState } from "react";
import { CopyIcon } from "./icons/CopyIcon";
import { FinishedIcon } from "./icons/FinishedIcon";
import { WarningIcon } from "./icons/WarningIcon";
import {
  LinearProgress,
  List,
  ListItem,
  Tooltip,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { ProjectListItem } from "./components/ProjectListItem";
import { getErrorMessage } from "../../_common/utils/getErrorMessage";
import { assert } from "../../_common/assert";

export function CopyAlert({
  fromProject,
  toProjects,
  onFinished,
}: {
  fromProject: GetAlertWithTestsQuery["alert"];
  toProjects: ProjectOption[];
  onFinished: (copied?: boolean) => void;
}): JSX.Element {
  assert(fromProject);
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation("alerts");

  const [finished, setFinished] = useState(false);
  const [working, setWorking] = useState(false);
  const [failedProjects, setFailedProjects] = useState<
    { project: ProjectOption; errorMessage: string }[]
  >([]);
  const [overflowProjects, setOverflowProjects] = useState<ProjectOption[]>([]);

  const [projectsToWorkOn, setProjectsToWorkOn] =
    useState<ProjectOption[]>(toProjects);

  const [copyAlertTestsToProject] = useCopyAlertTestsToProjectMutation();
  const [createMsTeamsWebhook] = useCreateMsTeamsWebhookMutation();
  const [createSlackWebhook] = useCreateSlackWebhookMutation();
  const [createEmailAlert] = useCreateEmailAlertMutation();

  const actuallyCopiedAlertsNum =
    toProjects.length - (failedProjects.length + overflowProjects.length);

  useEffect(() => {
    const copyProject = async (): Promise<void> => {
      setWorking(true);
      const project = projectsToWorkOn[0];
      try {
        await copyAlertTestsToProject({
          variables: {
            fromId: fromProject.id,
            toId: project.id,
          },
          refetchQueries: ["GetTestsTotalCount"],
        });
        for (const emailAlert of fromProject.emailAlerts?.nodes ?? []) {
          try {
            await createEmailAlert({
              variables: {
                email: emailAlert.email,
                projectId: project.id,
              },
            });
          } catch (error) {
            const errorCode =
              error instanceof ApolloError
                ? error?.graphQLErrors?.[0]?.extensions?.code
                : "";
            if (errorCode !== "ALERT_ALREADY_EXISTS") throw error;
          }
        }
        for (const slackWebhook of fromProject.slackWebhooks?.nodes ?? []) {
          try {
            await createSlackWebhook({
              variables: {
                url: slackWebhook.url,
                projectId: project.id,
              },
            });
          } catch (error) {
            const errorCode =
              error instanceof ApolloError
                ? error?.graphQLErrors?.[0]?.extensions?.code
                : "";
            if (errorCode !== "CONFLICT") throw error;
          }
        }
        for (const webhook of fromProject.webhooks?.nodes ?? []) {
          try {
            await createMsTeamsWebhook({
              variables: {
                url: webhook.url,
                projectId: project.id,
              },
            });
          } catch (error) {
            const errorCode =
              error instanceof ApolloError
                ? error?.graphQLErrors?.[0]?.extensions?.code
                : "";
            if (errorCode !== "CONFLICT") throw error;
          }
        }
      } catch (error) {
        const errorCode =
          error instanceof ApolloError
            ? error?.graphQLErrors?.[0]?.extensions?.code
            : "";

        if (errorCode === "INCORRECT_NUMBER_OF_TESTS") {
          setOverflowProjects([...overflowProjects, project]);
        } else {
          const message =
            errorCode === "ACCOUNT_MAX_PROJECT_TESTS_LIMIT_EXCEEDED"
              ? t("alerts:alertCreationFailedWithLimit", {
                  count:
                    error instanceof ApolloError
                      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (error?.graphQLErrors?.[0]?.extensions?.max as any)
                      : 1000,
                })
              : getErrorMessage(error);
          setFailedProjects([
            ...failedProjects,
            { project, errorMessage: message },
          ]);
        }
      }
      setProjectsToWorkOn(projectsToWorkOn.slice(1));
      setWorking(false);
    };

    if (!working && projectsToWorkOn.length) {
      copyProject();
    } else if (!projectsToWorkOn.length) setFinished(true);
  }, [
    working,
    projectsToWorkOn,
    copyAlertTestsToProject,
    fromProject.id,
    failedProjects,
    overflowProjects,
    t,
    fromProject.emailAlerts?.nodes,
    fromProject.slackWebhooks?.nodes,
    fromProject.webhooks?.nodes,
    createEmailAlert,
    createSlackWebhook,
    createMsTeamsWebhook,
  ]);

  const hasErrors =
    Boolean(failedProjects.length) || Boolean(overflowProjects.length);

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexFlow: "column",
          minHeight: 300,
          paddingTop: 60,
          paddingBottom: 65,
        }}
      >
        {!finished ? (
          <>
            <EmptyState
              icon={<CopyIcon />}
              title={t("copy.copyAlert", { count: toProjects.length })}
              description={t("copy.copyAlertDescription")}
            />
            <LinearProgress
              key="copy"
              variant="determinate"
              value={
                ((toProjects.length - projectsToWorkOn.length) /
                  toProjects.length) *
                100
              }
            />
          </>
        ) : (
          <>
            {hasErrors ? (
              <>
                <EmptyState
                  icon={<WarningIcon />}
                  title={t("copy.copied", { count: actuallyCopiedAlertsNum })}
                  description={
                    <Trans
                      ns="alerts"
                      i18nKey={"copy.warningDescription"}
                      components={{ bold: <b /> }}
                      values={{
                        count: failedProjects.length + overflowProjects.length,
                      }}
                    />
                  }
                />
                <LinearProgress
                  key="finished"
                  variant="determinate"
                  classes={{
                    bar: classes.warningColor,
                    root: classes.progress,
                  }}
                  value={100}
                />
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    marginTop: theme.spacing(2),
                    alignItems: "center",
                    minHeight: 100,
                    height: "fit-content",
                    maxHeight: "40vh",

                    flexShrink: 1,
                  }}
                >
                  <List className={classes.list}>
                    {Boolean(overflowProjects.length) && (
                      <ListItem disabled>{t("copy.exceeded")}</ListItem>
                    )}
                    {overflowProjects.map((e) => {
                      return (
                        <ProjectListItem
                          key={e.id}
                          project={{ ...e, testsTotalCount: 0 }}
                          selectable={false}
                          variant="simple"
                          classes={{ item: classes.listItem }}
                        />
                      );
                    })}
                    {Boolean(failedProjects.length) && (
                      <ListItem disabled>{t("copy.error")}</ListItem>
                    )}
                    {failedProjects.map((e) => {
                      return (
                        <Tooltip
                          title={e.errorMessage}
                          key={e.project.id}
                          arrow={false}
                        >
                          <span>
                            <ProjectListItem
                              project={{ ...e.project, testsTotalCount: 0 }}
                              selectable={false}
                              variant="simple"
                              classes={{ item: classes.listItem }}
                            />
                          </span>
                        </Tooltip>
                      );
                    })}
                  </List>
                </div>
              </>
            ) : (
              <>
                <EmptyState
                  icon={<FinishedIcon />}
                  title={t("copy.finished", { count: toProjects.length })}
                  description={t("copy.finishedDescription", {
                    count: toProjects.length,
                  })}
                />
                <LinearProgress
                  key="finished"
                  variant="determinate"
                  classes={{
                    bar: classes.finishedColor,
                    root: classes.progress,
                  }}
                  value={100}
                />
              </>
            )}
            <div className={classes.buttonBase}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={() => onFinished(true)}
              >
                {t("copy.button")}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  progress: {
    borderRadius: 6,
  },
  finishedColor: {
    backgroundColor: theme.palette.green[500],
  },
  warningColor: {
    backgroundColor: theme.palette.yellow[400],
  },
  buttonBase: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 60,
    backdropFilter: "blur(8px)",
    background: `${theme.palette.grey[300]}99`,
    display: "flex",
    alignContent: "right",
  },
  button: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: theme.spacing(3),
  },
  list: {
    borderRadius: 6,
    border: "1px solid #EBEFF3",
    padding: theme.spacing(2, 2, 1, 2),
    //    minHeight: 281,
    flex: 1,
    flexShrink: 1,
    overflowY: "auto",
  },
  listItem: {
    backgroundColor: theme.palette.grey[200],
    marginBottom: `${theme.spacing(1)}px!important`,
  },
}));

import {
  Checkbox,
  FaviconIcon,
  Typography,
  getRawProjectId,
  useTranslation,
} from "@lumar/shared";
import {
  CircularProgress,
  makeStyles,
  TextField,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { differenceBy } from "lodash";
import { ProjectOption } from "../utils/constants";
import { useDebounce } from "../hooks/useDebounce";
import { ConditionallTooltip } from "./ConditionalTooltip";

import clsx from "clsx";
import { useLoadProjectList } from "./utils/useLoadProjectList";
import { LeftBottomPopper } from "./CustomPopper/LeftBottomPopper";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:not(.Mui-focused) .MuiAutocomplete-inputRoot": {
      "&:hover fieldset": {
        borderColor: `${theme.palette.ultraviolet[400]} !important`,
      },
      backgroundColor: theme.palette.ultraviolet[100],
      color: theme.palette.grey[700],
      fontWeight: 500,
      "& fieldset": {
        borderColor: theme.palette.ultraviolet[400],
      },
    },
  },
  list: {
    "& li[aria-selected='true'] + li[aria-selected='false']": {
      display: "block",
      marginTop: theme.spacing(1.625),
      "&:before": {
        content: "''",
        height: 1,
        background: theme.palette.grey[300],
        display: "block",
        position: "relative",
        top: -7,
      },
    },
  },
  autocompleteOption: {
    padding: 0,
    "&[aria-disabled='true']": {
      pointerEvents: "all!important",
      cursor: "default",
    },
    "&[aria-selected='true']": {
      color: "inherit",
      backgroundColor: "inherit",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
  primaryDomain: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    fontWeight: 400,
  },
  projectName: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
  },
  favicon: {
    display: "block",
    margin: theme.spacing(0, 2),
  },
  disabled: {
    pointerEvents: "none",
    cursor: "pointer",
  },
  tooltip: {
    maxWidth: 150,
    fontSize: theme.typography.pxToRem(13),
  },
  input: {
    color: theme.palette.grey[700],
    "& input::placeholder": {
      color: theme.palette.grey[700],
      opacity: 1,
    },
    "&.Mui-disabled": {
      color: theme.palette.grey[400],
    },
  },
  popper: {
    minWidth: 360,
  },
  inputRoot: {
    flexWrap: "nowrap",
  },
}));

interface Props {
  value: ProjectOption[];
  noSelectionText?: string;
  onChange: (newProjects: ProjectOption[]) => void;
  disabled?: boolean;
  maxSelection?: number;
  minSelection?: number;
  ["data-pendo"]?: string;
  disableClearable?: boolean;
  disableBackground?: boolean;
  maxWidth?: number;
  className?: string;
}

export function ProjectsCombobox({
  value,
  disabled,
  onChange,
  noSelectionText,
  maxSelection,
  minSelection,
  disableClearable,
  disableBackground,
  maxWidth,
  ...props
}: Props): JSX.Element {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [scrollTo, setScrollTo] = useState<string | undefined>();

  const classes = useStyles();

  const theme = useTheme();

  const { t } = useTranslation(["common"]);

  const debouncedProjectInputValue = useDebounce(inputValue);

  const { data: currentList, loading } = useLoadProjectList(
    value,
    debouncedProjectInputValue,
  );

  useEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(`project-list-id-${scrollTo}`);
      const parent = element?.parentElement?.parentElement;
      parent?.scrollTo({ top: 0 });
    }
  }, [scrollTo]);

  const hasReachedMaxNumberOfProjects =
    Boolean(maxSelection) && value.length === maxSelection;
  const hasReachedMinNumberOfProjects = minSelection
    ? value.length <= minSelection
    : false;

  return (
    <Autocomplete
      data-pendo={props["data-pendo"]}
      data-testid="project-combobox"
      disabled={disabled}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={(e, r) => {
        if (isOpen && r === "toggleInput" && e.type === "mousedown") return;
        setIsOpen(false);
      }}
      disableCloseOnSelect
      openOnFocus={true}
      classes={{
        root:
          Boolean(value.length) && !disableBackground
            ? classes.root
            : undefined,
        option: classes.autocompleteOption,
        listbox: classes.list,
        popper: classes.popper,
        inputRoot: classes.inputRoot,
      }}
      multiple
      disableClearable={disableClearable || Boolean(minSelection) || isOpen}
      value={!loading ? value : []}
      onChange={(_, selection) => {
        const newElement = differenceBy(selection, value, "id");
        if (newElement?.length) {
          setScrollTo(getRawProjectId(newElement[0].id));
        } else setScrollTo(undefined);
        onChange(selection ?? []);
      }}
      noOptionsText={loading ? t("loading") : t("noOptions")}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (!event) return;
        if (event.type === "change") setInputValue(newInputValue);
      }}
      getOptionDisabled={(option) => {
        const isSelected = value.some(
          (v) => getRawProjectId(v.id) === getRawProjectId(option.id),
        );
        return (
          (hasReachedMaxNumberOfProjects && !isSelected) ||
          (hasReachedMinNumberOfProjects && isSelected)
        );
      }}
      onBlur={() => {
        if (inputValue !== "") setInputValue("");
      }}
      PopperComponent={LeftBottomPopper}
      options={currentList}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => {
        return getRawProjectId(option.id) === getRawProjectId(value.id);
      }}
      renderOption={(option, state) => {
        const disabled =
          (hasReachedMaxNumberOfProjects && !state.selected) ||
          (hasReachedMinNumberOfProjects && state.selected);

        return (
          <ConditionallTooltip
            show={disabled}
            classes={{ tooltip: classes.tooltip }}
            arrow={false}
            title={
              (hasReachedMaxNumberOfProjects
                ? t("maxPselectTooltip", { count: maxSelection })
                : t("minPselectTooltip", { count: minSelection })) as string
            }
          >
            <div
              id={`project-list-id-${getRawProjectId(option.id)}`}
              data-testid={`project-list-item-${getRawProjectId(option.id)}`}
              style={{
                display: "flex",
                cursor: disabled ? "default" : "pointer",
                width: "100%",
              }}
              onClick={(e) => {
                if (disabled) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              <div
                className={disabled ? classes.disabled : ""}
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "100%",
                  padding: theme.spacing(0.75, 1, 0.75, 1),
                  width: "100%",
                }}
              >
                <Checkbox checked={state.selected} />
                <FaviconIcon
                  className={classes.favicon}
                  url={option.primaryDomain}
                  width={24}
                  height={24}
                />
                <div
                  style={{
                    minWidth: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    component="p"
                    variant="subtitle3Medium"
                    noWrap
                    className={classes.projectName}
                    data-testid="project-combobox-project-name"
                  >
                    {option.name}
                  </Typography>
                  <Typography className={classes.primaryDomain} noWrap>
                    {option.primaryDomain}
                  </Typography>
                </div>
              </div>
            </div>
          </ConditionallTooltip>
        );
      }}
      fullWidth
      style={{ maxWidth: maxWidth ?? 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            className: clsx(classes.input, params.InputProps.className),
            endAdornment:
              loading && isOpen ? (
                <>
                  <CircularProgress size={20} style={{ marginRight: 4 }} />
                  {params.InputProps.endAdornment}
                </>
              ) : (
                params.InputProps.endAdornment
              ),
          }}
          placeholder={
            isOpen
              ? t("typeto")
              : !value.length
                ? noSelectionText ?? ""
                : undefined
          }
        />
      )}
      renderTags={(selectedProjects) => {
        return !isOpen ? (
          <span>
            {t("projectsselected", { count: selectedProjects.length })}
          </span>
        ) : null;
      }}
    />
  );
}

import {
  BellOutlined,
  Checkbox,
  Chip,
  FaviconIcon,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  ListItem,
  ListItemText,
  Tooltip,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Highlighter from "react-highlight-words";
import { BasicProject } from "../utils/basicProject";
import clsx from "clsx";

const FAVICON_WIDTH = 24;

interface ProjectListItemProps {
  project: BasicProject;
  selectable?: boolean;
  selected?: boolean;
  highlightedText?: string;
  variant?: "simple" | "complete";
  isLastItem?: boolean;
  onListItemClick?: (project: BasicProject, status: boolean) => void;
  classes?: {
    item?: string;
    text?: string;
    chip?: string;
  };
}

export function ProjectListItem({
  project,
  selectable,
  selected,
  highlightedText,
  variant = "complete",
  isLastItem,
  onListItemClick,
  classes: outerClasses,
}: ProjectListItemProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(["common", "alerts"]);

  return (
    <ListItem
      button
      alignItems="flex-start"
      key={project.id}
      data-pendo="alert-copy-to-menuitem"
      data-testid={`alert-copy-to-menuitem-${project.id}`}
      disableGutters
      selected={selected}
      onClick={() => onListItemClick?.(project, !selected)}
      style={{
        // Note: this ensures the last item is not hidden by the floating button container
        marginBottom: isLastItem ? 60 : 0,
      }}
      classes={{
        button: clsx(
          classes.button,
          selectable ? undefined : classes.notSelectable,
        ),
        selected: classes.selected,
      }}
      className={outerClasses?.item}
    >
      {selectable && <Checkbox checked={selected} className={classes.check} />}
      <ListItemText
        className={outerClasses?.text}
        primary={
          <div className={classes.listItemContent}>
            <div>
              <FaviconIcon
                url={project.primaryDomain}
                width={FAVICON_WIDTH}
                height={FAVICON_WIDTH}
              />
            </div>
            <div className={classes.nameAndDomainWrapper}>
              <Typography
                variant="subtitle3Medium"
                className={classes.listItemName}
                noWrap
              >
                <Highlighter
                  textToHighlight={project.name}
                  searchWords={[highlightedText ?? ""]}
                  highlightStyle={{
                    backgroundColor: theme.palette.ultraviolet[100],
                  }}
                  autoEscape={true}
                />
              </Typography>
              <Typography className={classes.primaryDomain} noWrap>
                <Highlighter
                  textToHighlight={project.primaryDomain}
                  searchWords={[highlightedText ?? ""]}
                  highlightStyle={{
                    backgroundColor: theme.palette.ultraviolet[100],
                  }}
                  autoEscape={true}
                />
              </Typography>
            </div>
            <div className={classes.chipContainer}>
              {project.testsTotalCount > 0 && variant === "complete" ? (
                <Tooltip
                  title={t("alerts:ruleOverrideExplanation") as string}
                  arrow={false}
                  placement="top"
                >
                  <Chip
                    label={t("alerts:alertConfigured")}
                    color="lightgrey"
                    className={clsx(classes.alertChip, outerClasses?.chip)}
                    avatar={<BellOutlined />}
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>
        }
      />
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  listItemContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
  nameAndDomainWrapper: {
    marginLeft: 15,
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    paddingRight: theme.spacing(1),
  },
  listItemName: {
    lineHeight: theme.typography.pxToRem(17),
  },
  primaryDomain: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
  },
  button: {
    borderRadius: 6,
    padding: theme.spacing(0, 1),
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  selected: {
    backgroundColor: `${theme.palette.ultraviolet[100]}!important`,
  },
  alertChip: {
    fontWeight: 400,
    "&:hover": {
      cursor: "pointer",
    },
    "& .MuiChip-avatar": {
      height: 16,
      width: 16,
      color: theme.palette.grey[400],
      marginRight: -3,
    },
  },
  chipContainer: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    height: 35,
  },
  check: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: theme.spacing(1),
  },
  notSelectable: {
    pointerEvents: "none",
  },
}));

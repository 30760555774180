import { useMemo } from "react";
import {
  GetAlertWithTestsQuery,
  ReportTemplateUnit,
  TestAutoThresholdAcceptance,
} from "../../../graphql";
import { RulesAndThresholdsFormState } from "../../_common/utils/types";
import { canReportTemplateBeUsedWithSegments } from "../../../_common/hooks/useReportTemplateAccumulator";

export function useInitialAlertSettings(
  data?: GetAlertWithTestsQuery,
): RulesAndThresholdsFormState {
  const alphabeticallySortedRules = useMemo(() => {
    const rules = data?.alert?.tests.nodes ?? [];

    // Note: it's ok to use .sort() here because we are spreading into a new array first
    // eslint-disable-next-line fp/no-mutating-methods
    return [...rules]
      .sort((a, b) =>
        a.reportTemplate.name.localeCompare(b.reportTemplate.name),
      )
      .map(
        ({
          id,
          severity,
          thresholdPredicate,
          absoluteThreshold,
          reportTemplate,
          testResultList,
          automaticThresholdAcceptanceWhenTestResultIsBetter,
          automaticThresholdAcceptanceWhenTestResultIsWorse,
          segment,
        }) => ({
          id,
          severity,
          threshold: thresholdPredicate,
          urls: absoluteThreshold,
          report: {
            ...reportTemplate,
            totalSign:
              testResultList.nodes[0]?.reportStat?.totalWeight ??
              reportTemplate.totalSign,
            unit: reportTemplate.metadata?.unit ?? ReportTemplateUnit.UrLs,
            useableWithSegments: canReportTemplateBeUsedWithSegments(
              reportTemplate.datasourceCodeEnum,
            ),
          },
          thresholdAcceptanceWhenWorse:
            automaticThresholdAcceptanceWhenTestResultIsWorse ??
            TestAutoThresholdAcceptance.None,
          thresholdAcceptanceWhenBetter:
            automaticThresholdAcceptanceWhenTestResultIsBetter ??
            TestAutoThresholdAcceptance.None,
          segment,
        }),
      );
  }, [data?.alert?.tests.nodes]);

  return {
    rulesAndThresholds: alphabeticallySortedRules,
    emailAlerts:
      data?.alert?.emailAlerts?.nodes.map(({ email }) => email) ?? [],
    slackWebhooks:
      data?.alert?.slackWebhooks?.nodes.map(({ url }) => url) ?? [],
    microsoftTeamsWebhooks:
      data?.alert?.webhooks?.nodes.map(({ url }) => url) ?? [],
  };
}

/* eslint-disable react/display-name */
import {
  Chip,
  FaviconIcon,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Tooltip, useTheme } from "@material-ui/core";
import Highlighter from "react-highlight-words";
import clsx from "clsx";
import { View } from "../types";
import React, { useEffect, useRef, useState } from "react";
import { GetViewListFilter } from "../board/utils/filterViewList";

const FAVICON_WIDTH = 24;

const useStyles = makeStyles((theme) => ({
  itemContent: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flex: 1,
    overflow: "hidden",
  },
  nameAndDomainWrapper: {
    paddingLeft: 15,
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    paddingRight: theme.spacing(2.5),
    flex: 1,
  },
  itemName: {
    lineHeight: theme.typography.pxToRem(17),
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  primaryDomain: {
    color: theme.palette.grey[500],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  crawlPart: {
    paddingRight: theme.spacing(1.5),
  },
  segmentPart: {
    width: 161 + theme.spacing(1.5),
    minWidth: 161 + theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export interface RenderViewProps {
  classes?: {
    root?: string;
    favicon?: string;
    project?: string;
    crawl?: string;
    segment?: string;
  };
  searchTerms?: GetViewListFilter;
  view: View;
}

export const RenderView = React.forwardRef(
  (
    { classes: externalClasses, searchTerms, view }: RenderViewProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation(["board"]);
    const [isSegmentNameTruncated, setIsSegmentNameTruncated] = useState(false);
    const segmentNameRef = useRef<HTMLDivElement | null>(null);

    const {
      account: {
        subscription: { segmentationAvailable },
      },
    } = useSession();

    useEffect(() => {
      setIsSegmentNameTruncated(
        Boolean(
          segmentNameRef.current &&
            segmentNameRef.current.getElementsByTagName("span")[0]
              ?.offsetWidth <
              segmentNameRef.current.getElementsByTagName("span")[0]
                ?.scrollWidth,
        ),
      );
    }, [setIsSegmentNameTruncated]);

    return (
      <div
        className={clsx(classes.itemContent, externalClasses?.root)}
        ref={ref}
      >
        <div>
          <FaviconIcon
            className={externalClasses?.favicon}
            url={view.primaryDomain ?? ""}
            width={FAVICON_WIDTH}
            height={FAVICON_WIDTH}
          />
        </div>
        <div
          className={clsx(
            classes.nameAndDomainWrapper,
            externalClasses?.project,
          )}
        >
          <Typography
            variant="subtitle3Medium"
            className={classes.itemName}
            noWrap
          >
            <Highlighter
              textToHighlight={view?.projectName}
              searchWords={[searchTerms?.projectNameSearchTherm ?? ""]}
              highlightStyle={{
                backgroundColor: theme.palette.ultraviolet[100],
              }}
              autoEscape={true}
            />
          </Typography>
          <Typography className={classes.primaryDomain} noWrap>
            <Highlighter
              textToHighlight={view.primaryDomain ?? ""}
              searchWords={[searchTerms?.projectNameSearchTherm ?? ""]}
              highlightStyle={{
                backgroundColor: theme.palette.ultraviolet[100],
              }}
              autoEscape={true}
            />
          </Typography>
        </div>
        <div className={classes.crawlPart}>
          {view.scheduleFrequency ? (
            <Tooltip
              title={
                t("crawlFrequency", {
                  type: view.scheduleFrequency.name,
                }) as string
              }
            >
              <Chip
                style={{ width: 32 }}
                label={
                  <Typography
                    variant="captionMedium"
                    style={{ color: theme.palette.grey[600], width: 12 }}
                  >
                    {view.scheduleFrequency.name.charAt(0)}
                  </Typography>
                }
                color="white"
              />
            </Tooltip>
          ) : (
            <div style={{ width: 32 }} />
          )}
        </div>
        {segmentationAvailable && (
          <div className={classes.segmentPart}>
            <Tooltip
              arrow={false}
              title={
                isSegmentNameTruncated
                  ? view.segmentName ?? (t("noSegments") as string)
                  : ""
              }
            >
              <Chip
                style={{ maxWidth: 161 }}
                ref={segmentNameRef}
                label={
                  <Typography
                    variant="captionMedium"
                    style={{
                      color: theme.palette.grey[600],
                    }}
                  >
                    {view.segmentName ?? t("noSegments")}
                  </Typography>
                }
                color="white"
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  },
);

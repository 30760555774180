import { isReportTrendItemArray } from "../../_common/utils/isReportTrendElement";

interface TrendInfo {
  seriesData: [number, number][];
  currentUrlCount: number;
  previousUrlCount: number;
  lastCrawledAt: Date | null;
  crawlId: string | null;
}

export function extractChartDataFromTrends(trends?: unknown[]): TrendInfo {
  if (trends && trends.length && isReportTrendItemArray(trends)) {
    const seriesData: [number, number][] = trends.map((trend) => [
      new Date(trend.createdAt ?? "").getTime(),
      trend.basic ?? 0,
    ]);

    const [mostRecentTimeStamp, mostRecentUrlCount] = seriesData[0];
    const id = trends[0].crawlId;
    const previousUrlCount = seriesData.length > 1 ? seriesData[1][1] : 0;

    return {
      seriesData,
      previousUrlCount,
      currentUrlCount: mostRecentUrlCount,
      lastCrawledAt: new Date(mostRecentTimeStamp),
      crawlId: id?.toString() ?? "",
    };
  }

  return {
    seriesData: [],
    currentUrlCount: 0,
    previousUrlCount: 0,
    lastCrawledAt: null,
    crawlId: null,
  };
}

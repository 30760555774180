import { getRawProjectId } from "@lumar/shared";
import queryString from "query-string";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ModuleCode, Severity } from "../../graphql";
import { useURLSearchParams } from "../../_common/routing/useURLSearchParams";
import { ProjectOption, ReportOption } from "../../_common/utils/constants";

const PROJECT_PARAM = "projectIds";
const REPORT_PARAM = "reportCodes";
const SEVERITY_PARAM = "severity";

interface NotificationFiltersPayload {
  severity: Severity | null;
  projects: ProjectOption[];
  reports: ReportOption[];
  setSeverity: (severity: Severity | null) => void;
  setProjects: (newProjects: ProjectOption[]) => void;
  setReports: (newReports: ReportOption[]) => void;
  resetFilters: () => void;
}

export function useNotificationFilters(): NotificationFiltersPayload {
  const searchParams = useURLSearchParams();
  const history = useHistory();

  const [severity, setSeverity] = useState<Severity | null>(
    getInitialSeverityFromUrl(searchParams.toString()),
  );
  const [projects, setProjects] = useState<ProjectOption[]>(
    getInitialProjectsFromUrl(searchParams.toString()),
  );
  const [reports, setReports] = useState<ReportOption[]>(
    getInitialReportsFromUrl(searchParams.toString()),
  );

  const updateProjects = (newProjects: ProjectOption[]): void => {
    if (!newProjects.length) {
      searchParams.delete(PROJECT_PARAM);
    } else {
      const projectIds = newProjects.map((p) => getRawProjectId(p.id));
      const queryString = projectIds.join(",");
      searchParams.set(PROJECT_PARAM, queryString);
    }
    history.replace({ search: searchParams.toString() });
    setProjects(getInitialProjectsFromUrl(searchParams.toString()));
  };

  const updateReports = (newReports: ReportOption[]): void => {
    setReports(newReports);
    if (!newReports.length) {
      searchParams.delete(REPORT_PARAM);
    } else {
      const reportCodes = newReports.map((r) => r.code);
      const queryString = reportCodes.join(",");
      searchParams.set(REPORT_PARAM, queryString);
    }
    history.replace({ search: searchParams.toString() });
  };

  const updateSeverity = (newValue: Severity | null): void => {
    setSeverity(newValue);
    if (!newValue) {
      searchParams.delete(SEVERITY_PARAM);
    } else {
      searchParams.set(SEVERITY_PARAM, newValue);
    }
    history.replace({ search: searchParams.toString() });
  };

  const resetFilters = (): void => {
    setProjects([]);
    setReports([]);
    setSeverity(null);
    searchParams.delete(PROJECT_PARAM);
    searchParams.delete(REPORT_PARAM);
    searchParams.delete(SEVERITY_PARAM);
    history.replace({ search: searchParams.toString() });
  };

  return {
    severity,
    reports,
    projects,
    setSeverity: updateSeverity,
    setProjects: updateProjects,
    setReports: updateReports,
    resetFilters,
  };
}

// NOTE: we get the initially selected projects and reports from the URL. However, all we know is the project ids and the report codes, but in order
// to satisfy the types of `ProjectOption` and `ReportOption`, we need to provide the other fields. This should be fine as the project filter component
// only uses the id to determine which options are selected, and the report filter component only uses the code to determine which options are selected.
const dummyProject = {
  id: "1",
  name: "Fake name",
  primaryDomain: "Fake domain",
  moduleCode: ModuleCode.Seo,
};

const dummyReport = {
  id: "1",
  name: "Fake report",
  code: "dlfkjd",
  description: "",
  reportCategories: [
    {
      name: "string",
      code: "string",
    },
  ],
};

function getInitialProjectsFromUrl(searchParams: string): ProjectOption[] {
  const parsedSearchParams = queryString.parse(searchParams, {
    arrayFormat: "comma",
  });
  const projectIds = parsedSearchParams[PROJECT_PARAM];

  if (!projectIds) return [];

  if (typeof projectIds === "string") {
    return [{ ...dummyProject, id: projectIds }];
  }

  return projectIds.filter(Boolean).map((id) => ({
    ...dummyProject,
    id,
  }));
}

function getInitialReportsFromUrl(searchParams: string): ReportOption[] {
  const parsedSearchParams = queryString.parse(searchParams, {
    arrayFormat: "comma",
  });
  const reportCodes = parsedSearchParams[REPORT_PARAM];

  if (!reportCodes) return [];

  if (typeof reportCodes === "string") {
    return [{ ...dummyReport, code: reportCodes }];
  }

  return reportCodes.filter(Boolean).map((code) => ({
    ...dummyReport,
    code: code ?? "",
  }));
}

function getInitialSeverityFromUrl(searchParams: string): Severity | null {
  const parsedSearchParams = queryString.parse(searchParams);
  const severity = parsedSearchParams[SEVERITY_PARAM];

  if (typeof severity === "string") {
    if (severity === Severity.Fail || severity === Severity.Warning) {
      return severity;
    }
  }
  return null;
}

import {
  Button,
  Checkbox,
  Snackbar,
  Typography,
  useNumberFormatter,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, useTheme, Chip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  Severity,
  TestResultStatus,
  useUpdateBulkNotificationsStatusMutation,
  useBulkAcceptSuggestedThresholdsMutation,
  useBulkDeclineSuggestedThresholdsMutation,
  GetNotificationsQueryVariables,
} from "../../graphql";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import {
  allNotificationSelection,
  emptyNotificationSelection,
  NotificationSelectionType,
} from "../utils/constants";
import { getFilterFromNotificationStatusFilter } from "../utils/getFilterFromNotificationStatusFilter";
import { notificationStatusToTestResultStatus } from "../utils/notificationStatusToTestResultStatus";
import { NotificationsSelectMenu } from "./NotificationsSelectMenu";
import { useParams } from "react-router-dom";
import { isEqual, sortBy, isEmpty } from "lodash";
import {
  BulkAcceptDeclineVariables,
  MonitorNotification,
  NotificationSelection,
} from "../types";
import { NotificationStatusFilter } from "../filters/constants";
import { ProjectOption, ReportOption } from "../../_common/utils/constants";
import { SuggestedThresholdFilter } from "../filters/SuggestedThresholdTabs";
import { BulkUpdateStatusMenu } from "./BulkUpdateStatusMenu";
import {
  BulkUpdateThresholdMenu,
  ThresholdStatus,
} from "./BulkUpdateThresholdMenu";
import { updateNotificationsInCache } from "../utils/updateNotificationsInCache";
import { useClearNotificationCache } from "../utils/useClearNotificationCache";

const CHECKBOX_SIZE = 24;

const useStyles = makeStyles((theme) => ({
  checkbox: {
    width: CHECKBOX_SIZE,
    height: CHECKBOX_SIZE,
    "& .MuiIconButton-label span": {
      "&:before": {
        width: CHECKBOX_SIZE,
        height: CHECKBOX_SIZE,
      },
      width: CHECKBOX_SIZE,
      height: CHECKBOX_SIZE,
    },
    "&[class*=MuiCheckbox-indeterminate] svg": {
      maxHeight: CHECKBOX_SIZE,
      maxWidth: CHECKBOX_SIZE,
    },
  },
  chip: {
    height: 18,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    alignSelf: "center",
    "& .MuiChip-label": {
      paddingLeft: 9,
      paddingRight: 9,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(13),
    },
  },
  separatorSmall: {
    height: 20,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    marginTop: 5,
    marginBottom: 5,
  },
  button: {
    background: "transparent",
    border: 0,
    boxShadow: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      background: "transparent!important",
    },
  },
}));

export interface StatusUpdateProps {
  totalCount: number;
  notifications?: MonitorNotification[];
  loading?: boolean;
  projects: ProjectOption[];
  reports: ReportOption[];
  severity: Severity | null;
  status: NotificationStatusFilter;
  hasFiltersApplied: boolean;
  selection: NotificationSelection;
  setSelection: (selection: NotificationSelection) => void;
  suggestedThresholdFilter: SuggestedThresholdFilter;
  showUpdateThresholdMenu?: boolean;
  queryVariables?: GetNotificationsQueryVariables;
}

export function NotificationStatusUpdate(
  props: StatusUpdateProps,
): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const theme = useTheme();
  const { t } = useTranslation(["alerts", "notifications", "errors"]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formatNumber = useNumberFormatter();
  const clearCache = useClearNotificationCache();

  const areAllNotificationsSelected =
    props.selection.state === NotificationSelectionType.SelectAll;

  const areAnyNotificationsSelected =
    Boolean(props.selection.selected.length) || areAllNotificationsSelected;

  const isAdjustmentsTabSelected =
    props.status === NotificationStatusFilter.Adjustments;

  const requiresManualApproval =
    props.suggestedThresholdFilter === "requiresApproval";

  const { count, text: selectionText } = useFilterAndSelectionText({
    isLoading: props.loading,
    hasFiltersApplied: props.hasFiltersApplied,
    notificationSelection: props.selection,
    notifications: props.notifications,
    requiresManualApproval,
    totalCount: props.totalCount,
  });

  const [bulkUpdateNotificationsStatus] =
    useUpdateBulkNotificationsStatusMutation({
      refetchQueries: [
        "GetNotifications",
        "NotificationTotalCounts",
        "RequiresManualApprovalCount",
        "GetNotificationsCount",
      ],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("notifications:failedToUpdateStatus", {
              message: error.message,
            })}
          />,
        );
      },
    });

  const [bulkAcceptSugestedThreshold] =
    useBulkAcceptSuggestedThresholdsMutation({
      refetchQueries: [
        "RequiresManualApprovalCount",
        "NotificationTotalCounts",
      ],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("errors:thresholdUpdate", {
              message: error.message,
            })}
          />,
        );
      },
    });

  const [bulkDeclineSugestedThreshold] =
    useBulkDeclineSuggestedThresholdsMutation({
      refetchQueries: [
        "RequiresManualApprovalCount",
        "NotificationTotalCounts",
      ],
      awaitRefetchQueries: true,
      onError: (error) => {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("errors:thresholdUpdate", {
              message: error.message,
            })}
          />,
        );
      },
    });

  function getMessageByStatus(status: TestResultStatus, count: number): string {
    switch (status) {
      case TestResultStatus.Read:
        return t("notifications:movedToArchived", { count: count }) as string;
      case TestResultStatus.WorkingOnIt:
        return t("notifications:movedToWorkingOnIt", {
          count: count,
        }) as string;
      case TestResultStatus.Unresolved:
        return t("notifications:statusRemoved", { count: count }) as string;
    }
    return t("notifications:movedToUnread", { count: count }) as string;
  }

  const handleAcceptanceChange = async (
    status: ThresholdStatus,
  ): Promise<void> => {
    if (status === ThresholdStatus.None) return;

    const variables: BulkAcceptDeclineVariables = {
      passed: isAdjustmentsTabSelected,
      accountId: accountId,
      severity: props.severity,
      projectIds: props.projects.length
        ? props.projects.map((p) => p.id)
        : undefined,
      reportTemplateCodes: props.reports.length
        ? props.reports.map((r) => r.code)
        : undefined,
      statusFilter: getFilterFromNotificationStatusFilter(props.status),
      testResultIds: props.selection.selected.length
        ? props.selection.selected.map((e) => e.id)
        : undefined,
      blackListed:
        props.selection.state === NotificationSelectionType.SelectAll,
    };
    const result =
      status === ThresholdStatus.Accept
        ? await bulkAcceptSugestedThreshold({
            variables,
            update: (cache, { data, errors }) => {
              if (
                !isAdjustmentsTabSelected &&
                props.queryVariables &&
                data &&
                !errors
              )
                updateNotificationsInCache(
                  cache,
                  props.queryVariables,
                  (cacheNotification) => {
                    const notification = props.selection.selected.find(
                      (e) => e.id === cacheNotification.id,
                    );
                    const existsInList = Boolean(notification);
                    if (
                      (props.selection.state ===
                        NotificationSelectionType.SelectAll &&
                        !existsInList) ||
                      (props.selection.state !==
                        NotificationSelectionType.SelectAll &&
                        existsInList)
                    )
                      return {
                        suggestedAbsoluteThresholdAcceptedAt:
                          new Date().toISOString(),
                        test: {
                          ...cacheNotification.test,
                          absoluteThreshold:
                            cacheNotification.suggestedAbsoluteThreshold
                              ? cacheNotification.suggestedAbsoluteThreshold
                              : notification?.test?.absoluteThreshold,
                        },
                      };
                    return {};
                  },
                );
              else if (isAdjustmentsTabSelected) clearCache();
            },
          })
        : await bulkDeclineSugestedThreshold({
            variables,
            update: (cache, { data, errors }) => {
              if (
                !isAdjustmentsTabSelected &&
                props.queryVariables &&
                data &&
                !errors
              )
                updateNotificationsInCache(
                  cache,
                  props.queryVariables,
                  (notification) => {
                    const existsInList = Boolean(
                      props.selection.selected.find(
                        (e) => e.id === notification.id,
                      ),
                    );
                    if (
                      (props.selection.state ===
                        NotificationSelectionType.SelectAll &&
                        !existsInList) ||
                      (props.selection.state !==
                        NotificationSelectionType.SelectAll &&
                        existsInList)
                    )
                      return {
                        suggestedAbsoluteThresholdRejectedAt:
                          new Date().toISOString(),
                      };
                    return {};
                  },
                );
              else if (isAdjustmentsTabSelected) clearCache();
            },
          });
    if (!Boolean(result.errors)) {
      props.setSelection(emptyNotificationSelection);
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t(
            status === ThresholdStatus.Accept
              ? "notifications:succesAccept"
              : "notifications:succesDecline",
          )}
        />,
      );
    }
  };

  const handleStatusChange = async (
    status: TestResultStatus,
  ): Promise<void> => {
    const selectionCount = count;
    const result = await bulkUpdateNotificationsStatus({
      variables: {
        accountId: accountId,
        severity: props.severity,
        projectIds: props.projects.length
          ? props.projects.map((p) => p.id)
          : undefined,
        reportTemplateCodes: props.reports.length
          ? props.reports.map((r) => r.code)
          : undefined,
        statusFilter: getFilterFromNotificationStatusFilter(props.status),
        testResultIds: props.selection.selected.length
          ? props.selection.selected.map((e) => e.id)
          : undefined,
        blackListed:
          props.selection.state === NotificationSelectionType.SelectAll,
        toStatus: status,
      },
    });
    if (!Boolean(result.errors)) {
      props.setSelection(emptyNotificationSelection);
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={getMessageByStatus(status, selectionCount)}
        />,
      );
    }
  };

  const formattedCount = formatNumber(count);

  const areSomeNotificationsSelected = !!props.selection.selected.length;
  const isChecked = areSomeNotificationsSelected || areAllNotificationsSelected;

  return (
    <HideFromInsufficientRole>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0px 11px 0px 8px",
          background: isChecked ? theme.palette.grey[200] : "inherit",
          backdropFilter: isChecked ? "blur(4px)" : "none",
          borderRadius: 8,
          height: 48,
        }}
      >
        <Checkbox
          className={classes.checkbox}
          checked={isChecked}
          indeterminate={areSomeNotificationsSelected}
          onChange={(_, checked) => {
            if (checked === true) props.setSelection(allNotificationSelection);
            else props.setSelection(emptyNotificationSelection);
          }}
          data-testid="select-all-notifications"
          inputProps={{
            "aria-label": t("notifications:selectAll", {
              count: props.totalCount,
            }),
          }}
        />
        <NotificationsSelectMenu
          totalCount={props.totalCount}
          visibleCount={props.notifications?.length ?? 0}
          onChange={(e) => {
            if (e === NotificationSelectionType.SelectVisible)
              props.setSelection({
                state: NotificationSelectionType.SelectVisible,
                selected: props.notifications ?? [],
              });
            if (e === NotificationSelectionType.SelectAll)
              props.setSelection({
                state: NotificationSelectionType.SelectAll,
                selected: [],
              });
          }}
        />
        <Typography
          variant="button"
          style={{
            color: theme.palette.grey[500],
            marginLeft: theme.spacing(1.875),
            marginRight: theme.spacing(1.125),
            alignSelf: "center",
          }}
        >
          {selectionText}
        </Typography>
        {!props.loading ? (
          <Chip
            label={count ? formattedCount : "None"}
            className={classes.chip}
          />
        ) : null}
        {areAnyNotificationsSelected && !isAdjustmentsTabSelected ? (
          <>
            <div
              className={classes.separatorSmall}
              style={{
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
              }}
            />
            <BulkUpdateStatusMenu
              initialStatus={notificationStatusToTestResultStatus(props.status)}
              onChange={handleStatusChange}
            />
            {props.showUpdateThresholdMenu ? (
              <>
                <div
                  className={classes.separatorSmall}
                  style={{
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(1),
                  }}
                />
                <BulkUpdateThresholdMenu onChange={handleAcceptanceChange} />
              </>
            ) : undefined}
            {isAdjustmentsTabSelected ? (
              <>
                <Button
                  variant="text"
                  className={classes.button}
                  data-pendo={`notifications-page-remove-all-statuses`}
                  style={{ marginLeft: theme.spacing(2.25) }}
                  onClick={async () => {
                    const selectionCount = count;
                    const result = await bulkUpdateNotificationsStatus({
                      variables: {
                        accountId: accountId,
                        severity: props.severity,
                        projectIds: props.projects.length
                          ? props.projects.map((p) => p.id)
                          : undefined,
                        reportTemplateCodes: props.reports.length
                          ? props.reports.map((r) => r.code)
                          : undefined,
                        statusFilter: getFilterFromNotificationStatusFilter(
                          props.status,
                        ),
                        testResultIds: props.selection.selected.length
                          ? props.selection.selected.map((e) => e.id)
                          : undefined,
                        blackListed:
                          props.selection.state ===
                          NotificationSelectionType.SelectAll,
                        toStatus: TestResultStatus.Unresolved,
                      },
                    });
                    if (!Boolean(result.errors)) {
                      props.setSelection(emptyNotificationSelection);
                      enqueueSnackbar(
                        <Snackbar
                          variant="success"
                          title={getMessageByStatus(
                            TestResultStatus.Unresolved,
                            selectionCount,
                          )}
                        />,
                      );
                    }
                  }}
                >
                  {t("notifications:removeAll")}
                </Button>
              </>
            ) : undefined}
          </>
        ) : areAnyNotificationsSelected && props.showUpdateThresholdMenu ? (
          <>
            <div
              className={classes.separatorSmall}
              style={{
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
              }}
            />
            <BulkUpdateThresholdMenu onChange={handleAcceptanceChange} />
          </>
        ) : undefined}
      </div>
    </HideFromInsufficientRole>
  );
}

interface FilterAndSelectionTextArgs {
  isLoading?: boolean;
  notificationSelection: NotificationSelection;
  requiresManualApproval: boolean;
  hasFiltersApplied: boolean;
  totalCount: number;
  notifications?: MonitorNotification[];
}

const useFilterAndSelectionText = ({
  isLoading,
  notificationSelection,
  requiresManualApproval,
  hasFiltersApplied,
  notifications,
  totalCount,
}: FilterAndSelectionTextArgs): { count: number; text: string } => {
  const { t } = useTranslation(["alerts", "notifications"]);

  if (isLoading) return { count: 0, text: "" };

  if (
    notificationSelection.state === NotificationSelectionType.SelectAll &&
    isEmpty(notificationSelection.selected)
  ) {
    const requiresManualApprovalText = hasFiltersApplied
      ? "Selected that require manual approval with applied filters:"
      : "Selected that require manual approval";

    const text = requiresManualApproval
      ? requiresManualApprovalText
      : hasFiltersApplied
        ? t("notifications:selectedAllWF")
        : t("notifications:selectedAll");

    return {
      count: totalCount,
      text,
    };
  }
  if (areAllVisibleSelected(notificationSelection, notifications)) {
    const requiresManualApprovalText = hasFiltersApplied
      ? "Selected that require manual approval with applied filters:"
      : "Selected that require manual approval";

    return {
      count: notificationSelection.selected.length,
      text: requiresManualApproval
        ? requiresManualApprovalText
        : t("notifications:selectedVisible"),
    };
  }
  if (
    notificationSelection.selected.length &&
    notificationSelection.state !== NotificationSelectionType.SelectAll
  ) {
    const requiresManualApprovalText = hasFiltersApplied
      ? "Selected that require manual approval with applied filters:"
      : "Selected that require manual approval";

    return {
      count: notificationSelection.selected.length,
      text: requiresManualApproval
        ? requiresManualApprovalText
        : hasFiltersApplied
          ? t("notifications:selectedWF")
          : t("notifications:selected"),
    };
  } else if (
    notificationSelection.state === NotificationSelectionType.SelectAll
  ) {
    return {
      count: totalCount - notificationSelection.selected.length,
      text: hasFiltersApplied
        ? t("notifications:selectedWF")
        : t("notifications:selected"),
    };
  }
  const requiresManualApprovalText = hasFiltersApplied
    ? "Require manual approval with applied filters:"
    : "Requires manual approval:";

  return {
    count: totalCount,
    text: requiresManualApproval
      ? requiresManualApprovalText
      : hasFiltersApplied
        ? t("notifications:notificationsWithFilters")
        : t("notifications:total"),
  };
};

const areAllVisibleSelected = (
  notificationSelection: NotificationSelection,
  notifications?: MonitorNotification[],
): boolean => {
  if (
    notificationSelection.state === NotificationSelectionType.SelectAll ||
    !notificationSelection.selected.length
  )
    return false;
  return isEqual(sortBy(notificationSelection.selected), sortBy(notifications));
};

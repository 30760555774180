import { ApolloError } from "@lumar/shared";
import { useMemo } from "react";
import { GetViewsForCollectionQuery } from "../../../../graphql";
import { View } from "../../types";
import { useGetViewsForBoardLazyQuery } from "./useGetViewsForBoardLazyQuery";

export interface GetViewsForBoardAccumulatorReturnType {
  data:
    | {
        dashboardId?: string;
        views: View[];
        name?: string;
        viewsTotalCount?: number;
        accountId: string | null;
      }
    | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

export function useGetViewsForBoardAccumulator(
  skip: boolean,
  onCompleted?: (data: GetViewsForCollectionQuery | undefined) => void,
): GetViewsForBoardAccumulatorReturnType {
  const {
    data: loadedData,
    loading,
    error,
    handleLoadMore,
    hasFetchedAllData,
  } = useGetViewsForBoardLazyQuery(skip, (data) => {
    if (
      data.getCustomDashboardCollection?.customViews.totalCount ===
      data.getCustomDashboardCollection?.customViews.edges.length
    ) {
      onCompleted?.(data);
    }
  });
  if (!Boolean(error) && !hasFetchedAllData) {
    handleLoadMore();
  }
  const isLoading = loading || !hasFetchedAllData;

  const viewData = useMemo(() => {
    const views = (
      hasFetchedAllData
        ? loadedData?.getCustomDashboardCollection?.customViews.edges ?? []
        : []
    ).map(
      ({ node: e }): View => ({
        id: e.id,
        projectId: e.project.id,
        projectName: e.project.name,
        primaryDomain: e.project.primaryDomain,
        segmentId: e.segment?.id,
        segmentName: e.segment?.name,
        industryCode: e.project.industryCode,
        scheduleFrequency: e.project.schedule?.scheduleFrequency,
      }),
    );
    return views;
  }, [loadedData, hasFetchedAllData]);

  return {
    data: Boolean(loadedData)
      ? {
          views: viewData,
          dashboardId: loadedData?.getCustomDashboardCollection?.id,
          name: loadedData?.getCustomDashboardCollection?.name,
          accountId:
            loadedData?.getCustomDashboardCollection?.accountId ?? null,
          viewsTotalCount:
            loadedData?.getCustomDashboardCollection?.customViews?.totalCount,
        }
      : undefined,
    loading: isLoading && !Boolean(error),
    error,
  };
}

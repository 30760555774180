import { getRawProjectId, getRawSegmentId } from "@lumar/shared";
import {
  HealthscoreProjectComparisonChartQuery,
  HealthScoreTrendsTableRow,
} from "../../../../../graphql";
import { AbsoluteURLs } from "../../../../../_common/routing/absoluteURLs";
import { SeriesLineOptions } from "highcharts";
import { View } from "../../../../settings/types";
import { isRawView } from "../../../common/project-comparison-chart/helpers/isRawView";

export function mapHealthscoreComparisonChartDataToSeries({
  data,
  accountId,
  reportTemplateCategoryCode,
  benchmarkFunc = () => 0,
  views,
  noSegmentText,
}: {
  data?: HealthscoreProjectComparisonChartQuery;
  accountId: string;
  reportTemplateCategoryCode: string;
  benchmarkFunc: (a: string, b: string) => number | undefined;
  views: View[];
  noSegmentText: string;
}): SeriesLineOptions[] | undefined {
  if (!data) {
    return undefined;
  }
  const tmp = data.getCustomChart?.trends.nodes
    .filter((e): e is HealthScoreTrendsTableRow => isRawView(e))
    .map((rawView, index) => {
      const { projectId, segmentId, columns } = rawView;
      const fullView = (views ?? []).find(
        (e) =>
          getRawProjectId(e.projectId) === projectId.toString() &&
          getRawSegmentId(e.segmentId ?? "0") === segmentId.toString(),
      );
      const healthScore = columns[0]?.points ?? [];

      const result = {
        name: fullView?.projectName,
        type: "line",
        id: `${projectId}-${segmentId ?? "0"}`,
        colorIndex: index,
        segment: fullView?.segmentName ?? noSegmentText,
        // NOTE: We spread the array into a new array before we mutate with a reverse so it's O.K - Saul.
        // eslint-disable-next-line fp/no-mutating-methods
        data: [
          ...healthScore
            .filter((trend) => Boolean(trend.createdAt))
            .map((trend) => {
              const { crawlId, createdAt } = trend;
              const createdAtTimeInMilliseconds = new Date(
                createdAt ?? "",
              ).getTime();
              const crawlFrequency = fullView?.scheduleFrequency?.name ?? "";
              const industryBenchmark = benchmarkFunc(
                reportTemplateCategoryCode,
                fullView?.industryCode ?? "",
              );
              return {
                y: Math.floor(trend.healthScore * 100),
                x: createdAtTimeInMilliseconds,
                href: AbsoluteURLs.EXTERNAL__AnalyzeHealthScore.getUrl({
                  accountId,
                  projectId: `${projectId}`,
                  crawlId: String(crawlId),
                  reportCategoryCode: reportTemplateCategoryCode,
                  segmentId: segmentId ? segmentId?.toString() : undefined,
                }),
                crawlFrequency,
                sign: industryBenchmark
                  ? Math.sign(trend.healthScore - industryBenchmark)
                  : undefined,
              };
            }),
        ].reverse(),
      };
      return result as SeriesLineOptions;
    })
    .filter((x): x is SeriesLineOptions => x !== undefined)
    .map((x) => {
      return x;
    });

  return tmp;
}

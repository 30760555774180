import { useNumberFormatter, useTranslation, Typography } from "@lumar/shared";
import { createStyles, makeStyles, Tooltip, useTheme } from "@material-ui/core";
import {
  SparklinesMiniChart,
  SparklinesMiniChartLineData,
} from "../../_common/components/SparklinesMiniChart/SparklinesMiniChart";
import { CrawlChangeStats } from "../../alerts/create/components/CrawlChangeStatistics";
import { minBy, maxBy } from "lodash";
import { DashStyleValue } from "highcharts";
import { ReportTemplateUnit } from "../../graphql";

const SPARKLINE_HEIGHT = 30;
const SPARKLINE_WIDTH = 90;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      gap: 12,
    },
    sparklineContainer: { minHeight: SPARKLINE_HEIGHT },
    sparklineSpan: {
      width: SPARKLINE_WIDTH,
      height: SPARKLINE_HEIGHT,
      display: "block",
    },
    urlCountText: {
      textAlign: "end",
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
    },
  }),
);

interface Props {
  previousUrlCount: number;
  currentUrlCount: number;
  thresholdTrendData?: [number, number][];
  reportTrendData: [number, number][];
  absoluteThreshold: number;
  totalSign?: number;
  edited?: boolean;
  unit?: ReportTemplateUnit | null;
}

export function ReportTrendInfo({
  previousUrlCount,
  currentUrlCount,
  reportTrendData,
  thresholdTrendData,
  absoluteThreshold,
  totalSign = 0,
  edited,
  unit = ReportTemplateUnit.UrLs,
}: Props): JSX.Element {
  const { t } = useTranslation(["units", "common", "alerts"]);
  const formatNumber = useNumberFormatter();
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.container}>
      <div className={classes.sparklineContainer}>
        <Tooltip
          title={
            <div>
              <Typography variant="subtitle4">
                {t("alerts:rulesAndThresholds.changeComparison")}
              </Typography>
              <CrawlChangeStats
                currentUrlCount={currentUrlCount}
                previousUrlCount={previousUrlCount}
                totalSign={totalSign}
              />
              <div style={{ marginTop: theme.spacing(1.5), display: "flex" }}>
                <div
                  style={{
                    width: 22,
                    height: 0,
                    marginRight: theme.spacing(0.625),
                    alignSelf: "center",
                    border: `1px dashed ${
                      !edited
                        ? theme.palette.red[400]
                        : theme.palette.orange[500]
                    }`,
                    borderTopWidth: 0,
                  }}
                />
                <Typography variant="subtitle4">
                  {t(
                    !edited
                      ? "alerts:rulesAndThresholds.thresholdHistory"
                      : "alerts:rulesAndThresholds.currentThreshold",
                  )}
                </Typography>
              </div>
            </div>
          }
          arrow={false}
        >
          <span className={classes.sparklineSpan}>
            <SparklinesMiniChart
              height={SPARKLINE_HEIGHT}
              width={SPARKLINE_WIDTH}
              minX={minBy(reportTrendData, (e) => e[0])?.[0] ?? 0}
              maxX={maxBy(reportTrendData, (e) => e[0])?.[0] ?? 1}
              data={[
                reportTrendData,
                ...(thresholdTrendData && !edited
                  ? [
                      {
                        values: thresholdTrendData,
                        color: theme.palette.red[500],
                        gradient: null,
                        disableMarker: true,
                        dashStyle: "ShortDash" as DashStyleValue,
                        lineWidth: 1,
                      },
                    ]
                  : []),
                ...(edited
                  ? [
                      {
                        direction: "Y",
                        dashStyle: "ShortDash",
                        color: theme.palette.orange[500],
                        lineWidth: 1,
                        value: absoluteThreshold,
                        zIndex: 3,
                      } as SparklinesMiniChartLineData,
                    ]
                  : []),
              ]}
              allowChartUpdate={true}
            />
          </span>
        </Tooltip>
      </div>
      <Tooltip
        title={
          t("alerts:totalUrlCountTooltip", {
            unit: t(`units:${unit}`, { count: currentUrlCount }),
          }) as string
        }
        arrow={false}
      >
        <Typography
          variant="subtitle2SemiBold"
          className={classes.urlCountText}
          data-testid="current-url-count"
        >
          {formatNumber(currentUrlCount)}
        </Typography>
      </Tooltip>
    </div>
  );
}

import React from "react";
import {
  Typography,
  Alert,
  ToggleIconButton,
  DuplicateSolid,
  PencilOutlined,
  useTranslation,
  ApolloError,
} from "@lumar/shared";
import { makeStyles, Fade, useTheme, Tooltip, Button } from "@material-ui/core";
import { AlertActionButtons } from "./AlertActionButtons";
import { CrawlStatus, GetAlertWithTestsQuery } from "../../../graphql";
import { useReportTemplateAccumulator } from "../../../_common/hooks/useReportTemplateAccumulator";
import { useParams } from "react-router-dom";
import { HideFromInsufficientRole } from "../../../_common/components/HideFromInsufficientRole";
import { Skeleton } from "@material-ui/lab";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";
import { CopyAlertDialog } from "../../copy/components/CopyAlertDialog";
import { ConditionallyShow } from "../../../_common/components/ConditionallyShow";
import { PendoGuideIcon } from "../../_common/PendoGuideIcon";
import { useDeleteAlertMutation } from "../utils/useDeleteAlertMutation";
import { ProjectOption } from "../../../_common/utils/constants";
import { RulesAndThresholdsViewTable } from "../../_common/RulesAndThresholdsViewTable";
import { useFormikContext } from "formik";
import {
  RuleAndThreshold,
  RulesAndThresholdsFormState,
} from "../../_common/utils/types";
import { NotificationChanelSelector } from "../../_common/notification-channels/NotificationChanelSelector";
import { RulesAndThresholdsEditTable } from "../../_common/RulesAndThresholdsEditTable";
import clsx from "clsx";

interface Props {
  loading: boolean;
  error?: ApolloError;
  data?: GetAlertWithTestsQuery;
  headerVisible?: boolean;
  onDeletionSuccess: () => void;
  onCopyAlert?: (projects: ProjectOption[]) => void;
  disableCopyAlert?: boolean;
  isEditMode?: boolean;
  setEditMode: (editMode: boolean) => void;
}

export function AlertRulesAndThresholds({
  loading: loadingProvided,
  error,
  data,
  headerVisible,
  onDeletionSuccess,
  onCopyAlert,
  disableCopyAlert,
  isEditMode,
  setEditMode,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["common", "alerts"]);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { alertId } = useParams<{
    alertId: string;
  }>();

  const { alertsPage } = useMonitorRoutes();

  const {
    reports: reportsData,
    categories,
    error: reportsError,
    loading: loadingReports,
  } = useReportTemplateAccumulator({
    moduleCodes: data?.alert?.moduleCode ? [data?.alert?.moduleCode] : [],
    projectId: alertId,
  });

  const loading = loadingProvided || loadingReports;

  const [deleteAlert] = useDeleteAlertMutation();

  async function handleDelete(): Promise<void> {
    if (data?.alert) {
      await deleteAlert(data?.alert);
    }
    onDeletionSuccess();
    setEditMode(false);
    alertsPage.visit();
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const { values, isSubmitting, isValid, isValidating } =
    useFormikContext<RulesAndThresholdsFormState>();

  const showAlert =
    data?.alert?.lastCrawlStatus === CrawlStatus.Archived ||
    data?.alert?.lastCrawlStatus === CrawlStatus.Archiving;

  const invalid = values.rulesAndThresholds.reduce<boolean>((invalid, e) => {
    if (!e.segment) return false;
    return invalid;
  }, true);
  return (
    <>
      <div
        style={{
          width: "100%",
          padding: theme.spacing(0, 0),
          background: "#F0F3F7",
          marginBottom: theme.spacing(7),
        }}
      >
        <div className={classes.mainContentHeader}>
          {showAlert && (
            <Alert severity="warning" size="large" className={classes.archived}>
              <strong>{t("alerts:alertWarningMessage")}</strong>{" "}
              {t("alerts:alertWarningDescription")}
            </Alert>
          )}
          <div className={classes.contentHeader}>
            <div
              style={{
                marginRight: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2Medium"
                className={classes.mainContentTitle}
              >
                {t("alerts:chooseThresholdsTitle")}
              </Typography>
              <PendoGuideIcon />
            </div>
            <HideFromInsufficientRole>
              {isEditMode ? undefined : (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  data-pendo="edit-alert"
                  data-testid="edit-alert"
                  title="Edit"
                  startIcon={<PencilOutlined />}
                  className={classes.editButton}
                  onClick={() => setEditMode(true)}
                  disabled={loading}
                >
                  {t("common:edit")}
                </Button>
              )}
              {!disableCopyAlert && onCopyAlert ? (
                <>
                  <Tooltip
                    title={
                      (invalid
                        ? t("alerts:invalidCopy")
                        : t("alerts:copyto")) as string
                    }
                    arrow={false}
                  >
                    <span>
                      <ToggleIconButton
                        colorVariant="primary"
                        size="large"
                        variant="outlined"
                        data-pendo="copy-alert"
                        data-testid="copy-alert"
                        onClick={handleClick}
                        active={Boolean(anchorEl)}
                        disabled={loading || invalid}
                      >
                        <DuplicateSolid />
                      </ToggleIconButton>
                    </span>
                  </Tooltip>
                  <ConditionallyShow show={Boolean(anchorEl)}>
                    <CopyAlertDialog
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onOk={(projects) => {
                        setAnchorEl(null);
                        onCopyAlert(projects);
                      }}
                      onCancel={() => {
                        setAnchorEl(null);
                      }}
                      excludeProject={alertId}
                    />
                  </ConditionallyShow>
                  <div
                    style={{
                      height: 36,
                      width: 0,
                      borderLeft: `1px solid ${theme.palette.grey[300]}`,
                      margin: theme.spacing(0, 1.125, 0, 1.125),
                    }}
                  />
                </>
              ) : undefined}
              <AlertActionButtons
                onDeleteConfirmationClick={handleDelete}
                disabled={loading}
              />
            </HideFromInsufficientRole>
          </div>
        </div>
        <Typography
          variant="caption"
          component="p"
          className={classes.mainContentSubtitle}
        >
          {t("alerts:chooseThresholdsDescription")}
        </Typography>
        {loading ? <LoadingState /> : null}
        {error ? (
          <Alert severity="error">
            <strong>{t("alerts:rulesAndThresholdsError")}</strong>{" "}
            {error.message}
          </Alert>
        ) : null}
        {!loading ? (
          <Fade in>
            <div>
              {!isEditMode ? (
                <RulesAndThresholdsViewTable
                  crawlId={data?.alert?.builds?.nodes?.[0]?.crawl?.id}
                  className={classes.table}
                  projectId={alertId}
                  testList={(values.rulesAndThresholds ?? []).map((e) => {
                    const test = (data?.alert?.tests.nodes ?? []).find(
                      (t) =>
                        t.reportTemplate.code === e.report.code &&
                        t.segment?.id === e.segment?.id,
                    );
                    const reportTemplate = reportsData?.find(
                      (t) => t.code === e.report.code,
                    );
                    return {
                      ...e,
                      report: {
                        ...e.report,
                        name: reportTemplate?.name ?? e.report.name,
                      },
                      testResults: test?.testResultList.nodes ?? [],
                    } as RuleAndThreshold;
                  })}
                />
              ) : (
                <RulesAndThresholdsEditTable
                  className={clsx(
                    headerVisible ? classes.addedMargin : undefined,
                    classes.table,
                  )}
                  reports={reportsData ?? []}
                  categories={categories ?? []}
                  hasReportsError={Boolean(reportsError)}
                  crawlId={data?.alert?.builds?.nodes?.[0]?.crawl?.id}
                  projectId={alertId}
                  moduleCode={data?.alert?.moduleCode}
                />
              )}
              <NotificationChanelSelector
                key={String(isSubmitting && isValid && !isValidating)}
                isEditMode={Boolean(isEditMode)}
              />
            </div>
          </Fade>
        ) : null}
      </div>
    </>
  );
}

const LoadingState = (): JSX.Element => {
  return (
    <>
      {new Array(3).fill(null).map((_, i) => (
        <Skeleton
          key={i}
          variant="rect"
          height={32}
          width="100%"
          style={{ marginTop: 10, borderRadius: 6 }}
        />
      ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContentHeader: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
  },
  mainContentTitle: { marginRight: theme.spacing(1), fontWeight: 600 },
  mainContentSubtitle: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  addedMargin: {
    background: "#F0F3F7",
  },
  editButton: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    height: 36,
    borderRadius: 8,
    padding: theme.spacing(1, 1.5),
    "&:hover": {
      background: theme.palette.grey[200],
      cursor: "pointer",
    },
    marginRight: theme.spacing(1),
  },
  table: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  archived: {
    margin: 0,
    marginBottom: theme.spacing(2.25),
  },
}));

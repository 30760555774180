import { useState, useMemo } from "react";
import {
  Button,
  Typography,
  CogSolid,
  PlusSolid,
  Breadcrumbs,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, useTheme } from "@material-ui/core";
import { TopNav } from "../navigation/TopNav";
import { NotificationsList } from "./list/NotificationsList";
import { HideFromInsufficientRole } from "../_common/components/HideFromInsufficientRole";
import { useNotificationsQuery } from "./utils/useNotificationsQuery";
import { useMonitorRoutes } from "../_common/routing/useMonitorRoutes";
import { ErrorBoundary } from "@rollbar/react";
import { SimpleFallbackError } from "../_common/components/SimpleFallbackError";
import {
  TestAutoThresholdAcceptance,
  useHasAlertsQuery,
  useRequiresManualApprovalCountQuery,
} from "../graphql";
import {
  NotificationStatusFilter,
  notificationStatusFilters,
} from "./filters/constants";
import { StatusTabs } from "./filters/StatusTabs";
import { useNotificationFilters } from "./utils/useNotificationFilters";
import { useParams } from "react-router-dom";
import {
  emptyNotificationSelection,
  NotificationSelectionType,
} from "./utils/constants";
import { MonitorNotification, NotificationSelection } from "./types";
import { NotificationsFilters } from "./components/NotificationFilters";
import { NotificationStatusUpdate } from "./components/NotificationStatusUpdate";
import { intersectionWith } from "lodash";
import {
  SuggestedThresholdFilter,
  SuggestedThresholdTabs,
} from "./filters/SuggestedThresholdTabs";
import { StickyContainer } from "./StickyContainer";
import { useMostRecentCrawlIds } from "./utils/useMostRecentCrawlIds";
import { getFilterFromNotificationStatusFilter } from "./utils/getFilterFromNotificationStatusFilter";
import { getNotificationList } from "./utils/utils";

const useStyles = makeStyles((theme) => ({
  multiselect: {
    margin: theme.spacing(1.75, 0, 1, 1),
    height: 30,
  },
  multiselectContainer: {
    backgroundColor: "#F0F3F7",
  },
  filtersContainer: {
    borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    backgroundColor: "#F0F3F7",
    paddingTop: theme.spacing(1),
    gap: theme.spacing(2),
    flexWrap: "wrap",
  },
}));

function hasManualAcceptThresholdInSelection(
  selection: NotificationSelection,
): boolean {
  return selection.selected.some((e) => {
    const isFromMostRecentCrawl =
      e.reportStat?.crawlId === e.reportStat?.project.lastFinishedCrawl?.id;
    const isNotAltered =
      e.test?.reportTemplateCode === e.reportTemplate.code &&
      e.test?.thresholdType === e.thresholdType &&
      e.test?.thresholdPredicate === e.thresholdPredicate &&
      e.test?.absoluteThreshold === e.absoluteThreshold;

    return (
      e.automaticThresholdAcceptanceWhenTestResultIsWorse ===
        TestAutoThresholdAcceptance.Suggest &&
      typeof e.suggestedAbsoluteThreshold === "number" &&
      !Boolean(e.suggestedAbsoluteThresholdAcceptedAt) &&
      !Boolean(e.suggestedAbsoluteThresholdRejectedAt) &&
      isFromMostRecentCrawl &&
      isNotAltered
    );
  });
}

export function NotificationsPage(): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(["alerts", "notifications"]);

  const {
    severity,
    projects,
    reports,
    setSeverity,
    setProjects,
    setReports,
    resetFilters,
  } = useNotificationFilters();

  const [suggestedThresholdFilter, setSuggestedThresholdFilter] =
    useState<SuggestedThresholdFilter>("all");

  const [notificationStatus, setNotificationStatus] = useState(
    notificationStatusFilters[0],
  );

  const [notificationSelection, setNotificationSelection] =
    useState<NotificationSelection>(emptyNotificationSelection);

  const { alertsPage, createAlertPage } = useMonitorRoutes();

  const { data: crawlIds, loading: areCrawlIdsLoading } =
    useMostRecentCrawlIds();

  const {
    data,
    error,
    loading,
    reloading,
    hasFetchedAllData,
    handleLoadMore,
    queryVariables,
  } = useNotificationsQuery({
    severity:
      notificationStatus === NotificationStatusFilter.Adjustments
        ? undefined
        : severity,
    notificationStatus,
    suggestedThresholdFilter,
    projectIds: projects.length ? projects.map((p) => p.id) : null,
    reportTemplateCodes: reports.length ? reports.map((r) => r.code) : null,
    count: Math.ceil(notificationSelection.selected.length / 10) * 10,
    crawlIds,
    onCompleted: (data) => {
      if (data)
        setNotificationSelection({
          state: notificationSelection.state,
          selected: intersectionWith(
            notificationSelection.selected,
            data?.getAccount?.notifications?.edges.map((e) => e.node) ?? [],
            (a, b) => a.id === b.id,
          ),
        });
    },
  });

  const { data: alertCount, loading: loadingAlertCount } = useHasAlertsQuery({
    variables: { accountId: accountId },
  });

  const hasAlerts =
    !loadingAlertCount &&
    (alertCount?.getAccount?.projects?.totalCount !== 0 ||
      alertCount?.getAccount?.monitorNotifications.totalCount !== 0);

  const hasFiltersApplied = Boolean(
    severity || projects.length || reports.length,
  );

  const isAdjustmentsTabSelected =
    notificationStatus === NotificationStatusFilter.Adjustments;

  const handleNotificationStatusChange = (
    newValue: NotificationStatusFilter,
  ): void => {
    setNotificationStatus(newValue);
    setNotificationSelection(emptyNotificationSelection);
  };

  const notifications = useMemo<MonitorNotification[] | undefined>(
    () => getNotificationList(data),
    [data],
  );

  const { data: manualApprovalCount } = useRequiresManualApprovalCountQuery({
    variables: {
      accountId,
      projectIds: projects.length ? projects.map((p) => p.id) : undefined,
      reportTemplateCodes: reports.length
        ? reports.map((r) => r.code)
        : undefined,
      severity: severity ?? undefined,
      statusFilter: getFilterFromNotificationStatusFilter(notificationStatus),
      crawlIdFilter: { in: crawlIds },
    },
  });

  const showUpdateThresholdMenu =
    isAdjustmentsTabSelected ||
    (notificationSelection.state === NotificationSelectionType.SelectAll
      ? Boolean(manualApprovalCount?.getAccount?.notifications.totalCount)
      : hasManualAcceptThresholdInSelection(notificationSelection));
  return (
    <>
      <TopNav includeNotificationsMenu={false}>
        <Breadcrumbs
          breadcrumbItems={[
            {
              label: "Home",
              link: "/",
            },
            {
              label: t("notifications"),
            },
          ]}
        />
      </TopNav>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: theme.spacing(1),
        }}
      >
        <Typography variant="h1" style={{ marginRight: 8, fontWeight: 600 }}>
          {t("notificationsCenter")}
        </Typography>

        <Button
          style={{ marginLeft: "auto" }}
          variant="outlined"
          size="large"
          startIcon={<CogSolid />}
          data-pendo="notifications-page-manage-notifications-button"
          onClick={() => {
            alertsPage.visit();
          }}
        >
          {t("manage")}
        </Button>
        <HideFromInsufficientRole>
          <Button
            variant="contained"
            color="primary"
            size="large"
            data-pendo="notifications-page-manage-create-alert-button"
            startIcon={<PlusSolid />}
            style={{ marginLeft: 14 }}
            onClick={() => {
              createAlertPage.visit();
            }}
          >
            {t("createNewAlert")}
          </Button>
        </HideFromInsufficientRole>
      </div>

      <StickyContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            whiteSpace: "nowrap",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          className={classes.filtersContainer}
        >
          <StatusTabs
            value={notificationStatus}
            onChange={handleNotificationStatusChange}
            mostRecentCrawlIds={crawlIds}
            loading={loading || areCrawlIdsLoading}
          />
          <NotificationsFilters
            hasFiltersApplied={hasFiltersApplied}
            onClearFilters={resetFilters}
            onFilterChange={() => {
              setNotificationSelection(emptyNotificationSelection);
            }}
            showSeverityFilter={!isAdjustmentsTabSelected}
            disabled={
              data?.getAccount?.notifications?.totalCount === 0 &&
              !hasFiltersApplied
            }
            projects={projects}
            onProjectsChange={(p) => {
              setProjects(p);
              if (p.length !== 1)
                setReports(
                  reports.filter(
                    (e) => !e.code.startsWith("custom_extraction"),
                  ),
                );
            }}
            reports={reports}
            onReportsChange={(r) => {
              setReports(r);
            }}
            severity={severity}
            onSeverityChange={(s) => {
              setSeverity(s);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "20px 20px 8px 20px",
          }}
          className={classes.multiselectContainer}
          data-testid="bulk-update-notifications"
        >
          {(data?.getAccount?.notifications?.totalCount ?? 0) ===
          0 ? undefined : (
            <NotificationStatusUpdate
              setSelection={(s) => setNotificationSelection(s)}
              notifications={notifications}
              projects={projects}
              reports={reports}
              severity={severity}
              selection={notificationSelection}
              totalCount={data?.getAccount?.notifications?.totalCount ?? 0}
              loading={loading}
              hasFiltersApplied={hasFiltersApplied}
              status={notificationStatus}
              suggestedThresholdFilter={suggestedThresholdFilter}
              showUpdateThresholdMenu={showUpdateThresholdMenu}
              queryVariables={queryVariables}
            />
          )}
          {!isAdjustmentsTabSelected ? (
            <div style={{ marginLeft: "auto", paddingTop: 8 }}>
              <SuggestedThresholdTabs
                crawlIds={crawlIds}
                value={suggestedThresholdFilter}
                onChange={(value) => {
                  setSuggestedThresholdFilter(value);
                }}
                projectIds={projects.map((p) => p.id)}
                reportTemplateCodes={reports.map((r) => r.code)}
                severity={severity}
                status={notificationStatus}
              />
            </div>
          ) : null}
        </div>
      </StickyContainer>

      <ErrorBoundary fallbackUI={SimpleFallbackError}>
        <NotificationsList
          queryVariables={queryVariables}
          error={error}
          isLoading={loading || areCrawlIdsLoading}
          isReloading={reloading}
          isFilterApplied={hasFiltersApplied}
          hasAlerts={hasAlerts}
          data={data}
          hasFetchedAllData={hasFetchedAllData}
          onLoadMore={handleLoadMore}
          status={notificationStatus}
          clearFilters={resetFilters}
          values={notificationSelection}
          onGoToAllNotificationsClick={() => {
            setNotificationStatus(NotificationStatusFilter.Unread);
            setSuggestedThresholdFilter("all");
          }}
          onChange={(selection) => {
            setNotificationSelection(selection);
          }}
          showAdjustments={isAdjustmentsTabSelected}
          isRequiresManualApprovalSelected={
            suggestedThresholdFilter === "requiresApproval"
          }
        />
      </ErrorBoundary>
    </>
  );
}

import { MutableRefObject, useState } from "react";
import {
  Popover,
  Tooltip,
  makeStyles,
  IconButton,
  useTheme,
  Badge,
} from "@material-ui/core";
import {
  CogSolid,
  ToggleIconButton,
  Typography,
  PlusSolid,
  NotificationBellV2,
  useTranslation,
} from "@lumar/shared";
import { NotificationsMenuList } from "./NotificationsMenuList";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { useNotificationsQuery } from "../utils/useNotificationsQuery";
import { useMonitorRoutes } from "../../_common/routing/useMonitorRoutes";
import { ErrorBoundary } from "@rollbar/react";
import { SimpleFallbackError } from "../../_common/components/SimpleFallbackError";
import {
  NotificationStatusFilter,
  notificationStatusFilters,
} from "../filters/constants";
import { NotificationsMenuTabs } from "./NotificationsMenuTabs";
import { Link, useParams } from "react-router-dom";
import { useMostRecentCrawlIds } from "../utils/useMostRecentCrawlIds";
import { useGetNotificationsCountQuery } from "../../graphql";

const useStyles = makeStyles((theme) => ({
  popoverContainer: {
    width: "min(1200px, 95vw)",
  },
  popoverHeader: {
    display: "flex",
    alignItems: "center",
    height: 65,
    padding: theme.spacing(0, 3),
    background: theme.palette.grey[50],
  },
  popoverPaper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow:
      "-20px 0px 25px -5px rgba(0, 0, 0, 0.1), 10px 20px 10px -5px rgba(0, 0, 0, 0.06)",
    borderRadius: 8,
    marginTop: 6,
  },
  iconButton: ({ isPopupOpen }: { isPopupOpen: boolean }) => ({
    padding: 0,
    color: theme.palette.grey[700],
    borderRadius: 61,
    borderWidth: 1.5,
    backgroundColor: isPopupOpen ? theme.palette.grey[200] : "white",
    borderColor: theme.palette.grey[300],
    boxShadow: "0px 1px 2px rgb(0 0 0 / 5%)",
    "&:hover": {
      background: theme.palette.grey[200],
      color: theme.palette.grey[700],
      borderColor: theme.palette.grey[300],
    },
  }),
  plusButton: {
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    height: 31,
    width: 31,
    "&:hover": {
      backgroundColor: theme.palette.ultraviolet[700],
    },
    color: "white",
    "& svg": {
      fontSize: 19,
    },
    marginLeft: theme.spacing(1),
  },
  tabsContainer: {
    background: theme.palette.grey[50],
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.ultraviolet[700],
    },
  },
  badge: {
    background: theme.palette.red[600],
    border: `2px solid ${theme.palette.background.paper}`,
    top: 5,
    right: 8,
  },
}));

export function NotificationsMenu(props: {
  anchorRef: MutableRefObject<HTMLDivElement | null>;
}): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const classes = useStyles({ isPopupOpen });
  const { t } = useTranslation("alerts");
  const theme = useTheme();
  const { alertsPage, createAlertPage, notificationsPage } = useMonitorRoutes();
  const [skippingQuery, setSkippingQuery] = useState<boolean>(!isPopupOpen);
  const [notificationStatus, setNotificationStatus] = useState(
    notificationStatusFilters[0],
  );

  const { data: crawlIds, loading: areCrawlIdsLoading } =
    useMostRecentCrawlIds(!isPopupOpen);

  const {
    data,
    error,
    loading,
    hasFetchedAllData,
    handleLoadMore,
    queryVariables,
  } = useNotificationsQuery({
    notificationStatus,
    crawlIds,
    skip: areCrawlIdsLoading || skippingQuery,
  });

  if (skippingQuery !== !isPopupOpen && isPopupOpen) setSkippingQuery(false);

  const { data: totalCount } = useGetNotificationsCountQuery({
    variables: { accountId },
    fetchPolicy: "cache-first",
  });

  const handleNotificationStatusChange = (
    newValue: NotificationStatusFilter,
  ): void => {
    setNotificationStatus(newValue);
  };

  const handleClick = (): void => {
    setIsPopupOpen(true);
  };

  const handleClose = (): void => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <Badge
        badgeContent={totalCount?.getAccount?.notifications.totalCount}
        color="primary"
        max={9}
        classes={{ badge: classes.badge }}
      >
        <Tooltip title={t("notifications") as string} arrow={false}>
          <ToggleIconButton
            variant="outlined"
            size="large"
            color="secondary"
            className={classes.iconButton}
            onClick={handleClick}
            data-pendo="view-notifications-button"
            data-testid="notifications-menu-button"
          >
            <NotificationBellV2 />
          </ToggleIconButton>
        </Tooltip>
      </Badge>
      <Popover
        id="notifications-popover"
        open={isPopupOpen}
        anchorEl={props.anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: classes.popoverPaper,
        }}
      >
        <div className={classes.popoverContainer}>
          <div className={classes.popoverHeader}>
            <Typography variant="h6SemiBold">{t("notifications")}</Typography>
            <Tooltip title={t("manage") as string} arrow={false}>
              <ToggleIconButton
                style={{ marginLeft: "auto" }}
                data-pendo="view-notifications-manange-alerts-button"
                data-testid="manage-alerts-button"
                variant="outlined"
                colorVariant="primary"
                onClick={() => alertsPage.visit()}
              >
                <CogSolid />
              </ToggleIconButton>
            </Tooltip>
            <HideFromInsufficientRole>
              <Tooltip title={t("createNewAlert") as string} arrow={false}>
                <IconButton
                  className={classes.plusButton}
                  size="medium"
                  data-pendo="view-notifications-create-alert-button"
                  data-testid="create-new-alert-icon-button"
                  onClick={() => createAlertPage.visit()}
                >
                  <PlusSolid />
                </IconButton>
              </Tooltip>
            </HideFromInsufficientRole>
          </div>
          <div
            className={classes.tabsContainer}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            }}
          >
            <NotificationsMenuTabs
              value={notificationStatus}
              onChange={handleNotificationStatusChange}
              loading={loading || areCrawlIdsLoading}
              mostRecentCrawlIds={crawlIds}
            />
            <Link to={notificationsPage.getUrl()} className={classes.link}>
              {t("allNotifications")} -&gt;
            </Link>
          </div>
          <div>
            <ErrorBoundary fallbackUI={SimpleFallbackError}>
              <NotificationsMenuList
                queryVariables={queryVariables}
                data={data}
                error={error}
                loading={loading || areCrawlIdsLoading}
                hasFetchedAllData={hasFetchedAllData}
                onLoadMore={handleLoadMore}
                notificationStatus={notificationStatus}
              />
            </ErrorBoundary>
          </div>
        </div>
      </Popover>
    </>
  );
}

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  ExclamationSolid,
  ExclamationCircleSolid,
  Typography,
  Chip,
  useNumberFormatter,
  useTranslation,
  useSession,
} from "@lumar/shared";
import { RuleAndThreshold } from "./utils/types";
import {
  ReportTemplateUnit,
  Severity,
  ThresholdPredicate,
} from "../../graphql";
import { ReportTrendVisualisation } from "../create/components/ReportTrendVisualisation";
import { getThresholdTrendDataFromTestList } from "./utils/getThresholdTrendDataFromTestList";
import { clsx } from "clsx";
import {
  ChangeTitle,
  IfImprovesTitle,
  IfWorsensTitle,
  ReportTitle,
  RuleTitle,
  SegmentTitle,
  SeverityTitle,
  ThresholdTitle,
} from "./table/RulesAndThresholdsTableTitles";
import { useParams } from "react-router-dom";
import { AnalyticsHubReportLink } from "../../board/dashboards/account-overview/AnalyticsHubReportLink";

export function RulesAndThresholdsViewTable({
  projectId,
  crawlId,
  testList,
  className,
}: {
  projectId: string;
  crawlId: string;
  testList: RuleAndThreshold[];
  className?: string;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const { t } = useTranslation(["alerts", "units", "common", "thresholds"]);
  const { accountId } = useParams<{ accountId: string }>();
  const formatNumber = useNumberFormatter();

  return (
    <div className={clsx(classes.wrapper, className)}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              className={clsx(classes.headerCell, classes.severityCell)}
            >
              <SeverityTitle />
            </TableCell>
            <TableCell className={classes.headerCell}>
              <ReportTitle />
            </TableCell>
            {segmentationAvailable && (
              <TableCell className={classes.headerCell}>
                <SegmentTitle />
              </TableCell>
            )}
            <TableCell className={clsx(classes.headerCell, classes.ruleCell)}>
              <RuleTitle />
            </TableCell>
            <TableCell className={classes.headerCell}>
              <ThresholdTitle />
            </TableCell>
            <TableCell className={clsx(classes.headerCell, classes.issuesCell)}>
              <IfImprovesTitle />
            </TableCell>
            <TableCell className={clsx(classes.headerCell, classes.issuesCell)}>
              <IfWorsensTitle />
            </TableCell>
            <TableCell className={classes.changeHeader}>
              <ChangeTitle />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {testList.map((test, index) => {
            const thresholdTrendData = getThresholdTrendDataFromTestList(
              {
                id: test.id,
                testResults: test.testResults ?? [],
                reportTemplateCode: test.report.code,
              },
              test.report.code,
            );
            return (
              <TableRow
                key={test.id}
                className={classes.tableRow}
                data-testid={`rule-and-threshold-row-${index}`}
                data-testid-2={`rule-and-threshold-row-${test.report?.name.replace(
                  " ",
                  "-",
                )}${
                  test.segment ? `-${test.segment?.name.replace(" ", "-")}` : ""
                }`}
              >
                <TableCell className={classes.centeredRowCell}>
                  <Tooltip
                    title={t(`common:${test.severity}`) as string}
                    arrow={false}
                  >
                    {test.severity === Severity.Warning ? (
                      <ExclamationSolid
                        className={classes.severityIcon}
                        htmlColor={theme.palette.yellow[400]}
                      />
                    ) : (
                      <ExclamationCircleSolid
                        className={classes.severityIcon}
                        htmlColor={theme.palette.red[400]}
                      />
                    )}
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.rowCell}>
                  <Typography
                    variant="body2"
                    className={classes.limitedCellSize}
                  >
                    {test.report.name}
                  </Typography>
                </TableCell>
                {segmentationAvailable && (
                  <TableCell className={classes.rowCell}>
                    <Typography
                      variant="body2"
                      className={classes.limitedCellSize}
                    >
                      {test.segment?.name ?? t("common:noSegments")}
                    </Typography>
                  </TableCell>
                )}
                <TableCell className={classes.centeredRowCell}>
                  <Tooltip
                    title={
                      (test.threshold === ThresholdPredicate.GreaterThanOrEqual
                        ? t("thresholds:greater")
                        : t("thresholds:less")) as string
                    }
                    arrow={false}
                  >
                    <Chip
                      color="primary"
                      size="small"
                      rounded={true}
                      label={
                        <div
                          style={{
                            padding: theme.spacing(0, 0.5),
                            fontSize: theme.typography.pxToRem(15),
                          }}
                        >
                          {test.threshold ===
                          ThresholdPredicate.GreaterThanOrEqual
                            ? "≥"
                            : "<"}
                        </div>
                      }
                    />
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.rowCell}>
                  <div style={{ display: "flex" }}>
                    <Typography variant="body2">
                      {formatNumber(test.urls)}&nbsp;
                    </Typography>
                    <Typography variant="body2" className={classes.urls}>
                      {t(
                        `units:${test.report.unit ?? ReportTemplateUnit.UrLs}`,
                        {
                          count: test.urls,
                        },
                      )}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell
                  className={clsx(classes.rowCell, classes.issuesCell)}
                >
                  <Typography variant="body2">
                    {t(
                      `alerts:rulesAndThresholds:${test.thresholdAcceptanceWhenBetter}`,
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  className={clsx(classes.rowCell, classes.issuesCell)}
                >
                  <Typography variant="body2">
                    {t(
                      `alerts:rulesAndThresholds:${test.thresholdAcceptanceWhenWorse}`,
                    )}
                  </Typography>
                </TableCell>
                <TableCell className={classes.rowCell}>
                  <AnalyticsHubReportLink
                    accountId={accountId}
                    projectId={projectId}
                    segmentId={test.segment?.id}
                    crawlId={crawlId ?? ""}
                    reportTemplateCode={test.report.code ?? ""}
                    data-testid={`account-overview-grid-${test.report.code}-column-cell`}
                    data-pendo="monitor-dashboard-report-link"
                    className={classes.link}
                  >
                    <ReportTrendVisualisation
                      reportCode={test.report.code}
                      unit={test.report.unit ?? ReportTemplateUnit.UrLs}
                      projectId={projectId}
                      segmentId={test.segment?.id}
                      thresholdTrendData={thresholdTrendData}
                    />
                  </AnalyticsHubReportLink>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  headerCell: {
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.grey[800],
  },
  table: {
    color: theme.palette.grey[700],
  },
  tableRow: {
    boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset`,
    height: 56,
  },
  centeredRowCell: {
    textAlign: "center",
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
  },
  rowCell: {
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    padding: theme.spacing(1.5, 3),
    color: theme.palette.grey[700],
    maxWidth: 200,
  },
  severityIcon: {
    fontSize: theme.typography.pxToRem(20),
  },
  changeHeader: {
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    width: 205,
    maxWidth: 205,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  severityCell: {
    width: 60,
    maxWidth: 93,
  },
  ruleCell: {
    width: 50,
    maxWidth: 83,
  },
  urls: {
    color: theme.palette.grey[400],
  },
  issuesCell: {
    background: "#EBEFF3",
    width: 175,
    minWidth: 175,
    maxWidth: 175,
  },
  wrapper: {
    overflow: "auto",
    borderRadius: theme.spacing(1, 1, 0, 0),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderCollapse: "unset",
    background: theme.palette.background.paper,
  },
  link: {
    textDecoration: "none",
  },
  limitedCellSize: {
    textOverflow: "ellipsis",
    overflowX: "hidden",
    maxWidth: 200,
    minWidth: 100,
  },
}));

/* eslint-disable fp/no-this */
import { useTranslation } from "@lumar/shared";
import * as Yup from "yup";
import { MAX_ABSOLUTE_THRESHOLD_URLS } from "./constants";
import isUrl from "validator/lib/isURL";
import { filter, includes } from "lodash";
import isEmail from "validator/lib/isEmail";
import { RuleAndThreshold } from "./types";

export function useValidationSchema(): unknown {
  const { t } = useTranslation(["alerts", "msTeamsNotifications"]);

  return Yup.object().shape({
    rulesAndThresholds: Yup.array().of(
      Yup.object()
        .shape({
          severity: Yup.string().required(),
          report: Yup.object()
            .shape({
              id: Yup.string(),
              name: Yup.string(),
              code: Yup.string(),
              totalSign: Yup.number(),
            })
            .required(t("reportValidationMessage"))
            .nullable(),
          threshold: Yup.string().required(),
          urls: Yup.number()
            .required(t("urlsRequiredValidationMessage"))
            .min(0, t("minUrlsValidationMessage"))
            .max(
              MAX_ABSOLUTE_THRESHOLD_URLS,
              t("maxUrlsValidationMessage", {
                count: MAX_ABSOLUTE_THRESHOLD_URLS,
              }),
            ),
        })
        .test("unique", t("uniqueValidation"), (value, context) => {
          const element = value as RuleAndThreshold;
          const sameReportList: RuleAndThreshold[] = context.parent.filter(
            (item: RuleAndThreshold) => item.report.code === value.report?.code,
          );
          if (sameReportList.length <= 1) return true;
          const isUnique =
            sameReportList.filter((e) => e.segment?.id === element.segment?.id)
              .length < 2;

          return isUnique;
        })
        .test("valid", t("useableWithSegment"), (value) => {
          const element = value as RuleAndThreshold;
          if (!element.report.useableWithSegments && element.segment?.id)
            return false;
          return true;
        }),
    ),
    emailAlerts: Yup.array()
      .of(
        Yup.string().test(
          "email",
          t("invalidEmail"),
          (email) => !email || isEmail(email),
        ),
      )
      .test("unique", function (array) {
        if (!array) return true;

        const dup = filter(array, (val, i, iteratee) =>
          includes(iteratee, val, i + 1),
        );
        if (!dup?.length) return true;

        const errors = array
          .map((el, index) =>
            dup.includes(el)
              ? this.createError({
                  path: `${this.path}.${index}`,
                  message: t("duplicatedEmail"),
                })
              : <Yup.ValidationError>{ type: "none" },
          )
          .filter((e) => e.type !== "none");
        return new Yup.ValidationError(errors);
      }),
    slackWebhooks: Yup.array()
      .of(
        Yup.string().test(
          "isURL",
          t("invalidDomain"),
          (value) =>
            value !== undefined &&
            isUrl(value, {
              protocols: ["http", "https"],
              require_protocol: true,
            }),
        ),
      )
      .test("unique", function (array) {
        if (!array) return true;

        const dup = filter(array, (val, i, iteratee) =>
          includes(iteratee, val, i + 1),
        );
        if (!dup?.length) return true;

        const errors = array
          .map((el, index) =>
            dup.includes(el)
              ? this.createError({
                  path: `${this.path}.${index}`,
                  message: t("duplicatedSlack"),
                })
              : <Yup.ValidationError>{ type: "none" },
          )
          .filter((e) => e.type !== "none");
        return new Yup.ValidationError(errors);
      }),
    microsoftTeamsWebhooks: Yup.array()
      .of(
        Yup.string().test(
          "isURL",
          t("msTeamsNotifications:validationErrors.invalidWebhook"),
          (value) =>
            value !== undefined &&
            isUrl(value, {
              protocols: ["http", "https"],
              require_protocol: true,
            }),
        ),
      )
      .test("unique", function (array) {
        if (!array) return true;

        const dup = filter(array, (val, i, iteratee) =>
          includes(iteratee, val, i + 1),
        );
        if (!dup?.length) return true;

        const errors = array
          .map((el, index) =>
            dup.includes(el)
              ? this.createError({
                  path: `${this.path}.${index}`,
                  message: t(
                    "msTeamsNotifications:validationErrors.duplicateWebhook",
                  ),
                })
              : <Yup.ValidationError>{ type: "none" },
          )
          .filter((e) => e.type !== "none");
        return new Yup.ValidationError(errors);
      })
      .max(100, t("msTeamsNotifications:validationErrors.maximumNumber")),
  });
}

import { GetNotificationsQuery } from "../../graphql";
import { MonitorNotification } from "../types";

export function getNotificationList(
  data: GetNotificationsQuery | undefined,
): MonitorNotification[] | undefined {
  return data?.getAccount?.notifications?.edges.map((n) => ({
    ...n.node,
    reportStat: n.node.reportStat && {
      ...n.node.reportStat,
      reportTrend:
        n.node.reportStat?.reportTrend.map((t) => ({
          crawlId: t.crawlId,
          basic: t.basic,
          createdAt: t.createdAt ?? null,
        })) ?? [],
      crawlId: n.node.reportStat?.crawl?.id,
      customExtractions:
        n.node.reportStat.crawl.crawlSetting?.customExtractions ?? [],
    },
  }));
}

import { useParams } from "react-router-dom";
import {
  GetAlertWithTestsQuery,
  useGetNotificationsLazyQuery,
} from "../../../graphql";
import { RulesAndThresholdsFormState } from "../../_common/utils/types";
import { useSaveEmailAlertsMutation } from "../../_common/utils/useSaveEmailAlertsMutation";
import { useSaveMsTeamsWebhooksMutations } from "../../_common/utils/useSaveMsTeamsWebhooksMutation";
import { useSaveSlackWebhooksMutation } from "../../_common/utils/useSaveSlackWebhooksMutation";
import { useSaveRulesAndThresholdsMutation } from "./useSaveRulesAndThresholdsMutation";

interface UpdateAlertInput {
  initialAlertSettings: RulesAndThresholdsFormState;
  newAlertSettings: RulesAndThresholdsFormState;
  alertData: GetAlertWithTestsQuery | undefined;
  alertId: string;
}

type UpdateAlertFunction = (args: UpdateAlertInput) => Promise<void>;

export function useUpdateAlertMutation(): [
  UpdateAlertFunction,
  { loading: boolean },
] {
  const { accountId } = useParams<{ accountId: string }>();
  const [saveRulesAndThreshold, { loading: isSavingRules }] =
    useSaveRulesAndThresholdsMutation();

  const [saveEmailAlerts, { loading: isSavingEmailAlerts }] =
    useSaveEmailAlertsMutation();

  const [saveSlackWebhooks, { loading: isSavingSlackWebhooks }] =
    useSaveSlackWebhooksMutation();

  const [saveMsTeamsWebhooks, { loading: isSavingMsTeamsWebhooks }] =
    useSaveMsTeamsWebhooksMutations();

  const [getNotifications] = useGetNotificationsLazyQuery({
    fetchPolicy: "network-only",
  });

  const isLoading =
    isSavingRules ||
    isSavingEmailAlerts ||
    isSavingSlackWebhooks ||
    isSavingMsTeamsWebhooks;

  async function updateAlert({
    initialAlertSettings,
    newAlertSettings,
    alertData,
    alertId,
  }: UpdateAlertInput): Promise<void> {
    await Promise.all([
      saveRulesAndThreshold(
        initialAlertSettings.rulesAndThresholds,
        newAlertSettings.rulesAndThresholds,
        alertId,
      ),
      saveEmailAlerts(
        alertData?.alert?.emailAlerts?.nodes ?? [],
        newAlertSettings.emailAlerts ?? [],
        alertId,
      ),
      saveSlackWebhooks(
        alertData?.alert?.slackWebhooks?.nodes ?? [],
        newAlertSettings.slackWebhooks ?? [],
        alertId,
      ),
      saveMsTeamsWebhooks(
        alertData?.alert?.webhooks?.nodes ?? [],
        newAlertSettings.microsoftTeamsWebhooks ?? [],
        alertId,
      ),
    ]);

    // Note: we can't use `refetchQueries: ["GetNotifications"]` because we don't know for certain which mutations will actually be called.
    // Therefore, we have to manually refetch the notifications to ensure that the data doesn't become stale.
    getNotifications({ variables: { accountId } });
  }
  return [updateAlert, { loading: isLoading }];
}

import {
  PresentationChartLineOutlined,
  Snackbar,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, createStyles, useTheme } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import {
  CustomDashboardType,
  useDeleteDashboardMutation,
  useUpdateDashboardMutation,
} from "../../graphql";
import { ConditionallTooltip } from "../../_common/components/ConditionalTooltip";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { useIsElementOverflown } from "../../_common/hooks/useIsElementOverflown";
import { DashboardNameEditor } from "./DashboardNameEditor";
import { DashboardTabActionMenu } from "./DashboardTabActionMenu";

const useStyles = makeStyles(() =>
  createStyles({
    editField: {
      "& .MuiInputBase-root": {
        height: 32,
      },
    },
  }),
);

export interface DashboardLabelProps {
  name: string;
  id: string;
  className?: string;
  variant?: "standard" | "outlined";
  buttonBKColor?: string;
  colorVariant?: "blue" | "red" | "grey" | "primary";
  onEditStarted?: () => void;
  onEditEnded?: (name: string | null | undefined) => void;
  disabled?: boolean;
  disableDelete?: boolean;
  disableCloning?: boolean;
  onCloneAction?: (id: string) => void;
  type: CustomDashboardType;
}

export function DashboardLabel({
  id,
  name: dashboardName,
  className,
  variant,
  buttonBKColor,
  colorVariant,
  onEditStarted,
  onEditEnded,
  disabled,
  disableDelete,
  disableCloning,
  onCloneAction,
  type,
}: DashboardLabelProps): JSX.Element {
  const classes = useStyles();
  const [isHoveringOverMenuButton, setIsHoveringOverMenuButton] =
    useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const textRef = useRef<HTMLSpanElement | null>(null);
  const isTextOverflown = useIsElementOverflown(textRef);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["dashboards", "dashboardTabs", "errors"]);
  const theme = useTheme();

  const [deleteDashboard] = useDeleteDashboardMutation({
    refetchQueries: ["GetDashboardCollection"],
    onCompleted: (data) => {
      if (data)
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("dashboardTabs:deleteSuccess")}
          />,
        );
    },
    onError: (error) => {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("errors:deleteSubdashboardError", {
            message: error.message,
          })}
        />,
      );
    },
  });

  const [updateDashboard] = useUpdateDashboardMutation({
    refetchQueries: ["GetDashboardCollection"],
    onError: (error) => {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("errors:renameSubdashboardError", {
            message: error.message,
          })}
        />,
      );
    },
  });

  return (
    <div
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      className={className}
    >
      {isEditing && !disabled ? (
        <DashboardNameEditor
          minLength={1}
          value={dashboardName}
          style={{ width: "100%" }}
          data-testid="edit-dashboard-item-name-input"
          pendoPrefix="edit-dashboard-item-name"
          refetchQueries={[
            "GetDashboardCollections",
            "GetDashboardCollectionsCount",
          ]}
          onFinishedEditing={async (name, reason) => {
            if (reason === "ok") {
              if (name && name !== dashboardName)
                updateDashboard({
                  variables: {
                    customDashboardId: id,
                    name: name,
                  },
                });
              setIsEditing(false);
              onEditEnded?.(name);
            } else if (reason === "cancel") {
              setIsEditing(false);
              onEditEnded?.(null);
            }
          }}
          variant="small"
          className={classes.editField}
        />
      ) : (
        <>
          <ConditionallTooltip
            title={dashboardName}
            show={isTextOverflown}
            arrow={false}
          >
            <Typography
              variant="captionMedium"
              style={{
                color: "inherit",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              ref={textRef}
            >
              {type === CustomDashboardType.HealthScores ? (
                <PresentationChartLineOutlined
                  style={{
                    width: 15,
                    height: 15,
                    marginBottom: -2,
                    marginRight: theme.spacing(0.81),
                  }}
                />
              ) : undefined}
              {dashboardName}
            </Typography>
          </ConditionallTooltip>
          <HideFromInsufficientRole>
            <DashboardTabActionMenu
              showMenuButton={isHovered && !disabled}
              showMenuButtonTooltip={isHoveringOverMenuButton}
              disableDelete={disableDelete}
              disableCloning={disableCloning}
              onCloneAction={() => {
                onCloneAction?.(id);
              }}
              onRenameButtonClick={() => {
                setIsEditing(true);
                onEditStarted?.();
              }}
              onMouseEnter={() => setIsHoveringOverMenuButton(true)}
              onMouseLeave={() => setIsHoveringOverMenuButton(false)}
              onDeleteAction={async () => {
                await deleteDashboard({
                  variables: { customDashboardId: id },
                });
              }}
              onBlur={() => {
                setIsHovered(false);
              }}
              variant={variant}
              buttonBKColor={buttonBKColor}
              colorVariant={colorVariant}
            />
          </HideFromInsufficientRole>
        </>
      )}
    </div>
  );
}

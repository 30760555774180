import {
  useApolloClient,
  getApiAccountId,
  Trans,
  useTranslation,
  InformationCircleOutlined,
} from "@lumar/shared";
import {
  CircularProgress,
  makeStyles,
  Tab,
  Tabs,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useNotificationTotalCountsQuery } from "../../graphql";
import { NotificationStatusFilter } from "./constants";
import { AbsoluteURLs } from "../../_common/routing/absoluteURLs";

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.grey[700],
  },
}));

interface Props {
  loading?: boolean;
  value: NotificationStatusFilter;
  onChange: (newStatus: NotificationStatusFilter) => void;
  mostRecentCrawlIds: number[];
}

export function StatusTabs({
  loading: externalLoading = false,
  value,
  onChange,
  mostRecentCrawlIds,
}: Props): JSX.Element {
  const { t } = useTranslation(["notifications", "common", "navigation"]);
  const classes = useStyles();

  const { accountId } = useParams<{ accountId: string }>();
  const apollo = useApolloClient();

  const { data, loading: queryLoading } = useNotificationTotalCountsQuery({
    variables: { accountId, crawlIds: mostRecentCrawlIds },
    skip: externalLoading,
  });

  // Because apollo is managing the monitorNotifications cache for pagination, on tab change we have to force clear the cache
  // This is neccessary only when status change happened on notifications (so maybe optimization is possible)
  function flushCache(): void {
    apollo.cache.modify({
      id: apollo.cache.identify({
        __typename: "Account",
        id: getApiAccountId(accountId),
      }),
      fields: {
        monitorNotifications: (_, details) => details.DELETE,
      },
    });
  }
  const loading = externalLoading || queryLoading;

  return (
    <Tabs
      value={value}
      onChange={(_, newStatus) => {
        onChange(newStatus);
        flushCache();
      }}
      aria-label="Notification tabs"
      style={{ borderBottom: 0 }}
      data-pendo={`notifications-page-tabs`}
    >
      <Tab
        label={
          <TabLabel
            loading={loading}
            loadingText={t("common:notifications")}
            text={t("notificationsWithCount", {
              count: data?.getAccount?.unreadNotifications.totalCount,
            })}
            labelKey="notificationsLabel"
          />
        }
        value={NotificationStatusFilter.Unread}
        data-testid="notifications-tab"
        classes={{ textColorPrimary: classes.primary }}
        data-pendo="all-notifications-tab"
      />
      <Tab
        label={
          <TabLabel
            loading={loading}
            loadingText={t("adjustments")}
            text={t("adjustmentsWithCount", {
              count: data?.getAccount?.adjustmentNotifications.totalCount,
            })}
            labelKey="adjustmentsLabel"
            link={AbsoluteURLs.EXTERNAL__ProductGuide.getUrl(t)}
          />
        }
        value={NotificationStatusFilter.Adjustments}
        data-testid={`${NotificationStatusFilter.Adjustments}-tab`}
        classes={{ textColorPrimary: classes.primary }}
        data-pendo="adjustments-tab"
      />
      <Tab
        label={
          <TabLabel
            loading={loading}
            loadingText={t("workingonit")}
            text={t("workingOnItWithCount", {
              count: data?.getAccount?.workingOnItNotifications.totalCount,
            })}
            labelKey="workingonitLabel"
          />
        }
        value={NotificationStatusFilter.WorkingOnIt}
        data-testid={`${NotificationStatusFilter.WorkingOnIt}-tab`}
        classes={{ textColorPrimary: classes.primary }}
        data-pendo="working-on-it-tab"
      />
      <Tab
        label={
          <TabLabel
            loading={loading}
            loadingText={t("read")}
            text={t("read")}
            labelKey="readLabel"
          />
        }
        data-testid={`${NotificationStatusFilter.Archived}-tab`}
        value={NotificationStatusFilter.Archived}
        classes={{ textColorPrimary: classes.primary }}
        data-pendo="archived-tab"
      />
    </Tabs>
  );
}

interface TabLabelProps {
  loading: boolean;
  loadingText: string;
  text: string;
  labelKey?: string;
  link?: string;
}

const TabLabel = ({
  loading,
  loadingText,
  text,
  labelKey,
  link,
}: TabLabelProps): JSX.Element => {
  const theme = useTheme();
  return loading ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{loadingText}</span>
      <CircularProgress style={{ height: 16, width: 16, marginLeft: 8 }} />
    </div>
  ) : (
    <div style={{ display: "flex" }}>
      {text}
      {labelKey ? (
        <Tooltip
          interactive
          arrow={false}
          title={
            <Trans
              ns="notifications"
              i18nKey={labelKey}
              components={{
                notificationLink: (
                  <a href={link} target="_blank" rel="noopener noreferrer" />
                ),
              }}
            />
          }
        >
          <InformationCircleOutlined
            style={{ fontSize: 20, marginLeft: 4 }}
            htmlColor={theme.palette.grey[400]}
          />
        </Tooltip>
      ) : undefined}
    </div>
  );
};

import React from "react";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { WRAPPER_SPACING } from "../wrapper/Wrapper";

const useStyles = makeStyles((theme) => ({
  sticky: {
    boxShadow: "0px 5px 6px -1px rgba(0, 0, 0, 0.12)",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.drawer - 1,
    marginLeft: -theme.spacing(WRAPPER_SPACING),
    width: `calc(100% + ${2 * theme.spacing(WRAPPER_SPACING)}px)`,
    whiteSpace: "nowrap",
    flexWrap: "nowrap",
    transition: "box-shadow 0.3s ease-in-out",
  },
}));

interface Props {
  children: React.ReactNode;
}

export const StickyContainer = ({ children }: Props): JSX.Element => {
  const classes = useStyles();
  const { ref, inView } = useInView({
    delay: 0,
    rootMargin: "-136px 0px 0px 0px",
    threshold: 0,
    fallbackInView: true,
  });

  return (
    <div
      className={clsx(classes.div, !inView ? classes.sticky : undefined)}
      ref={ref}
      id="sticky"
    >
      {children}
    </div>
  );
};

import { ReportTrendItem } from "../../graphql";
import { ReportTrendElement } from "../types";

type WithReportTrendElementKeys = Record<keyof ReportTrendElement, unknown>;
type WithReportTrendItemKeys = Record<keyof ReportTrendItem, unknown>;

function hasReportTrendElementKeys(
  value: unknown,
): value is WithReportTrendElementKeys {
  return (
    typeof value === "object" &&
    value !== null &&
    Boolean(value.hasOwnProperty("crawlId")) &&
    Boolean(value.hasOwnProperty("createdAt")) &&
    Boolean(value.hasOwnProperty("totalRows"))
  );
}

export function isReportTrendElement(
  value: unknown,
): value is ReportTrendElement {
  if (hasReportTrendElementKeys(value)) {
    return (
      typeof value.crawlId === "string" &&
      typeof value.createdAt === "string" &&
      typeof value.totalRows === "number"
    );
  }

  return false;
}

type ReportTrendElementArray = ReportTrendElement[];

export function isReportTrendElementArray(
  arr: unknown[],
): arr is ReportTrendElementArray {
  return arr.every((item) => isReportTrendElement(item));
}

function hasReportTrendItemKeys(
  value: unknown,
): value is WithReportTrendItemKeys {
  return (
    typeof value === "object" &&
    value !== null &&
    Boolean(value.hasOwnProperty("crawlId")) &&
    Boolean(value.hasOwnProperty("createdAt")) &&
    Boolean(value.hasOwnProperty("basic"))
  );
}

export function isReportTrendItem(value: unknown): value is ReportTrendItem {
  if (hasReportTrendItemKeys(value)) {
    return (
      typeof value.crawlId === "number" &&
      typeof value.createdAt === "string" &&
      typeof value.basic === "number"
    );
  }

  return false;
}

type ReportTrendItemArray = ReportTrendItem[];

export function isReportTrendItemArray(
  arr: unknown[],
): arr is ReportTrendItemArray {
  return arr.every((item) => isReportTrendItem(item));
}
